import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Button,
  Avatar,
  Tabs,
  Empty,
  Switch,
  Typography,
} from 'antd';
import {
  UserPlaceholder,
  TrashIcon,
  ClientForm,
  AppointmentTimeline,
} from '../index';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useState } from 'react';
import CancelModal from '../modal/CancelModal';

const { TabPane } = Tabs;

/**
 * Show the customer detail
 *
 * @param {Object} param0
 * @returns
 */
function CustomerDetail({
  customer,
  onDelete,
  timeline,
  updateUser,
  activeTab,
  onTabChange,
}) {
  const { t } = useTranslation();
  const [showPastAppointment, setShowPastAppointment] = useState(false);
  const [cancModal, setCancModal] = useState(false);

  const deleteCustomer = () =>
    typeof onDelete === 'function' && onDelete(customer);

  const getClientInitialFormat = (data) => ({
    name: data?.name,
    surname: data?.surname,
    address: data?.address,
    username: data?.username,
    date_of_birth: data?.date_of_birth,
    postal_code: data?.postal_code,
    phone_number: data?.phone_number,
    email: data?.email,
    notes: data.notes || '',
    terms_and_conditions:
      data?.terms_and_conditions || data?.accept_terms_and_conditions,
  });

  const handleTabChange = (key) =>
    typeof onTabChange === 'function' && onTabChange(key);

  return (
    <div>
      <div className="profile">
        <div className="w-100 d-flex flex-column justify-content-center">
          <div style={{ margin: '30px auto 0 auto' }}>
            <Avatar src={customer?.avatar || UserPlaceholder} size={120} />
          </div>
          <h3
            className="mt-2 username text-capitalize px-2"
            style={{ margin: '20px auto' }}
          >{`${customer.name} ${customer.surname}`}</h3>
        </div>

        <div
          className="d-flex justify-content-end w-100"
          style={{ position: 'absolute', top: '40px', right: '20px' }}
        >
          <CancelModal
            title={t('calendar.card.title_confirm')}
            open={cancModal}
            closeModal={() => setCancModal(false)}
            content={t('clientpage.delete_confirmation_title')}
            handleOK={() => {
              setCancModal(false);
              deleteCustomer();
            }}
          />
          <Button
            className="secondary-button-outline"
            onClick={() => {
              setCancModal(true);
            }}
          >
            <img
              src={TrashIcon}
              className="pr-2"
              style={{ height: '16px', marginTop: '-2px' }}
              alt="Delete"
            />
            <span className="font-12">{t('common:delete')}</span>
          </Button>
        </div>
      </div>

      <div className="profile bg-light-yellow">
        <Tabs
          className="bg-tab-white"
          defaultActiveKey={activeTab}
          onChange={handleTabChange}
        >
          <TabPane tab={t('tab.personal_data')} key="personal">
            <div className="mt-4"></div>
            <Row gap={2}>
              <Col offset={1} span={22}>
                <Card width={'80%'} className="card mx-2 p-4 mt-2">
                  <ClientForm
                    onSubmit={updateUser}
                    initialValue={getClientInitialFormat(customer)}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('tab.appointments')} key="appointment">
            <div className="mt-4"></div>
            <Row gap={2}>
              <Col offset={1} span={22}>
                <div className=" mx-2 p-4 mt-2">
                  <div className="py-2 text-right">
                    <Typography.Paragraph>
                      <Typography.Text className="mx-2">
                        {t('clientpage.show_past_appointment')}
                      </Typography.Text>
                      <Switch
                        checked={showPastAppointment}
                        onChange={(checked) => setShowPastAppointment(checked)}
                      />
                    </Typography.Paragraph>
                  </div>
                  {!timeline.length ? (
                    <Empty
                      className="h-100"
                      description={t('clientpage.appointment_empty_message')}
                    />
                  ) : (
                    <AppointmentTimeline
                      timeline={timeline.filter((item) =>
                        showPastAppointment
                          ? moment(item.end_date).isSameOrAfter(
                              moment(),
                              'date',
                            )
                          : false,
                      )}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <div className="py-4 bg-light-yellow"></div>
      </div>
    </div>
  );
}

CustomerDetail.propTypes = {
  timeline: PropTypes.array,
  customer: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  updateUser: PropTypes.func,
};

export default CustomerDetail;
