import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateError } from "../../components/util/Helper";
import { staffTransformerCollection } from "../../transformers/staffTransformer";
import { createStaffRequest, deleteStaffRequest, fetchStaffHolidayListRequest, fetchStaffListRequest, updateStaffRequest } from "./staffApi";

const initialState = {
    loading: false,
    fetchingData : [],
    error: {
      hasError: false,
      status: 0,
      data: null
    },
    staffs: [],
    holidays: [] // staff holiday
};



export const fetchStaffList = createAsyncThunk(
    'staff/fetchStaffList',
    async () => {
      const response = await fetchStaffListRequest();
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);

export const removeStaff = createAsyncThunk(
    'staff/removeStaff',
    async (payload) => {
      const response = await deleteStaffRequest(payload.id);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : {data: response.data, id: payload.id};
    }
);

export const updateStaff = createAsyncThunk(
    'staff/updateStaff',
    async (payload) => {
      const response = await updateStaffRequest(payload);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);

export const createStaff = createAsyncThunk(
    'staff/createStaff',
    async (payload) => {
      const response = await createStaffRequest(payload);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);


export const fetchStaffHolidayList = createAsyncThunk(
  'staff/fetchStaffHolidayList',
  async (payload) => {
    const response = await fetchStaffHolidayListRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  }
);
  

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        // Fetch all staff
        .addCase(fetchStaffList.pending, (state) => {
          state.fetchingData = ["fetchStaffList", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchStaffList.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchStaffList");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.staffs = staffTransformerCollection(data.staffs);
        })
        
        // Fetch staff holiday
        .addCase(fetchStaffHolidayList.pending, (state) => {
          state.fetchingData = ["fetchStaffHolidayList", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchStaffHolidayList.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchStaffHolidayList");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.holidays = data.holidays;
        })
        
        // Update Staff
        .addCase(updateStaff.pending, (state) => {
          state.fetchingData = ["updateStaff", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(updateStaff.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "updateStaff");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          let newStaffs = staffTransformerCollection([data])[0];
          state.staffs = state.staffs.map(staff => newStaffs.id === staff.id ? newStaffs : staff);
        })

        // Remove Staff
        .addCase(removeStaff.pending, (state) => {
          state.fetchingData = ["removeStaff", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(removeStaff.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "removeStaff");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.staffs = state.staffs.filter(staff => data.id !== staff.id);
        })
        // Create Staff
        .addCase(createStaff.pending, (state) => {
          state.fetchingData = ["createStaff", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(createStaff.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "createStaff");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.staffs = [...state.staffs, data.data];
        });
    },
});

export const selectStaffList = (state) => state.staff.staffs;
export const selectStaffHolidayList = (state) => state.staff.holidays;
export const isStaffLoading = (state) => !!state.staff.fetchingData.length;



export default staffSlice.reducer;