import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import staffReducer from '../features/staff/staffSlice';
import serviceReducer from '../features/service/serviceSlice';
import customerReducer from '../features/customer/customerSlice';
import scheduleReducer from '../features/schedule/scheduleSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import frontendReducer from '../features/frontend/frontendSlice';
import notificationReducer from '../features/notification/notificationSlice';

export const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    auth: authReducer,
    staff: staffReducer,
    customer: customerReducer,
    service: serviceReducer,
    notification: notificationReducer,
    schedule: scheduleReducer,

    // Later added reducer. This should be refactored if 
    // the requirement grows from current point.
    frontend: frontendReducer, 
  },
});
