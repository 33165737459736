import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Upload,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServicePlaceholderIcon, TrashIcon } from '..';
import { logHandler } from '../../handlers/LogHandler';
import { authRequest } from '../../services/api';
import { getEventFromStorage } from '../../services/token';
import CancelModal from '../modal/CancelModal';
import './ServiceForm.less';

const { Option } = Select;

function ServiceForm({ service, hours, services, staffs, onSubmit, onDelete }) {
  const [color, setColor] = useState(null);
  const [form] = Form.useForm();
  const [colors, setColors] = useState([]);
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [cancModal, setCancModal] = useState(false);
  // const [onlyService, setOnlyService] = useState(false);

  const onColorSelect = (colorOption) => {
    setColor(colorOption.code);
    form.setFieldsValue({
      color: colorOption.code,
    });
  };

  // const toggleService = (e) => {
  //   setOnlyService(e);
  // };

  const handleFormSubmit = (data) => onSubmit({ ...data, image: image });

  useEffect(() => {
    let eventId = getEventFromStorage();
    setImage(null);
    authRequest()
      .get(`/api/business/v1/events/${eventId}/service_colors`)
      .then((res) => {
        setColors(res.data.service_colors);
      })
      .catch((error) => {
        setColors([]);
        logHandler.error(error);
      });
  }, []);

  useEffect(() => {
    if (service) {
      setImage(null);
      setColor(service.color);
      // setOnlyService(service.only_service || false);
      let date = new Date(service.execution_time * 1000);
      let staff = [];
      (service.staffs ?? []).forEach((x) => {
        staff[x.id] = true;
      });
      setTimeout(
        () =>
          form.setFieldsValue({
            name: service.title,
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes(),
            description: service.description,
            only_service: service.only_service,
            staff,
            service_id:
              service.service_ids && service.service_ids.length
                ? service.service_ids[0]
                : null,
            color: service.color,
            price: service.price,
          }),
        800,
      );
    }
    form.resetFields();
  }, [form, service]);

  const beforeUpload = (file) => false;

  const handleImageChange = (info) => {
    getBase64(info.file, (imageUrl) => setImage(imageUrl));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const dummyRequest = ({ file, onSuccess }) =>
    setTimeout(() => {
      onSuccess('ok');
    }, 0);

  const handleDeleteSubmit = () => service && onDelete && onDelete(service);

  return (
    <Form onFinish={handleFormSubmit} form={form}>
      <Row className="text-center">
        <Col span={22} offset={1}>
          <div className="py-4 w-100"></div>
          {service ? (
            <div className="">
              <Typography.Title className="flex-1 text-center" level={3}>
                <Space
                  style={{
                    float: 'left',
                  }}
                >
                  <Form.Item
                    name={['only_service']}
                    label={t('servicepage.button.showService')}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Space>
                {/* {service.title} */}
                <Form.Item
                  name={['name']}
                  rules={[{ required: true }]}
                  className="custom-input"
                  style={{
                    display: 'inline-block',
                    width: 'auto',
                  }}
                >
                  <Input
                    autoComplete="off"
                    size="large"
                    className="text-center custom-input"
                    placeholder={t('servicepage.placeholder.name')}
                  />
                </Form.Item>

                <CancelModal
                  title={t('calendar.card.title_confirm')}
                  content={t('servicepage.delete_confirmation_title')}
                  open={cancModal}
                  closeModal={() => setCancModal(false)}
                  handleOK={() => {
                    setCancModal(false);
                    handleDeleteSubmit();
                  }}
                />
                <Button
                  htmlType="button"
                  className="secondary-button-outline"
                  style={{
                    float: 'right',
                  }}
                  onClick={() => {
                    setCancModal(true);
                  }}
                >
                  <img
                    src={TrashIcon}
                    alt="Delete"
                    className="pr-2"
                    style={{ height: '16px', marginTop: '-2px' }}
                  />{' '}
                  {t('common:delete')}
                </Button>
              </Typography.Title>
            </div>
          ) : (
            <Form.Item name={['name']} rules={[{ required: true }]}>
              <Input
                autoComplete="off"
                size="large"
                className="text-center"
                style={{ minWidth: 300, maxWidth: 600 }}
                placeholder={t('servicepage.placeholder.name')}
              />
            </Form.Item>
          )}
          <div className="py-2 w-100"></div>
        </Col>
        <Col span={11} offset={1}>
          <div className="py-4 w-100"></div>
          <Card>
            <Typography.Paragraph className="text-uppercase text-bold">
              {t('servicepage.label.image')}
            </Typography.Paragraph>

            <div className="py-1 w-100"></div>

            <Upload
              multiple={false}
              className="cursor-pointer position-relative"
              showUploadList={false}
              maxCount={1}
              beforeUpload={beforeUpload}
              customRequest={dummyRequest}
              onChange={handleImageChange}
            >
              {(image || service?.image_url || ServicePlaceholderIcon) && (
                <Avatar
                  src={image || service?.image_url || ServicePlaceholderIcon}
                  size={{ xs: 100, sm: 100, md: 120, lg: 140, xl: 140 }}
                />
              )}
            </Upload>
            <div className="pb-1 w-100"></div>
          </Card>
        </Col>
        <Col span={11}>
          <div className="py-4 w-100"></div>
          <Card>
            <Typography.Paragraph className="text-uppercase text-bold">
              {t('servicepage.label.description')}
            </Typography.Paragraph>

            <div className="py-1 w-100"></div>

            <Form.Item name={['description']}>
              <Input.TextArea
                rows={5}
                autoComplete="off"
                placeholder={t('servicepage.placeholder.description')}
              />
            </Form.Item>
          </Card>
        </Col>

        <div className="my-2 py-2 w-100" />

        <Col span={22} offset={1}>
          <div className="d-flex">
            <Card style={{ flex: 1 }}>
              <div className="my-2 w-100" />

              <Typography.Paragraph className="text-uppercase text-bold">
                {t('servicepage.label.duration')}
              </Typography.Paragraph>
              <div className="my-2 py-2 w-100" />
              <Space align="center">
                <Form.Item
                  name={['hour']}
                  rules={[{ required: true }]}
                  className="m-0"
                >
                  <Select
                    showSearch
                    placeholder={t('servicepage.placeholder.hour')}
                    style={{ width: 100 }}
                    className="select-outline"
                    size={'large'}
                  >
                    {hours.map((hour) => (
                      <Option key={`start-${hour}`} value={hour}>
                        {hour}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Typography.Text>:</Typography.Text>
                <Form.Item
                  className="m-0"
                  rules={[{ required: true }]}
                  name={['minute']}
                >
                  <Select
                    showSearch
                    style={{ width: 100 }}
                    size={'large'}
                    className="select-outline"
                    placeholder={t('servicepage.placeholder.minute')}
                  >
                    <Option value={0}>{0}</Option>
                    <Option value={15}>{15}</Option>
                    <Option value={30}>{30}</Option>
                    <Option value={45}>{45}</Option>
                  </Select>
                </Form.Item>
              </Space>
              <div className="my-2 py-2 w-100" />
            </Card>
            <div className="ml-2"></div>
            <Card style={{ flex: 1 }}>
              <div className="my-2 w-100" />
              <Typography.Paragraph className="text-uppercase text-bold">
                {t('servicepage.label.price')}
              </Typography.Paragraph>
              <div className="my-2 py-2 w-100" />
              <Form.Item rules={[{ required: true }]} name={['price']}>
                <Input
                  autoComplete="off"
                  type={'number'}
                  size={'large'}
                  prefix={
                    <Typography.Text className="px-2 pr-4">
                      {t('common:currency')}
                    </Typography.Text>
                  }
                  placeholder="100"
                  style={{ maxWidth: 200 }}
                />
              </Form.Item>
              <div className="my-2 py-2 w-100" />
              <hr />
              <Typography.Paragraph className="text-uppercase text-bold">
                {t('servicepage.label.related_service')}
              </Typography.Paragraph>
              <Form.Item name={['service_id']}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ maxWidth: 200, minWidth: 190 }}
                  className="select-outline"
                  placeholder={t('servicepage.placeholder.related_service')}
                  size={'large'}
                >
                  {!!services.length &&
                    services.map((serviceOpt) => (
                      <Option
                        key={`service-opt-${serviceOpt.id}`}
                        value={serviceOpt.id}
                      >
                        {serviceOpt.title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Card>
          </div>
        </Col>

        <div className="my-2 py-2 w-100" />

        <Col span={22} offset={1}>
          <Card>
            <Typography.Paragraph className="text-uppercase text-bold">
              {t('servicepage.label.operator')}
            </Typography.Paragraph>

            <Row align="middle">
              {staffs &&
                staffs.map((staff) => (
                  <Col span={24} key={`staff-${staff.id}`}>
                    <Form.Item
                      className="m-0 service-staff py-2"
                      wrapperCol={24}
                      labelCol={12}
                      label={staff.name}
                      name={['staff', `${staff.id}`]}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                ))}
            </Row>
          </Card>
        </Col>

        <Col span={22} offset={1}>
          <div className="my-4"></div>
          <Card>
            <Typography.Paragraph
              level={6}
              className="text-uppercase text-bold"
            >
              {t('servicepage.label.color')}
            </Typography.Paragraph>

            <Form.Item name={['color']}>
              <div className="d-flex flex-row" style={{ flexWrap: 'wrap' }}>
                {colors.map((x) => (
                  <div
                    key={`color-${x.name}-${x.code.substr(1)}`}
                    title={x.name}
                    onClick={() => onColorSelect(x)}
                    className={`cursor-pointer color-selected ${
                      color === x.code ? 'active' : ''
                    } my-2 mx-2`}
                  >
                    <div
                      title={x.name}
                      className="colors my-2 mx-2"
                      style={{
                        width: 28,
                        height: 28,
                        background: x.code,
                        borderRadius: 50,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </Form.Item>
          </Card>
        </Col>

        <Col span={22} offset={1}>
          <Card>
            <Button
              htmlType="submit"
              type="primary"
              className="secondary-button"
            >
              {service
                ? t('servicepage.button.update')
                : t('servicepage.button.create')}
            </Button>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

ServiceForm.propTypes = {
  service: PropTypes.object,
  hours: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  staffs: PropTypes.array.isRequired,
};

export default ServiceForm;
