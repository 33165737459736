import axios from 'axios';
import { logout, setAuthError } from '../features/auth/authSlice';
import {
  getFeTokenFromStorage,
  getFrontendTokenFromStorage,
  getTokenFromStorage,
  setEventInStorage,
  setTokenInStorage,
  setTokenValidityInStorage,
} from './token';

const getAxiosInstance = (option = {}, auth = true) => {
  let defaultOption = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 40000,
  };
  let instance = axios.create({ ...defaultOption, ...option });

  if (auth) {
    instance.interceptors.request.use(
      (conf) => {
        conf.headers.Authorization = getTokenFromStorage();
        return conf;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('stored_access_token');
        window.location.replace('/');
      }
      return error;
    },
  );

  return instance;
};

const getAxiosInstanceForFe = (option = {}, auth = true) => {
  let defaultOption = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 40000,
  };
  let instance = axios.create({ ...defaultOption, ...option });

  if (auth) {
    instance.interceptors.request.use(
      (conf) => {
        conf.headers.Authorization = getFeTokenFromStorage();
        return conf;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('user_access_token');
        window.location.replace('/login');
      }
    },
  );

  return instance;
};

const authAxiosRequestInstance = getAxiosInstance({}, false);

const frontendAuthAxiosRequestInstance = getAxiosInstanceForFe({}, false);

const guestAxiosRequestInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 40000,
  headers: {
    Authorization: `Token token="${process.env.REACT_APP_FRONTEND_AUTH_STATIC_TOKEN}"`,
  },
});

const reportAxiosRequestInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 40000,
  headers: {
    Authorization: `Token token="767dffb9ffb6fb30a3dc7eb7e985e1f8"`,
  },
});

/**
 * Create a custom request instance for api use
 *
 */
export const authRequest = () => authAxiosRequestInstance;

export const frontendAuthRequest = () => frontendAuthAxiosRequestInstance;

export const apiRequest = () => guestAxiosRequestInstance;

export const reportRequest = () => reportAxiosRequestInstance;

/**
 * Send get request to the url
 * @param {String} url Url to send get request to
 * @returns
 */
export const getFrontendRequest = (url) => {
  return new Promise((resolve) => {
    frontendAuthRequest()
      .get(url)
      .then((res) => {
        resolve({
          error: false,
          data: res.data,
        });
      })
      .catch((error) => {
        resolve(apiErrorFormatter(error));
      });
  });
};

/**
 * Send get request to the url
 * @param {String} url Url to send get request to
 * @returns
 */
export const getAuthRequest = (url, option = {}) => {
  let instance = option.cancelToken ? getAxiosInstance(option) : authRequest();
  return new Promise((resolve) => {
    instance
      .get(url, option)
      .then((res) => {
        resolve({
          error: false,
          data: res.data,
        });
      })
      .catch((error) => {
        resolve(apiErrorFormatter(error));
      });
  });
};

/**
 * Send post request to the url
 * @param {String} url Url to send post request to
 * @returns
 */
export const postAuthRequest = (url, data) => {
  return new Promise((resolve) => {
    authRequest()
      .post(url, data)
      .then((res) => {
        resolve({
          error: false,
          data: res.data,
        });
      })
      .catch((error) => {
        resolve(apiErrorFormatter(error));
      });
  });
};

/**
 * Send put request to the url
 * @param {String} url Url to send put request to
 * @returns
 */
export const putAuthRequest = (url, data) => {
  return new Promise((resolve) => {
    authRequest()
      .put(url, data)
      .then((res) => {
        resolve({
          error: false,
          data: res.data,
        });
      })
      .catch((error) => {
        resolve(apiErrorFormatter(error));
      });
  });
};

/**
 * Send delete request to the url
 * @param {String} url Url to send delete request to
 * @returns
 */
export const deleteAuthRequest = (url) => {
  return new Promise((resolve) => {
    authRequest()
      .delete(url)
      .then((res) => {
        resolve({
          error: false,
          data: res.data,
        });
      })
      .catch((error) => {
        resolve(apiErrorFormatter(error));
      });
  });
};

/**
 * Validate token and event id and set it to storage if params are valid
 *
 * @param {String} token Access token from system
 * @param {Integer} eventId Event Id of the agenda
 * @returns {Boolen}
 */
export const validateTokenAndSetToStorage = async (
  token,
  eventId,
  validityTime,
) => {
  await setTokenInStorage(token);
  await setEventInStorage(eventId);
  await setTokenValidityInStorage(validityTime);

  try {
    await authRequest().get(
      `/api/business/v1/${eventId}/agenda_appointments/date_search?from=22-06-2000`, // particular date to check server is on
    );
    return true;
  } catch (error) {
    setTokenInStorage(token, true);
    setEventInStorage(token, true);
    setTokenValidityInStorage(validityTime, true);
    return false;
  }
};

/**
 * Format api error according to status
 *
 * @param {Object} error Axios error instance
 */
export const apiErrorFormatter = (error) => {
  if (error.response === undefined) {
    return {
      error: true,
      data: 'Network Issues Encountered',
      status: 1,
    };
  }

  return {
    error: true,
    data: error.response.data,
    status: error.response.status,
  };
};

export const registerInterceptor = (store) => {
  authAxiosRequestInstance.interceptors.request.use(
    (conf) => {
      conf.headers.Authorization = getTokenFromStorage();
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  frontendAuthAxiosRequestInstance.interceptors.request.use(
    (conf) => {
      conf.headers.Authorization = `${getFrontendTokenFromStorage()}`;
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  authAxiosRequestInstance.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (error.isAxiosError && error.code === 'ECONNABORTED') {
        return Promise.reject(error);
      }
      // You can handle error here and trigger warning message without get in the code inside
      if (error.response.status === 401) {
        store.dispatch(logout());
        store.dispatch(setAuthError({ message: error?.data?.message || '' }));
      }

      if (error.response === undefined) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );
};
