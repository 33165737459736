import PropTypes from 'prop-types';
import ScheduleLayer from "./ScheduleLayer";
import BackgroundLayer from "./BackgroundLayer";
import { useCalendarTheme } from "../../../theme";

function WeeklyCalendarBodyLayer({
    hours,
    column,  
    onCellClick,
    currentDate,
}) {
    const { computedStyles } = useCalendarTheme();

    return (
        <div className="calendar-column calendar-column-cell position-relative" style={computedStyles.cellContainerStyle}>
            <div className="calendar-hours-container position-relative">
                {hours.map(i => ( 
                    <BackgroundLayer key={`wbackground-${currentDate}-${column.id}-${i}`} />
                ))}
            </div>
            <div className="agenda-container calendar-absolute-top-left">
                {hours.map((i) => (
                    <ScheduleLayer
                        key={`wagenda-${currentDate}-${column.id}-${i}`} 
                        column={column}
                        hour={i}
                        currentDate={currentDate}
                        onCellClick={onCellClick}
                    />
                ))}
            </div>
        </div>
    );
}


WeeklyCalendarBodyLayer.propTypes = {
    column: PropTypes.object,
    hours: PropTypes.array.isRequired, 
    onCellClick: PropTypes.func
}



export default WeeklyCalendarBodyLayer;