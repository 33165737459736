import { Button, Result } from 'antd';
import React from 'react';
import { logHandler } from './LogHandler';

/**
 * Error handler most commonly used to request after certain time
 * 
 * @returns {ErrorRenderHandler}
 */
class ErrorRenderHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      logHandler.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <Result
            status="warning"
            title="Something went wrong. Sorry we couldnot figure out the issue"
            extra={
              <Button type="primary" onClick={(e) => this.setState({hasError: false})}>
                Reload
              </Button>
            }
          />
        );
      }
  
      return this.props.children; 
    }
}
export default ErrorRenderHandler;