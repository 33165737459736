import React, { useEffect, useState } from 'react';
import { Carousel, Spin } from 'antd';
import {
  AuthHeader,
  DeleteIcon,
  hairdresserBackground,
} from '../../components';
import './HairdresserListPage.less';
import {
  fetchHairdressers,
  hairdresserUnSelected,
  setFrontendHairdresserList,
} from '../../features/frontend/frontendSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserDetailRequest,
  removeHairdresser,
} from '../../features/frontend/frontendApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { t } from 'i18next';
import RemoveVoucherModal from '../../components/modal/RemoveVoucherModal';

const Hairdresser = () => {
  const hairdressers = useSelector(
    (state) => state.frontend.frontendHairdresserList,
  );
  const loading = false;
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const history = useHistory();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedHairdresserId, setSelectedHairdresserId] = useState(null);

  const handleDelete = (hairdresserId) => {
    setSelectedHairdresserId(hairdresserId);
    setOpenRemoveModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedHairdresserId) {
      const res = await removeHairdresser(selectedHairdresserId, userId);

      dispatch(setFrontendHairdresserList(res?.data?.result?.upcoming));
      setOpenRemoveModal(false);
      setSelectedHairdresserId(null);
    }
  };

  const handleNavigateToChooseService = (
    hairdresserId,
    participantId,
    token,
  ) => {
    localStorage.setItem('fe-userId', participantId);
    localStorage.setItem('fe-eventId', hairdresserId);
    localStorage.setItem('stored_access_token', token);
    if (localStorage.getItem('fe-eventId')) {
      history.push(`/choose-service`);
    }
  };

  useEffect(() => {
    fetchUserDetailRequest().then((response) => {
      if (!response?.error) {
        setUserId(response?.data?.id);
      }
    });
    dispatch(hairdresserUnSelected());
    localStorage.removeItem('fe-eventId');
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchHairdressers(userId))
        .unwrap()
        .then((response) => {
          dispatch(setFrontendHairdresserList(response?.upcoming));
        });
    }
  }, [dispatch, userId]);

  return (
    <div className="main-page">
      <AuthHeader />
      <div className="wrapperSlider bg-light-gray">
        <Spin spinning={loading}>
          <Carousel dots={false} dragEnabled={false}>
            <div className="contentSlider">
              <div
                className="hairdresser-header"
                style={{
                  backgroundColor: 'gray',
                  height: '250px',
                  marginLeft: '40px',
                  marginRight: '40px',
                  backgroundImage: `url(${hairdresserBackground})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <h1 className="hairdresser-title">
                  {t('frontend:hairdresser')}
                </h1>
              </div>
              <div
                className="hairdresser-container"
                style={{
                  marginLeft: '40px',
                  marginRight: '40px',
                }}
              >
                {hairdressers.length ? (
                  <div className="hairdresser-list">
                    {hairdressers.map((hairdresser, index) => (
                      <div
                        key={index}
                        className="hairdresser-card"
                        onClick={() =>
                          handleNavigateToChooseService(
                            hairdresser.id,
                            hairdresser.participant_id,
                            hairdresser.token,
                          )
                        }
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <button
                          className="delete-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(hairdresser.id);
                          }}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </button>
                        <div style={{ padding: '20px' }}>
                          <div className="hairdresser-info">
                            <img
                              src={hairdresser.event_image.original}
                              alt={hairdresser.name}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            background:
                              'linear-gradient(180deg, rgba(177, 148, 153, 0.0488) -75%, rgba(177, 148, 153, 0.61) 100%)',
                            padding: 10,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            fontSize: 16,
                            color: 'white',
                          }}
                        >
                          {hairdresser.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="hairdresser-empty">
                    {t('frontend:no_hairdresser')}
                  </div>
                )}
              </div>
            </div>
          </Carousel>
        </Spin>
      </div>

      <RemoveVoucherModal
        open={openRemoveModal}
        // title={t('frontend:removeHairdresserTitle')}
        // content={t('frontend:removeHairdresserMessage')}
        closeModal={() => setOpenRemoveModal(false)}
        handleOK={handleConfirmDelete}
      />
    </div>
  );
};

export default Hairdresser;
