import { Avatar, Card, Upload } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_UPDATED } from '../app/constants';
import ProfileForm from '../components/form/ProfileForm';
import { parseApiResponseAndSendNotification } from '../handlers/ApiErrorNotifier';
import { logHandler } from '../handlers/LogHandler';
import { authRequest } from '../services/api';
import { DefaultPageLayout } from './../components/index';
import { UserOutlined } from '@ant-design/icons';

function ProfilePage() {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [image, setImage] = useState(null);

  const { t } = useTranslation();

  const fetchApiData = useCallback(() => {
    setLoading(false);
    authRequest()
      .get(`/api/business/v1/users/profile`)
      .then((res) => {
        setProfile(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleProfileUpdate = useCallback(
    (data) => {
      setLoading(true);
      let updateData = image ? { ...data, image: image } : data;

      authRequest()
        .put(`/api/business/v1/users/update`, updateData)
        .then((res) => {
          parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
          setProfile(res.data);
          setImage(null);
          setLoading(false);
        })
        .catch((error) => {
          logHandler.error(error);
          parseApiResponseAndSendNotification(error, NOTIFICATION_UPDATED);
          setLoading(false);
        });
    },
    [image],
  );

  useEffect(() => fetchApiData(), [fetchApiData]);

  const beforeUpload = (file) => false;

  const handleImageChange = (info) => {
    setLoading(true);
    getBase64(info.file, (imageUrl) => {
      setImage(imageUrl);
      setLoading(false);
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const dummyRequest = ({ file, onSuccess }) =>
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  const addIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V13H8C7.73478 13 7.48043 12.8946 7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929C7.48043 11.1054 7.73478 11 8 11H11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V11H16C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13H13V16Z"
        fill="#939292"
      />
    </svg>
  );
  return (
    <DefaultPageLayout loadingContent={loading} hideSidebar={true}>
      <DefaultPageLayout.Title>
        <div className="page-title-ag">
          <UserOutlined className="iconHeader" />
          <span className="text-uppercase">{t('common:profile')}</span>
        </div>
      </DefaultPageLayout.Title>

      <DefaultPageLayout.Content>
        <div
          className="text-center"
          style={{ position: 'relative', marginTop: '40px' }}
        >
          <Upload
            multiple={false}
            className="cursor-pointer position-relative"
            showUploadList={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            customRequest={dummyRequest}
            onChange={handleImageChange}
          >
            <div
              style={{ position: 'absolute', right: 0, zIndex: 1, top: -44 }}
            >
              {addIcon}
            </div>
            <Avatar src={image || profile.image} size={120} />
          </Upload>
          <h3 className="mt-2 username text-capitalize px-2">{`${profile.name}`}</h3>
        </div>
        <div
          style={{
            width: '100%',
            padding: '20px 40px',
          }}
        >
          <Card
            className="w-100"
            style={{
              margin: '0 auto',
              maxWidth: '960px',
            }}
          >
            <ProfileForm
              initialValue={profile}
              onSubmit={handleProfileUpdate}
            />
          </Card>
        </div>
      </DefaultPageLayout.Content>
    </DefaultPageLayout>
  );
}

export default ProfilePage;
