import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getEventFromStorage } from "../services/token";

function CommunicationPage() {
    const { t } = useTranslation();
    
    useEffect(() => {
        let eventId = getEventFromStorage();
        window.location.href = `${process.env.REACT_APP_BACKEND_BASE_URL}/it/events/${eventId}/press_releases`;
        return () => {}
    }, [])

    return (
        <div>
            <h1>{ t('common:redirect_to_communication') }</h1>
        </div>
    );
}

export default CommunicationPage;