import { updateScheduleRequest } from '../../features/schedule/scheduleApi';
import {
  CustomWindowEvent,
  triggerEventListenerRefresh,
} from '../../services/event';

var counter = 0;

export const zeroNum = (v, n) => {
  if (!n) n = 2;
  let l = (v + '').length,
    r;
  for (r = l; r < n; r++) v = '0' + v;
  return v;
};

// hm1 - hm2  format 'hh:mm'
// return seconds
export const diffHoursMin = (hm1, hm2) => {
  let t1,
    t2,
    v1 = hm1.split(':'),
    v2 = hm2.split(':');

  t1 = +v1[0] * 3600 + +v1[1] * 60;
  t2 = +v2[0] * 3600 + +v2[1] * 60;

  return t1 - t2;
};

// input seconds
// return "hh:mm"
export const getHourMinFromSec = (sec) => {
  let h = Math.floor(sec / 3600),
    m = Math.floor((sec - h * 3600) / 60);

  return zeroNum(h) + ':' + zeroNum(m);
};

// date: "2023-11-28 10:30" delta 1800 seconds
// return new date
export const addDeltaToDate = (date, delta) => {
  if (!delta) return date;
  let sec = 0,
    dt = date.split(' ');

  if (dt.length > 1) sec = diffHoursMin(dt[1], '00:00');
  sec += delta || 0;

  return dt[0] + ' ' + getHourMinFromSec(sec);
};

// input "2023-10-28 10:30", "2023-11-29"
// return new date "2023-11-29 10:30"
export const changeDateYMD = (dtFrom, dtTo) => {
  let dtf = dtFrom.split(' '),
    dtt = dtTo.split(' ');
  return dtt[0] + ' ' + dtf[1];
};

// move appointments
/*
param = {
  schedules: [ {...}, ... ],
  dateFrom: '2023-11-29',
  dateTo: '2023-11-30',
}
*/
export const moveMultiAppointment = (param, delta) => {
  //console.log(delta, 'param', param);
  let r,
    startDate,
    endDate,
    numSch = param.schedules.length;

  delta = delta || 0;
  counter = 0;

  for (r = 0; r < numSch; r++) {
    startDate = changeDateYMD(param.schedules[r].start_date, param.dateTo);
    endDate = changeDateYMD(param.schedules[r].end_date, param.dateTo);

    if (delta) {
      startDate = addDeltaToDate(startDate, delta);
      endDate = addDeltaToDate(endDate, delta);
    }

    const newData = {
      id: param.schedules[r].id,
      startDate: startDate,
      endDate: endDate,
      staffId: param.staffId,
      operator: param.schedules[r].operator,
      participantId: param.schedules[r].participant.id,
      phoneNumber: param.schedules[r]?.phone_number || '',
      service: param.schedules[r].services,
      new_participant: param.schedules[r].participant.new_participant,
      appointment_status: null,
    };

    // update api
    updateScheduleRequest(newData).then(() => {
      counterFinish(param.dateTo);
    });
  }
};

const counterFinish = (dateTo) => {
  counter--;
  // when finish
  if (counter <= 0) {
    localStorage.removeItem('copy_type');
    localStorage.removeItem('copy_schedule');
    // event refresh
    triggerEventListenerRefresh(CustomWindowEvent.SCHEDULE_REFRESH, dateTo);
  }
};

export const cloneDeep = (a) => {
  if (!a) return;
  var d,
    c = typeof a.length == 'undefined' ? {} : [];
  for (d in a) c[d] = typeof a[d] == 'object' ? cloneDeep(a[d]) : a[d];
  return c;
};
