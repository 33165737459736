import { getAuthRequest, putAuthRequest } from '../../services/api';
import { getEventFromStorage } from '../../services/token';

// A mock function to mimic making an async request for data
export function fetchSchedulesRequest(startDate, endDate = null, option = {}) {
  const queryParam =
    endDate === null ? `from=${startDate}` : `from=${startDate}&to=${endDate}`;
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments/date_search?${queryParam}`,
    option,
  );
}

export function fetchDeleteAppointmentReasonRequest() {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/appointment_delete_note?locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

/**
 * Update dragged schedule
 *
 * @param {Object} schedule Schedule to update
 * @returns {Object}
 */
export function updateDraggedScheduleRequest(schedule) {
  const eventId = getEventFromStorage();
  return putAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments/${schedule.id}`,
    {
      start_date: schedule.start_date,
      end_date: schedule.end_date,
      staff_id: schedule.staff_id,
      service_ids: schedule.service_ids,
      operator: schedule.operator,
      updated_by: 0,
    },
  );
}

/**
 * Update schedule
 *
 * @param {Object} schedule Schedule to update
 * @returns {Object}
 */
export function updateScheduleRequest(schedule) {
  const eventId = getEventFromStorage();
  const data = { ...schedule };
  const id = schedule.id;
  delete data.id;
  return putAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments/${id}`,
    data,
  );
}

/**
 * Get status colors for calendar
 * @returns {Object}
 */
export function fetchStatusColorsRequest() {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/appointment_status?locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}
