// Timeline 
import { Card, Timeline } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import './AppointmentTimeline.less';
import ClockIcon from "./../icons/ClockIcon.svg";
import StaffIcon from "./../icons/StaffIcon.svg";
import DurationIcon from "./../icons/DurationIcon.svg";
import NoteIcon from "./../icons/NoteIcon.svg";
import { logHandler } from '../../handlers/LogHandler';
import { Link } from 'react-router-dom';


function AppointmentTimeline({ timeline }) {
    const getDuration = (item) => {
        try{
            let diff = (new Date(item.end_date)) - (new Date(item.start_date));
            let duration = moment(diff).utc();
            
            return duration.format("HH:mm");
        }catch(error) {
            logHandler.error(error);
            return "N/A";
        }
    }

    const sortByDateDesc = (data) => {
        try{
            return [ ...data ].sort((a,b) => {
                return (new Date(b.start_date)) - (new Date(a.start_date));
            });
        }catch(error) {
            logHandler.error(error);
            return data;
        }
    }

    return (
        <div className="timeline">
            {!!timeline.length && (
                <Timeline>
                    {sortByDateDesc(timeline).map(item => (
                        <Timeline.Item className="d-flex detail-wrapper" key={`timeline-${item.id}-${item.staff_id}`}>
                            <div className="time">
                                <span className="text">
                                    {moment(item.start_date).format('dddd')} <br />
                                    {moment(item.start_date).format('DD/MM/YYYY HH:mm')}
                                </span>
                            </div>

                            <Card className="detail ml-4 p-2" aria-label="appointment card">
                                <div className="d-flex">
                                    <div className="py-2 d-flex">
                                        <div className="color" style={{ background: item.color, width: 10, height: 10, position: 'relative', top: 6 ,borderRadius: 50, marginRight: 6 }}></div>
                                        <span className="title font-14 text-bold">{item.services.length ? item.services[0].title : ""}</span>
                                    </div>
                                </div>
                                <div className="icons pl-2 d-flex flex-column">
                                    <div className="d-flex flex-row py-1">
                                        <div className="px-2 font-16">
                                            <img src={ClockIcon} alt="appointment time" /> 
                                            <span aria-label="appointment time"> {moment(item.start_date).format('HH:mm')}</span>
                                        </div>
                                        <div className="px-2 font-16">
                                            <img src={StaffIcon} alt="staff" /> 
                                            <span aria-label="staff name"> {item.staff.name}</span>
                                        </div>
                                        <div className="px-2 font-16">
                                            <img src={DurationIcon} alt="appointment duration" /> 
                                            <span aria-label="appointment duration"> { getDuration(item) } </span>
                                        </div>
                                    </div>
                                    <div className="flex-1 py-1 px-2">
                                        <Link to={`/agenda?view=daily&from=${item.start_date.split(" ")[0]}&scheduleModal=${item.id}`}>
                                        <img src={NoteIcon} alt="" /> 
                                        <span className="text-hightlighted font-12 mx-1">Aggiungi note</span>
                                        </Link>
                                    </div>
                                </div>

                            </Card>
                            
                        </Timeline.Item>
                    ))}
                </Timeline>
            )}
        </div>
    );  
}

AppointmentTimeline.propTypes = {
    timeline: PropTypes.arrayOf(PropTypes.object)
}


export default AppointmentTimeline;