import { Modal, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CancelModal({ open = false, title, content, closeModal, handleOK }) {
  const { t } = useTranslation();

  return (
    <Modal
      title={title || t('frontend:cancelConfirmation')}
      open={open}
      onCancel={closeModal}
      width={'400px'}
      okType={'default'}
      footer={[
        <Button key="back" className="round-button" onClick={closeModal}>
          {t('frontend:Cancel')}
        </Button>,

        <Button key="ok" className="modal-button" onClick={handleOK}>
          OK
        </Button>,
      ]}
    >
      <Typography.Paragraph>{content || ''}</Typography.Paragraph>
    </Modal>
  );
}

CancelModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  handleOK: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
};

export default CancelModal;
