import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../../../services/api';
import './DateTime.less';
import calendarSvg from './calendar_book.svg';
/**
 * Routes component containing routes for the whole application
 *
 * @returns {JSX}
 *
 */
export default function DateTime({
  selectedStaff,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  currentDate,
  setCurrentDate,
  timeSlots,
  setTimeSlots,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const getTimeSlotsFromApi = useCallback(
    async (selectedDate) => {
      setLoading(true);
      const eventId = localStorage.getItem('fe-eventId');
      const data = {
        date: selectedDate,
        time_slots: true,
        data: Object.keys(selectedStaff).map((serviceId) => ({
          service_id: serviceId,
          staff_id: selectedStaff[serviceId],
        })),
      };
      await apiRequest()
        .post(
          `/api/business/v1/${eventId}/agenda_appointments/time_slots?locale=${localStorage.getItem(
            'lang',
          )}`,
          data,
        )
        .then((res) => {
          setTimeSlots(res.data);
        })
        .catch((err) => {
          setTimeSlots({ times: [] });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedStaff, setTimeSlots],
  );

  useEffect(() => {
    selectedDate && getTimeSlotsFromApi(selectedDate.format('YYYY-MM-DD'));
  }, [getTimeSlotsFromApi, selectedDate]);

  const onPrevDateClick = (e) =>
    currentDate.isAfter() &&
    setCurrentDate(currentDate.clone().subtract(1, 'days'));

  const onNextDateClick = (e) =>
    setCurrentDate(currentDate.clone().add(1, 'days'));

  const NoData = (
    <div className="selectTime">
      {selectedDate
        ? t('frontend:appointmenttimepage.not_available')
        : t('frontend:appointmenttimepage.select_date')}
    </div>
  );

  const isSelectedTimeSlot = (time) =>
    selectedDate.format('YYYY-MM-DD') === selectedTime.date &&
    selectedTime.from === time.from &&
    selectedTime.to === time.to;

  const generateTimeSlots = (timeSlots) => (
    <div className="listRangeTime">
      <div className="selectTime">
        {t('frontend:appointmenttimepage.selectTimeLedgend')}
      </div>

      <ul className="timelist">
        {timeSlots.map((time) => (
          <li
            key={`${time.from}-${time.to}`}
            className={isSelectedTimeSlot(time) ? 'active' : ''}
          >
            <div
              className="arial d-flex justify-content-between align-items-center py-2 pr-2"
              onClick={() =>
                setSelectedTime({
                  ...time,
                  date: selectedDate.format('YYYY-MM-DD'),
                })
              }
            >
              <div>
                <span className="rangeForm">{time.from} </span>
                <span className="rangeTo">
                  {' '}
                  {t('calendar.until_about')} {time.to}
                </span>
              </div>

              <div style={{ paddingTop: '8px' }}>
                {isSelectedTimeSlot(time) ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="17" cy="17" r="16" stroke="#888" />
                    <circle cx="17" cy="17" r="16" fill="#B19499" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.7594 22.5336L24.1663 14.1267C24.4518 13.8413 24.4518 13.3784 24.1663 13.0929L23.1325 12.0591C22.847 11.7736 22.3842 11.7736 22.0986 12.0591L15.2425 18.9152L12.0415 15.7142C11.7561 15.4287 11.2932 15.4287 11.0077 15.7142L9.97387 16.748C9.6884 17.0335 9.6884 17.4964 9.97387 17.7818L14.7256 22.5336C15.0111 22.8191 15.4739 22.8191 15.7594 22.5336Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="17" cy="17" r="16" stroke="#888" />
                  </svg>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const resetTimeDate = () => {
    setSelectedTime({
      from: '',
      to: '',
      date: '',
    });
  };

  return (
    <div className="main-page">
      <div className="wrapperDateTime">
        <img src={calendarSvg} className="imgCalendar" alt="Icon Calendario" />
        <div className="monthTitle">
          {(selectedDate || currentDate).format('MMMM yyyy')}
        </div>

        <div className="select-time-calendar">
          <button
            type="button"
            className="change-date prev-date"
            style={{
              padding: '10px 6px 10px 6px',
              borderRadius: '9px 0 0 9px',
            }}
            onClick={onPrevDateClick}
          >
            <LeftOutlined />
          </button>
          <table className="calendar-table">
            <thead>
              <tr>
                {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                  <th
                    className="text-capitalize"
                    key={`date-${currentDate
                      .clone()
                      .add(item, 'days')
                      .format('YYYY-MM-DD')}-title`}
                  >
                    {currentDate.clone().add(item, 'days').format('dd')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                  <td
                    key={`date-${currentDate
                      .clone()
                      .add(item, 'days')
                      .format('YYYY-MM-DD')}-value`}
                    onClick={() => {
                      resetTimeDate();
                      setSelectedDate(currentDate.clone().add(item, 'days'));
                    }}
                  >
                    <p
                      className={`my-0 text-center date ${
                        selectedDate &&
                        currentDate
                          .clone()
                          .add(item, 'days')
                          .format('YYYY-MM-DD') ===
                          selectedDate.format('YYYY-MM-DD')
                          ? 'selected-date'
                          : ''
                      }`}
                    >
                      {currentDate.clone().add(item, 'days').format('DD')}
                    </p>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            className="change-date next-date"
            style={{
              padding: '10px 6px 10px 6px',
              borderRadius: '0 9px 9px 0',
            }}
            onClick={onNextDateClick}
          >
            <RightOutlined />
          </button>
        </div>

        <div>
          <Spin spinning={loading}>
            {timeSlots &&
              (timeSlots.times === undefined || !timeSlots.times.length) && (
                <div style={{ marginTop: '24px' }}>{NoData}</div>
              )}
            {timeSlots &&
              timeSlots.times &&
              !!timeSlots.times.length &&
              generateTimeSlots(timeSlots.times)}
          </Spin>
        </div>
      </div>
    </div>
  );
}
