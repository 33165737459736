import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_ACTION } from '../../app/constants';
import { updateError } from '../../components/util/Helper';
import { parseApiResponseAndSendNotification } from '../../handlers/ApiErrorNotifier';
import {
  fetchFrontendHairdressers,
  fetchShopDetailRequest,
  fetchUserDetailRequest,
  postFrontendLoginRequest,
  postFrontendRegisterRequest,
  setapp,
} from './frontendApi';

const initialState = {
  user: {}, // User Detail
  services: [], // Selected Services With Staff If Selected
  appointmentDate: null, // Appointment Date Selection
  appointmentTime: null, // Appointment Time Selection
  shop: {}, // Shop Detail
  isHairdresserSelected: false,
  isLoggedIn: !!localStorage.getItem('user_access_token'),
  error: {
    hasError: false,
    status: 0,
    data: null,
  },
  appointments: [],
  message: {},
  loading: false,
  fetchingData: [],
  frontendHairdresserList: [],
};

// Fetch Appointment api GET method
// http://127.0.0.1:3000/api/business/v1/2/agenda_appointments?participant=82

export const loginCustomer = createAsyncThunk(
  'frontend/loginCustomer',
  async (payload) => {
    const response = await postFrontendLoginRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    parseApiResponseAndSendNotification(response, NOTIFICATION_ACTION);
    return response.error ? response : response.data;
  },
);

export const registerCustomer = createAsyncThunk(
  'frontend/registerCustomer',
  async (payload) => {
    const response = await postFrontendRegisterRequest(payload);

    console.log(response, 'teradsfjlkaj');

    // The value we return becomes the `fulfilled` action payload
    parseApiResponseAndSendNotification(response.response, NOTIFICATION_ACTION);
    return response.error ? response : response.data;
  },
);

export const fetchShopDetail = createAsyncThunk(
  'frontend/fetchShopDetail',
  async (payload) => {
    const response = await fetchShopDetailRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data.details;
  },
);

export const fetchHairdressers = createAsyncThunk(
  'frontend/fetchFrontendHairdressers',
  async (payload) => {
    const response = await fetchFrontendHairdressers(payload);

    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data.result;
  },
);

export const Fsetapp = createAsyncThunk('frontend/setapp', async (payload) => {
  const response = await setapp(payload);

  // The value we return becomes the `fulfilled` action payload
  return response.error ? response : response.data.details;
});

export const fetchUserDetail = createAsyncThunk(
  'frontend/fetchUserDetail',
  async (payload) => {
    const response = await fetchUserDetailRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const frontendSlice = createSlice({
  name: 'frontend',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFrontendUser: (state, action) => {
      state.user = action.payload;
    },

    setFrontendShop: (state, action) => {
      state.shop = action.payload;
    },

    setFrontendAppointmentDate: (state, action) => {
      state.appointmentDate = action.payload;
    },

    setFrontendAppointmentTime: (state, action) => {
      state.appointmentTime = action.payload;
    },

    setFrontendSelectedService: (state, action) => {
      state.services = action.payload;
    },

    frontendLogout: (state) => {
      state.isLoggedIn = false;
      state.loading = false;
      state.message = null;
      state.error = false;
    },

    frontendLogin: (state) => {
      state.isLoggedIn = true;
      state.message = null;
      state.error = false;
      state.loading = false;
    },

    hairdresserSelected: (state) => {
      state.isHairdresserSelected = true;
      state.message = null;
      state.error = false;
      state.loading = false;
    },
    hairdresserUnSelected: (state) => {
      state.isHairdresserSelected = false;
      state.message = null;
      state.error = false;
      state.loading = false;
    },

    frontendHairdresserList: (state) => {
      state.frontendHairdresserList = [];
    },

    setFrontendHairdresserList: (state, action) => {
      state.frontendHairdresserList = action.payload;
    },
    frontendUserIsAuthenticating: (state) => {
      state.loading = true;
    },

    // set authentication error
    setFrontendAuthError: (state, action) => {
      const { message } = action.payload;
      state.error = true;
      state.message = message;
      state.loading = false;
      state.isLoggedIn = false;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginCustomer.pending, (state) => {
        state.fetchingData.push('loginCustomer');
        state.loading = true;
      })
      .addCase(loginCustomer.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'loginCustomer',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }
        if (action.payload.status > 400) {
          return;
        }

        const data = action.payload;
        state.user = data.participant;
        state.isLoggedIn = true;
        state.message = null;
        state.error = false;
      })
      .addCase(loginCustomer.rejected, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'loginCustomer',
        );
        state.loading = !!state.fetchingData.length;
        state.error = {
          hasError: true,
          status: action.error.code,
          data: action.error.message,
        };
      });

    builder
      .addCase(fetchShopDetail.pending, (state) => {
        state.fetchingData.push('fetchShopDetail');
        state.loading = true;
      })
      .addCase(fetchShopDetail.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchShopDetail',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }
        state.shop = action.payload;
      })
      .addCase(fetchShopDetail.rejected, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchShopDetail',
        );
        state.loading = !!state.fetchingData.length;
        state.error = {
          hasError: true,
          status: action.error.code,
          data: action.error.message,
        };
      });

    builder
      .addCase(fetchHairdressers.pending, (state) => {
        state.fetchingData.push('fetchHairdressers');
        state.loading = true;
      })
      .addCase(fetchHairdressers.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchHairdressers',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }
        state.frontendHairdresserList = action.payload;
      })
      .addCase(fetchHairdressers.rejected, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchHairdressers',
        );
        state.loading = !!state.fetchingData.length;
        state.error = {
          hasError: true,
          status: action.error.code,
          data: action.error.message,
        };
      });

    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.fetchingData.push('fetchUserDetail');
        state.loading = true;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchUserDetail',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }

        state.user = action.payload;
        state.isLoggedIn = true;
        state.message = null;
        state.error = { hasError: false, status: null, data: null };
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchUserDetail',
        );
        state.loading = !!state.fetchingData.length;
        if (action.error.message.includes('401')) {
          state.isLoggedIn = false;
          state.message = null;
          state.error = { hasError: false, status: null, data: null };
        } else {
          state.error = {
            hasError: true,
            status: action.error.code,
            data: action.error.message,
          };
        }
      });

    builder
      .addCase(registerCustomer.pending, (state) => {
        state.fetchingData.push('registerCustomer');
        state.loading = true;
      })
      .addCase(registerCustomer.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'registerCustomer',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }

        state.isLoggedIn = true;
        state.message = null;
        state.error = false;
      })
      .addCase(registerCustomer.rejected, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'registerCustomer',
        );
        state.loading = !!state.fetchingData.length;
        state.error = {
          hasError: true,
          status: action.error.code,
          data: action.error.message,
        };
      });
  },
});

export const {
  frontendLogout,
  frontendLogin,
  hairdresserSelected,
  hairdresserUnSelected,
  setFrontendUser,
  setFrontendShop,
  setFrontendAppointmentDate,
  setFrontendAppointmentTime,
  setFrontendSelectedService,
  setFrontendHairdresserList,
  frontendHairdresserList,
} = frontendSlice.actions;

export const isFrontendLoggedIn = (state) => state.frontend.isLoggedIn;
export const isFrontendHairdresserSelected = (state) =>
  state.frontend.isHairdresserSelected;
export const isFrontendLoading = (state) => state.frontend.loading;
export const frontendFetchingData = (state) => state.frontend.fetchingData;
export const hasFrontendAuthError = (state) => state.frontend.error;
export const frontendSelectedServices = (state) => state.frontend.services;

export const frontendUser = (state) => state.frontend.user;
export const frontendAppointmentDate = (state) =>
  state.frontend.appointmentDate;
export const frontendAppointmentTime = (state) =>
  state.frontend.appointmentTime;
export const frontendAuthMessage = (state) => state.frontend.message;

export const shopDetail = (state) => state.frontend.shop;
export const shopName = (state) => state.frontend.shop?.name;

export default frontendSlice.reducer;
