// Modal Helpers for create/edit mode
export const INITIAL_MODE = 'initial';
export const SHOW_MODE = 'show';
export const CREATE_MODE = 'create';
export const EDIT_MODE = 'edit';
export const EMPTY_MODE = 'empty';

export const NOTIFICATION_CREATED = 'create';

export const NOTIFICATION_UPDATED = 'update';

export const NOTIFICATION_DELETED = 'delete';

export const NOTIFICATION_ACTION = 'action';


export const LANG_TYPES = {
    "en": "en-US",
    "it": "it-IT",
}