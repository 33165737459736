import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  DatePicker,
  Row,
  Select,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorRenderHandler from '../../../handlers/ErrorRenderHandler';
import './ScheduleModal.less';

const { Option } = Select;

/**
 * Recurring Modal For Schedule
 * @param {*} param0
 * @returns JSX
 */
function RecurringModal({ open = true, closeModal, recurringData, onSubmit }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [checkedService, setCheckedService] = useState([]);
  const repititiveType = Form.useWatch('ripeti', form);

  const disableTimes = (date) =>
    recurringData ? date.isBefore(recurringData.date.date, 'date') : false;

  const handleSubmit = (data) => {
    let newData = { ...recurringData, ...data };
    newData.serviceIds = checkedService;
    checkedService.length &&
      typeof onSubmit === 'function' &&
      onSubmit(newData);
  };

  const title = (
    <Row align="middle">
      <Col span={20} offset={2}>
        <p className="text-center text-uppercase m-0 pt-3">
          {t('calendar.repititivemodal.title')}
        </p>
      </Col>
    </Row>
  );

  const addService = (selectedService) => {
    let itemId = selectedService.service.id;
    if (checkedService.includes(itemId)) {
      setCheckedService(
        checkedService.filter((x) => x !== itemId && x !== undefined),
      );
      return;
    }
    setCheckedService(
      checkedService.length ? [...checkedService, itemId] : [itemId],
    );
  };

  const serviceList = () => {
    let services = recurringData.services;
    if (!services.length) return;

    return services.map((item) => (
      <div
        key={`service-${item.service_id}`}
        className="badge cursor badge-service"
        onClick={() => addService(item)}
      >
        <Form.Item
          className="my-0 pb-1 pt-2"
          htmlFor={`service-${item.service_id}`}
          labelAlign="left"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 0 }}
          rules={[{ required: true }]}
        >
          <span className="text font-12 pr-2">{item.service.title}</span>
          <Checkbox
            checked={checkedService.includes(item.service_id)}
            id={`service-${item.service_id}`}
            value={item}
          />
        </Form.Item>
      </div>
    ));
  };

  const datePlaceholder =
    repititiveType === 'every_week'
      ? 'calendar.repititivemodal.placeholder.weekdate'
      : 'calendar.repititivemodal.placeholder.date';

  return (
    <Modal
      wrapClassName="modal-bg"
      title={title}
      footer={null}
      open={open}
      onCancel={closeModal}
      closable={false}
      maskClosable={true}
    >
      <ErrorRenderHandler>
        <Form form={form} onFinish={handleSubmit} autoComplete="off">
          <Row align="middle">
            <Col span={24}>
              <Form.Item
                className="my-0 py-2"
                name={'ripeti'}
                labelAlign="left"
                wrapperCol={{ span: 25 }}
                labelCol={{ span: 0 }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('calendar.repititivemodal.placeholder.repeat')}
                  size="large"
                >
                  <Option key={`once_a_time`} value={'once_a_time'}>
                    {t('common:one_time')}
                  </Option>
                  <Option key={`every_week`} value={'every_week'}>
                    {t('common:every_week')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                className="my-0 pb-1 pt-2"
                labelAlign="left"
                name={'repeat_until'}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 0 }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  disabledDate={disableTimes}
                  size="large"
                  className="w-100"
                  placeholder={t(datePlaceholder)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Typography.Paragraph className="title-1 mt-4 text-uppercase mb-2">
            {t('calendar.repititivemodal.service_title')}
          </Typography.Paragraph>

          <Row align="middle">
            <Col span={24}>
              <div className="d-flex flex-row">{serviceList()}</div>
              {!checkedService.length && (
                <Typography.Paragraph className="ant-form-item-explain ant-form-item-explain-error mt-2">
                  {t('error:appointment.repititivemodal.select_a_service')}
                </Typography.Paragraph>
              )}
            </Col>
          </Row>

          <Row align="middle mt-4">
            <Col span={24}>
              <div className="d-flex flex-column align-items-center">
                <Button
                  htmlType="submit"
                  style={{ minWidth: 250 }}
                  className="text-uppercase mb-2"
                  type="primary"
                >
                  {t('common:button.confirm')}
                </Button>
                <Button
                  htmlType="button"
                  style={{ minWidth: 250 }}
                  className="text-uppercase mb-2"
                  onClick={closeModal}
                >
                  {t('common:button.cancel')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ErrorRenderHandler>
    </Modal>
  );
}

RecurringModal.propTypes = {
  open: PropTypes.bool,
  dateOption: PropTypes.object,
  staff: PropTypes.object,
  closeModal: PropTypes.func,
};

export default RecurringModal;
