import { Button, Card, Col, Row, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InbellezzaIcon } from '../../components';
import {
  fetchShopDetail,
  fetchUserDetail,
  frontendLogin,
  frontendLogout,
  isFrontendLoggedIn,
  shopName,
} from '../../features/frontend/frontendSlice';
import { setCurrentLanguage } from '../../services/language';
import {
  getEventFromStorage,
  getFrontendTokenFromStorage,
  setEventInStorage,
  setFrontendTokenInStorage,
} from '../../services/token';
import './EmbedPage.less';

const { Title, Paragraph } = Typography;

/**
 * Routes component containing routes for the whole application
 *
 * @returns {JSX}
 */
export default function EmbedPage() {
  const auth = useSelector(isFrontendLoggedIn);
  const shop = useSelector(shopName);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchShopDetail());
  }, [dispatch]);

  const authorizeUser = useCallback(async () => {
    await dispatch(fetchUserDetail()).then((res) => {
      dispatch(res.payload.id ? frontendLogin() : frontendLogout());
    });
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      return;
    }
    setCurrentLanguage(`${localStorage.getItem('lang')}`);
    const query = new URLSearchParams(window.location.search);
    let token = query.get('token');
    let eventId = query.get('eventId');

    if (token === null && eventId === null) {
      token = getFrontendTokenFromStorage();
      eventId = getEventFromStorage();
    }

    setFrontendTokenInStorage(token);
    setEventInStorage(eventId);

    token && eventId && !auth && authorizeUser(token, eventId);
    if (token === null && eventId === null) {
      dispatch(frontendLogout());
    }
  }, [auth, authorizeUser, dispatch]);

  const redirectToLoginPage = () => {
    if (auth) {
      history.push('/choose-service');
    }
    history.push('/login');
  };

  return (
    <div className="homepage embed-page">
      <Row type="flex" align="middle" className="w-100 vh-100 text-center">
        <Col offset={6} span={12}>
          <Title level={2}>{shop}</Title>

          <div>
            <Card className="cardEmbed">
              <Title level={4}>{t('frontend:embedpage.cardtitle')}</Title>

              <Paragraph className="text-center">
                <Button
                  className="round-btn"
                  onClick={redirectToLoginPage}
                  style={{ margin: 10 }}
                >
                  {t('frontend:embedpage.button')}
                </Button>
              </Paragraph>

              <img
                src={InbellezzaIcon}
                alt={'Inbellezza icon'}
                style={{ width: '80%', maxWidth: '200px' }}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}
