import { useEffect } from "react";
import { Col, Row, Typography } from "antd";
import { useHistory } from "react-router-dom";

const { Title, Paragraph } = Typography;

/**
 * Unauthorized message for user
 * 
 * @returns {JSX}
 */
function Unauthorized({ title, description , message }) {

    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push('/redirect');
        }, 1000);
    }, [history]);

    return (
        <div className="homepage">
            <Row type="flex" align="middle" className="w-100 vh-100 text-center">
                <Col offset={6} span={12}>
                    <div>
                        <Title aria-labelledby="Unauthorized" level={2}>{title || "Unauthorized Access In Agenda Management"}</Title>
                        <Paragraph className="text-center">
                            {description || "You are unauthorized to access this page. Please come back from backend"}
                        </Paragraph>
                        {message && (
                            <p aria-labelledby="unauthorizedErrorMessage" className="lead text-danger">{message}</p>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}


export default Unauthorized;