import {
  PlusCircleFilled,
  ScissorOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Input,
  List,
  notification,
  Row,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CREATE_MODE,
  EMPTY_MODE,
  INITIAL_MODE,
  NOTIFICATION_CREATED,
  NOTIFICATION_UPDATED,
} from '../app/constants';
import { filterDataFromKeyword, sortByKey } from '../components/util/Helper';
import {
  createStaff,
  fetchStaffHolidayList,
  fetchStaffList,
  isStaffLoading,
  removeStaff,
  selectStaffHolidayList,
  selectStaffList,
  updateStaff,
} from '../features/staff/staffSlice';
import { parseApiResponseAndSendNotification } from '../handlers/ApiErrorNotifier';
import { logHandler } from '../handlers/LogHandler';
import { authRequest } from '../services/api';
import { getEventFromStorage } from '../services/token';
import { staffTransformerResource } from '../transformers/staffTransformer';
import {
  DefaultPageLayout,
  StaffCreateForm,
  StaffDetail,
  StaffService,
  UserPlaceholder,
} from './../components/index';

const { Paragraph } = Typography;

const SERVICE_MODE = 'service';

function StaffPage() {
  const [type, setType] = useState(EMPTY_MODE);
  const [selectedStaff, setStaff] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const holidays = useSelector(selectStaffHolidayList);
  const staffs = useSelector(selectStaffList);
  const isSidebarLoading = useSelector(isStaffLoading);

  const fetchStaffData = useCallback(
    () => dispatch(fetchStaffList()),
    [dispatch],
  );
  const onSearch = (event) => setSearchKeyword(event.target.value);

  useEffect(() => {
    let keyword = searchKeyword.length ? searchKeyword.toLowerCase() : '';
    let filteredStaffs = filterDataFromKeyword(staffs, keyword, [
      'name',
      'email',
      'phone',
    ]);
    filteredStaffs = sortByKey(filteredStaffs, 'name');

    setStaffList(filteredStaffs);
  }, [searchKeyword, staffs]);

  useEffect(() => {
    fetchStaffData();
  }, [fetchStaffData]);

  const fetchStaffService = (staff) => {
    setLoading(true);
    setStaff(staff);
    let eventId = getEventFromStorage();
    authRequest()
      .get(
        `/api/business/v1/events/${eventId}/staffs/${
          staff.id
        }/service_list?locale=${localStorage.getItem('lang')}`,
      )
      .then((res) => {
        setType(EMPTY_MODE);
        setServiceList(res.data.services);
        setLoading(false);
        setType(SERVICE_MODE);
      })
      .catch((err) => {
        setType(EMPTY_MODE);
        setLoading(false);
      });
  };

  const deleteStaffHoliday = (holiday) => {
    setLoading(true);
    authRequest()
      .delete(`/api/business/v1/2/agenda_appointments/${holiday.id}`)
      .then((res) => {
        notification['success']({
          message: t('staffpage.holiday_delete_success_msg'),
        });
        viewStaff(selectedStaff);
        setLoading(false);
      })
      .catch((err) => {
        notification['error']({
          message: 'There was an error',
        });
        setLoading(false);
      });
  };

  const handleCreateStaff = (data) => {
    let formattedData = { ...data };
    formattedData.contacts = formattedData.phone_number;

    setLoading(true);
    dispatch(createStaff(formattedData))
      .unwrap()
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        setLoading(false);
        fetchStaffData();
        setStaff(null);
        setType(EMPTY_MODE);
      });
  };

  const fetchStaffHoliday = (staff = null) =>
    dispatch(
      fetchStaffHolidayList({
        id: staff === null ? selectedStaff.id : staff.id,
        from: moment().format('DD-MM-YYYY'),
        to: moment().add(1, 'year').format('DD-MM-YYYY'),
      }),
    );

  const viewStaff = (staff) => {
    setLoading(true);

    let eventId = getEventFromStorage();
    authRequest()
      .get(
        `/api/business/v1/events/${eventId}/staffs/${
          staff.id
        }?locale=${localStorage.getItem('lang')}`,
      )
      .then((res) => {
        const staffTransform = staffTransformerResource(res.data);
        setStaff(staffTransform);
        setLoading(false);
        setType(INITIAL_MODE);
        fetchStaffHoliday(staffTransform);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDeleteStaff = (staff) => {
    dispatch(removeStaff(staff));
    setStaff(null);
    setType(EMPTY_MODE);
    fetchStaffData();
    setLoading(false);
  };

  const handleStaffHolidayCreate = (data, cb = null) => {
    let eventId = getEventFromStorage();
    let { startDate, endDate, start_hour, start_minute, end_hour, end_minute } =
      data;
    startDate = moment(startDate).set({
      hour: start_hour,
      minute: start_minute,
    });
    endDate = moment(endDate).set({ hour: end_hour, minute: end_minute });

    setLoading(true);
    authRequest()
      .post(`/api/business/v1/${eventId}/agenda_appointments`, {
        agenda_type: 'holiday',
        staff_id: selectedStaff.id,
        start_date: startDate.format('DD-MM-YYYY HH:mm'),
        end_date: endDate.format('DD-MM-YYYY HH:mm'),
      })
      .then((res) => {
        logHandler.log(res);
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        typeof cb === 'function' && cb();
      })
      .catch((error) => {
        logHandler.log(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_CREATED);
        typeof cb === 'function' && cb();
      })
      .finally((err) => {
        fetchStaffHoliday();
        setLoading(false);
      });
  };

  const handleStaffHolidayUpdate = (data, cb = null) => {
    let eventId = getEventFromStorage();
    let { startDate, endDate, start_hour, start_minute, end_hour, end_minute } =
      data;
    startDate = moment(startDate).set({
      hour: start_hour,
      minute: start_minute,
    });
    endDate = moment(endDate).set({ hour: end_hour, minute: end_minute });

    setLoading(true);
    authRequest()
      .put(`/api/business/v1/${eventId}/agenda_appointments/${data.id}`, {
        agenda_type: 'holiday',
        staff_id: selectedStaff.id,
        start_date: startDate.format('DD-MM-YYYY HH:mm'),
        end_date: endDate.format('DD-MM-YYYY HH:mm'),
      })
      .then((res) => {
        logHandler.log(res);
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        typeof cb === 'function' && cb();
      })
      .catch((error) => {
        logHandler.log(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_CREATED);
        typeof cb === 'function' && cb();
      })
      .finally((err) => {
        fetchStaffHoliday();
        setLoading(false);
      });
  };

  const handleUpdateStaff = (values, cb) => {
    setLoading(true);
    let staffId = selectedStaff.id;
    dispatch(
      updateStaff({
        id: staffId,
        name: values.name,
        surname: values.surname,
        email: values.email,
        contacts: values.phone,
        times_attributes: values.time_attributes,
      }),
    )
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
        logHandler.log(res);
        viewStaff({ id: staffId });
        typeof cb === 'function' && cb();
      })
      .catch((error) => {
        logHandler.error(error);
        typeof cb === 'function' && cb();
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const staffClick = useCallback((item) => fetchStaffService(item), []);

  return (
    <DefaultPageLayout
      loadingSidebar={isSidebarLoading}
      loadingContent={loading}
      bodyClass={type === SERVICE_MODE && 'bg-light-yellow'}
    >
      <DefaultPageLayout.Title>
        <div className="page-title-ag">
          <span style={{ paddingRight: '12px' }}>
            <svg
              style={{ position: 'relative', top: '2px' }}
              width="24"
              height="24"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="3"
                width="12"
                height="16"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 3.07495H11.9175V18.9999H0V3.07495Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.48145 18.3072C1.8352 15.0459 2.87345 13.4375 4.95267 12.9294C5.28027 12.8494 5.51598 12.5479 5.5271 12.1944C5.53813 11.841 5.32203 11.5237 5.00008 11.4211C3.60115 10.975 2.66135 9.64182 2.66135 8.10377C2.66135 6.19239 4.14047 4.63731 5.95881 4.63731C7.77686 4.63731 9.25608 6.19239 9.25608 8.10377C9.25608 9.64182 8.31618 10.975 6.91725 11.4211C6.5953 11.5238 6.3792 11.841 6.39023 12.1944C6.40145 12.548 6.63706 12.8495 6.96476 12.9295C9.04418 13.4376 10.0823 15.046 10.4361 18.3072C10.4824 18.7358 10.8517 19.0441 11.2585 18.9947C11.6663 18.9459 11.9591 18.5587 11.9127 18.1301C11.6912 16.0884 11.1263 13.4209 8.91286 12.0663C10.0479 11.1364 10.7422 9.69577 10.7422 8.10377C10.7422 5.33085 8.59637 3.07495 5.95881 3.07495C3.32106 3.07495 1.1752 5.33085 1.1752 8.10377C1.1752 9.69567 1.86954 11.1364 3.00457 12.0662C0.791299 13.4209 0.226421 16.0884 0.00486032 18.13C-0.0416763 18.5587 0.251202 18.9459 0.659007 18.9947C0.687592 18.9982 0.715982 18.9999 0.74408 18.9999C1.11676 18.9999 1.43813 18.7057 1.48145 18.3072Z"
                  fill="#B19499"
                />
              </g>
              <mask
                id="mask1"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="9"
                y="0"
                width="10"
                height="16"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.06445 0.0385742H18.9953V15.9635H9.06445V0.0385742Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask1)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9156 9.02996C17.079 8.10001 17.7907 6.65939 17.7907 5.06739C17.7907 2.29457 15.5912 0.0385742 12.8877 0.0385742C11.5116 0.0385742 10.1906 0.637138 9.26319 1.68063C8.97979 1.9995 9.00219 2.49365 9.31309 2.78422C9.62389 3.07478 10.1055 3.05191 10.389 2.73314C11.0283 2.01365 11.9391 1.60093 12.8877 1.60093C14.7513 1.60093 16.2674 3.15601 16.2674 5.06739C16.2674 6.60545 15.304 7.93857 13.8701 8.38463C13.5402 8.48729 13.3186 8.80452 13.33 9.15806C13.3414 9.5115 13.5829 9.81304 13.9188 9.89314C16.0502 10.4012 17.1142 12.0095 17.4769 15.2708C17.5211 15.6693 17.8506 15.9635 18.2326 15.9635C18.2615 15.9635 18.2906 15.9618 18.3198 15.9584C18.7378 15.9095 19.038 15.5224 18.9903 15.0938C18.7634 13.052 18.1843 10.3846 15.9156 9.02996Z"
                  fill="#B19499"
                />
              </g>
            </svg>
          </span>
          <span className="px-1">{t('staffpage.navbar_title')}</span>
        </div>
      </DefaultPageLayout.Title>

      <DefaultPageLayout.Sidebar>
        <Row>
          <Col span={18}>
            <Input
              placeholder={t('staffpage.staff_search_placeholder')}
              size="large"
              suffix={<SearchOutlined style={{ fontSize: '20px' }} />}
              onChange={onSearch}
            />
          </Col>
          <Col span={6}>
            <Button
              size="large"
              onClick={() => setType(CREATE_MODE)}
              className="w-100 border-transparent button-ghost"
              ghost={true}
            >
              <PlusCircleFilled
                twoToneColor="#2776B3"
                style={{ fontSize: '28px' }}
              />
            </Button>
          </Col>
        </Row>
        <div className="mt-4"></div>

        <div className="list">
          <List
            dataSource={staffList}
            renderItem={(item) => (
              <List.Item
                key={`${item.name}-${item.id}`}
                className="staff-list appear-effect"
              >
                <List.Item.Meta
                  avatar={
                    <Avatar size="large" src={item.avatar || UserPlaceholder} />
                  }
                  title={
                    <p
                      className="my-0 text-capitalize"
                      onClick={(e) => viewStaff(item)}
                    >
                      {item.name}
                    </p>
                  }
                />
                <Button
                  ghost={true}
                  className="border-transparent"
                  onClick={(e) => staffClick(item)}
                >
                  <ScissorOutlined
                    twoToneColor="#2776B3"
                    style={{ fontSize: '20px' }}
                  />
                </Button>
              </List.Item>
            )}
          />
        </div>
      </DefaultPageLayout.Sidebar>

      <DefaultPageLayout.Content>
        {type === EMPTY_MODE && (
          <Row type="flex" align="center" className="w-100 vh-100 text-center">
            <Col span={24}>
              <div style={{ marginTop: 'calc(30vh - 85px)' }}>
                <Empty
                  description={
                    <Paragraph>{t('staffpage.empty_desc')}</Paragraph>
                  }
                />
              </div>
            </Col>
          </Row>
        )}

        {type === SERVICE_MODE && (
          <Row
            type="flex"
            align="center"
            className="w-100 vh-100 mt-2 text-center"
          >
            <Col offset={1} span={22}>
              <StaffService staff={selectedStaff} services={serviceList} />
            </Col>
          </Row>
        )}

        {type === CREATE_MODE && (
          <Row
            type="flex"
            align="center"
            className="w-100 vh-100 mt-2 text-center"
          >
            <Col offset={1} span={22}>
              <Card>
                <StaffCreateForm onSubmit={handleCreateStaff} />
              </Card>
            </Col>
          </Row>
        )}

        {type === INITIAL_MODE && (
          <Row
            type="flex"
            align="center"
            className="w-100 vh-100 mt-2 text-center"
          >
            <Col offset={1} span={22}>
              <StaffDetail
                onStaffHolidayDelete={deleteStaffHoliday}
                onDelete={handleDeleteStaff}
                onStaffHolidayCreate={handleStaffHolidayCreate}
                onStaffHolidayUpdate={handleStaffHolidayUpdate}
                staff={selectedStaff}
                holidays={holidays}
                onUpdate={handleUpdateStaff}
              />
            </Col>
          </Row>
        )}
      </DefaultPageLayout.Content>
    </DefaultPageLayout>
  );
}

export default StaffPage;
