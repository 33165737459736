import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION_CREATED, NOTIFICATION_DELETED, NOTIFICATION_UPDATED } from "../../app/constants";
import { updateError } from "../../components/util/Helper";
import { parseApiResponseAndSendNotification } from "../../handlers/ApiErrorNotifier";
import { participantTransformerCollection } from "../../transformers/participantTransformer";
import { createCustomerRequest, deleteCustomerRequest, fetchCustomerListRequest, fetchCustomerTimelineRequest, showCustomerRequest, updateCustomerRequest } from "./customerApi";

const initialState = {
    loading: false,
    fetchingData : [],
    error: {
      hasError: false,
      status: 0,
      data: null
    },
    customers: [],
    customer: null,
    customerTimeline: []
};
  


export const fetchCustomerList = createAsyncThunk(
    'customer/fetchCustomerList',
    async (keyword) => {
      const response = await fetchCustomerListRequest(keyword);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);

export const fetchCustomer = createAsyncThunk(
  'customer/fetchCustomer',
  async (payload) => {
    const response = await showCustomerRequest(payload.id);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  }
);

export const fetchCustomerTimeline = createAsyncThunk(
  'customer/fetchCustomerTimeline',
  async (payload) => {
    const response = await fetchCustomerTimelineRequest(payload.id);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  }
);


export const removeCustomer = createAsyncThunk(
    'customer/removeCustomer',
    async (payload) => {
      const response = await deleteCustomerRequest(payload.id);
      parseApiResponseAndSendNotification(response, NOTIFICATION_DELETED);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : {data: response.data, id: payload.id};
    }
);

export const updateCustomer = createAsyncThunk(
    'customer/updateCustomer',
    async (payload) => {
      const response = await updateCustomerRequest(payload);
      // The value we return becomes the `fulfilled` action payload
      parseApiResponseAndSendNotification(response, NOTIFICATION_UPDATED);
      return response.error ? response : response.data;
    }
);

export const createCustomer = createAsyncThunk(
    'customer/createCustomer',
    async (payload) => {
      const response = await createCustomerRequest(payload);
      // The value we return becomes the `fulfilled` action payload
      parseApiResponseAndSendNotification(response, NOTIFICATION_CREATED);
      return response.error ? response : response.data;
    }
);
  

export const CustomerSlice = createSlice({
    name: 'customer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        // Fetch all Customer
        .addCase(fetchCustomerList.pending, (state) => {
          state.fetchingData = ["fetchCustomerList", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchCustomerList.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchCustomerList");
          state.loading = !!state.fetchingData.length;

          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.customers = participantTransformerCollection(data.participants);
        })

        // Fetch all Customer Timeline
        .addCase(fetchCustomerTimeline.pending, (state) => {
          state.fetchingData = ["fetchCustomerTimeline", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchCustomerTimeline.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchCustomerTimeline");
          state.loading = !!state.fetchingData.length;

          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.customerTimeline = data.data.schedules;
        })

        .addCase(fetchCustomer.pending, (state) => {
          state.fetchingData = ["fetchCustomer", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchCustomer.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchCustomer");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.customer = data;
        })
        
        // Update Customer
        .addCase(updateCustomer.pending, (state) => {
          state.fetchingData = ["updateCustomer", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(updateCustomer.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "updateCustomer");
          state.loading = !!state.fetchingData.length;
          if(updateError(action.payload, state)){return;}
          const data = action.payload?.participant;
          if(state.customer?.id === data.id) {
            state.customer = { ...state.customer, ...data }
          }
          state.customers = state.customers.map(customer => data.id === customer.id ? data : customer);
        })

        // Remove Customer
        .addCase(removeCustomer.pending, (state) => {
          state.fetchingData = ["removeCustomer", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(removeCustomer.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "removeCustomer");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.customers = state.customers.filter(Customer => data.id !== Customer.id);
        })

        // Create Customer
        .addCase(createCustomer.pending, (state) => {
          state.fetchingData = ["createCustomer", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(createCustomer.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "createCustomer");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload;
          state.customers = [...state.customers, data.participant];
        });
    },
});

export const selectCustomerList = (state) => state.customer.customers;
export const selectCustomerProfile = (state) => state.customer.customer;
export const isCustomerLoading = (state) => !!state.customer.fetchingData.length;
export const selectCustomerLoading = (state) => state.customer.fetchingData;
export const selectCustomerProfileTimeline = (state) => state.customer.customerTimeline;


export default CustomerSlice.reducer;