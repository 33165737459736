import moment from "moment";
import { Draggable, Droppable } from "./../../../dnd/DragAndDrop";
import ErrorRenderHandler from "../../../handlers/ErrorRenderHandler";
import { useSchedulesProvider } from "../../../providers/ScheduleProvider";
import { useCalendarTheme } from "../../../theme";
import ScheduleCard from "../../schedule";
import { CustomWindowEvent, triggerEventListener } from "../../../../../services/event";

/**
 * Schedule layer of calendar
 * 
 * @param {Object} param0 
 * @returns {JSX}
 */
function ScheduleLayer({
    hour,
    column,
    currentDate,

    onCellClick,
}) {
    const {computedStyles, theme} = useCalendarTheme();
    const { schedules, scheduleClick, scheduleDblClick, dragAndDrop} = useSchedulesProvider();

    /**
     * Generate Schedule For The Cell
     * 
     * @param {array} schedules Schedules list
     * @param {integer} currentDate Start datetime of cell
     * @param {object} theme Theme for the schedule
     * @returns 
     */
    function generateSchedule(schedules, currentDate ,theme) {
        try{
            const schedule = schedules.filter(row => {
                let date = moment(row.start_date);
                let startDate = currentDate;
                let endDate = startDate.clone().add(14, 'minutes');
                return column.id === row.column_id && (date.isSame(startDate) || date.isBetween(startDate, endDate));
            });
            if(!schedule.length) {
                return (<></>);
            }
            const hour = currentDate.format('HH');
            if(!dragAndDrop) {
                return (
                    <>
                    {schedule.map((item,index) => (
                        <ScheduleCard 
                            schedule={item} 
                            theme={theme}
                            key={`weekly-${item.id}`}
                            clickedSchedule={scheduleClick} 
                            doubleClickedSchedule={scheduleDblClick} 
                            containerStyle={{zIndex: 2+hour}}
                        />
                    ))}
                    </>
                );
            }

            return (
                <>
                {schedule.map((row,index) => (
                    <ErrorRenderHandler item={row} index={index}>
                        <Draggable draggableId={`${row.id}`} key={`${row.id}`} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ScheduleCard
                                    schedule={row}
                                    theme={theme}
                                    clickedSchedule={scheduleClick}
                                    doubleClickedSchedule={scheduleDblClick} 
                                    containerStyle={{zIndex: 2+hour}} 
                                />
                            </div>
                        )}
                        </Draggable>
                    </ErrorRenderHandler>
                    
                ))}
                </>
            );
        }catch(error) {
            return (<></>);
        }
    }

    const handleDrop = (schedule, minute, prevSchedule) => {
        if(prevSchedule) {
            const date = moment(prevSchedule.start_date);
            const currentHour = date.format('HH');
            const currentMinute = date.format('mm');
            const isOnSameCell = parseInt(currentHour) === parseInt(hour)  && currentMinute === minute
            if(isOnSameCell) {return}
        }

        triggerEventListener(CustomWindowEvent.SCHEDULE_DROPPED, {
            draggableId: schedule.id,
            destination: {
                droppableId: `droppable-${column.id}-${currentDate}-${hour}-${minute}`
            }
        })
    }


    const isHoliday = (currentHour, minute) => {
        if(column.holidays === undefined || !column.holidays.length) return false;
        
        let date = moment(currentDate);
        return column.holidays.some(item => {
            date = date.set({hour: currentHour, minute: minute});
            let startDate = moment(item.start_date).subtract(1, 'minute');
            let endDate = moment(item.end_date).add(1, 'minute');
            return date.isBetween(startDate, endDate);
        });
    }

    const shouldRenderSchedule = schedules && !!schedules.length;

    const generateCell = (minute) => {
        if(!dragAndDrop) {
            return (
                <div 
                    style={computedStyles.cellStyle}
                    className={`${isHoliday(hour, minute) ? 'leave' : 'agenda-hover'} calendar-hour agenda-bg-transparent position-relative`}
                    onClick={(e) => onCellClick({column: column, date: currentDate, event: e, hour, minute: minute})}
                >
                    {shouldRenderSchedule && generateSchedule(schedules, moment(currentDate).set({hour:hour, minute: minute}), theme)}
                </div>
            )
        }

        return (
            <Droppable 
                onDrop={(item) => handleDrop(item, minute, schedules)}
                item={column} 
                type="ITEM" 
                isDropDisabled={false}
                // isDropDisabled={isHoliday(hour, 0)}
                className={`calendar-hour agenda-bg-transparent position-relative`}
                onClick={(e) => onCellClick({column: column, date: currentDate, event: e, hour, minute: minute})}
                style={computedStyles.cellStyle}
            >
                {shouldRenderSchedule && generateSchedule(schedules, moment(currentDate).set({hour:hour, minute: minute}), theme)}
            </Droppable>
        )
    }
    return (
        <div className="calendar-hours agenda-bg-transparent">
            {generateCell(0)}
            {generateCell(15)}
            {generateCell(30)}
            {generateCell(45)}
        </div>
    );
}

export default ScheduleLayer;