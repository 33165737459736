import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScheduleLayer from './ScheduleLayer';
import BackgroundLayer from './BackgroundLayer';
import { useCalendarTheme } from '../../../theme';
import { useSchedulesProvider } from '../../../providers/ScheduleProvider';

/**
 *
 * @param  {hours}  All hours list
 * @param  {column}  Column
 * @param  {onCellClick}  Column
 * @returns
 */
function CalendarBodyLayer({ hours, column, onCellClick }) {
  const [task, setTask] = useState([]);
  const { computedStyles, dateOption } = useCalendarTheme();
  const { schedules } = useSchedulesProvider();

  useEffect(() => {
    const filteredSchedules = schedules.filter((item) => {
      return (
        item.column_id === column.id &&
        dateOption.date.isSame(item.start_date, 'date')
      );
    });
    setTask(filteredSchedules);
  }, [column.id, dateOption, schedules]);

  return (
    <div
      className="calendar-column calendar-column-cell position-relative"
      style={computedStyles.cellContainerStyle}
    >
      <div className="calendar-hours-container position-relative">
        {hours.map((i) => (
          <BackgroundLayer
            key={`background-${dateOption.date.format('YYYY-MM-DD')}-${
              column.id
            }-${i}`}
          />
        ))}
      </div>
      <div className="agenda-container calendar-absolute-top-left">
        {hours.map((i) => (
          <ScheduleLayer
            key={`agenda-${dateOption.date.format('YYYY-MM-DD')}-${
              column.id
            }-${i}`}
            schedules={task}
            column={column}
            currentDate={dateOption.date.format('YYYY-MM-DD')}
            hour={i}
            onCellClick={onCellClick}
          />
        ))}
      </div>
    </div>
  );
}

CalendarBodyLayer.propTypes = {
  column: PropTypes.object,
  hours: PropTypes.array.isRequired,
  onCellClick: PropTypes.func,
};

export default CalendarBodyLayer;
