import { useCallback, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { rszDrg } from '../../card/resizeCard';

export function Draggable({ item, children }) {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'item',
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      position: monitor.getClientOffset(),
    }),
  }));

  const scrollWhenMoved = useCallback((event) => {
    if (Math.round(Math.random())) {
      return;
    }
    const container = document.getElementById('calendarscroller');

    // Get the container dimensions
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const boundingBox = container.getBoundingClientRect();

    // Get the mouse position relative to the container
    const mouseX =
      (event.clientX || event.touches[0].clientX) - boundingBox.left;
    const mouseY =
      (event.clientY || event.touches[0].clientY) - boundingBox.top;

    const isSmallScreen = window.innerWidth < 700;

    // Calculate the scroll amounts based on the container dimensions and threshold
    const scrollX = calculateScrollAmount(
      mouseX,
      containerWidth,
      isSmallScreen ? 0.3 : 0.2,
      isSmallScreen ? 0.6 : 0.8,
    );
    const scrollY = calculateScrollAmount(
      mouseY,
      containerHeight,
      isSmallScreen ? 0.3 : 0.2,
      isSmallScreen ? 0.6 : 0.8,
    );

    container.scrollBy({ left: scrollX, top: scrollY });

    const wmx = container.scrollWidth - boundingBox.width - 1;

    if (container.scrollLeft > wmx) container.scrollTo({ left: wmx });

    function calculateScrollAmount(
      mousePos,
      containerSize,
      minThreshold,
      maxThreshold,
    ) {
      // Calculate the scroll amount based on the mouse position and threshold
      if (mousePos < containerSize * minThreshold && mousePos > 0) return -20;

      if (mousePos > containerSize * maxThreshold && mousePos < containerSize)
        return 20;
      return 0;
    }
  }, []);

  const supportsTouch =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (collected.isDragging) {
      const calendarScroller = document.getElementById('calendarscroller');
      calendarScroller.addEventListener('dragover', scrollWhenMoved);
      calendarScroller.addEventListener('touchmove', scrollWhenMoved);

      return () => {
        calendarScroller.removeEventListener('dragover', scrollWhenMoved);
        calendarScroller.removeEventListener('touchmove', scrollWhenMoved);
      };
    }
  }, [collected.isDragging, scrollWhenMoved]);

  const rsz = rszDrg.ev.count || 0;

  return collected.isDragging && supportsTouch && !rsz ? (
    <div
      style={{
        top: 0,
        left: 0,
        width: 'inherit',
        zIndex: 999,
        position: 'fixed',
        transform: `translate(${collected.position?.x}px, ${collected.position?.y}px)`,
      }}
      ref={dragPreview}
    >
      {children}
    </div>
  ) : (
    <div ref={drag}>{children}</div>
  );
}

export function Droppable({
  children,
  isDropDisabled,
  item,
  onDrop,
  className,
  style,
  onClick,
  onContextMenu,
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'item',
    // if droping is not disabled then enable drag and drop
    canDrop: () => !isDropDisabled,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    drop(_item, monitor) {
      if (onDrop) onDrop(_item);
      return item;
    },
  }));

  return (
    <div
      onClick={onClick}
      onContextMenu={onContextMenu}
      className={className}
      style={{
        ...style,
        ...(!isDropDisabled && isOver ? { background: '#B19499' } : {}),
      }}
      ref={drop}
    >
      {children}
    </div>
  );
}
