export const transformRegisterRequest = (data) => ({
    "participant": {
        "name": data.name,
        "surname": data.surname,
        "email": data.email,
        "username": data.username,
        "password": data.password,
        "phone_number": data.phone_number,
        "custom_fields": [
            {"key": "phone_number","value": data.phone_number},
            {"key": "accept_terms_and_conditions","value": data.terms_and_conditions ?? false}
        ]
    }
});

export const transformLoginRequest = (data) => ({
    "participant": {
        "username": data.username,
        "password": data.password,
    }
});