import {
  deleteAuthRequest,
  getAuthRequest,
  postAuthRequest,
  putAuthRequest,
} from '../../services/api';
import { getEventFromStorage } from '../../services/token';

/**
 * Send request to get all staff service
 *
 * @returns {Promise}
 */
export const fetchAllStaffServiceRequest = (staffId) => {
  const eventId = getEventFromStorage();
  let url = `/api//business/v1/events/${eventId}/staffs/${staffId}/service_list`;
  return getAuthRequest(url);
};

/**
 * Send request to create a appointment
 *
 * @param {Object} data Appointment data to create new appointment
 * @returns {Promise}
 */
export const createNewScheduleRequest = (data) => {
  const eventId = getEventFromStorage();
  return postAuthRequest(`/api/business/v1/${eventId}/agenda_appointments`, {
    start_date: data.startDate,
    end_date: data.endDate,
    operator: data.operator,
    phone_number: data.phoneNumber,
    staff_id: data.staffId,
    participant_id: data.participantId,
    agenda_services_attributes: data.service,
    new_participant: data.new_participant,
    created_by: 0,
  });
};

/**
 * Send Update schedule request
 * @param {Object} data Data to update schedule
 * @returns {Promise}
 */
export const updateScheduleRequest = (data) => {
  const eventId = getEventFromStorage();

  const param = {
    start_date: data.startDate,
    end_date: data.endDate,
    operator: data.operator,
    staff_id: data.staffId,
    phone_number: data.phoneNumber,
    participant_id: data.participantId,
    updated_by: 0,
    agenda_services_attributes: data.service,
    new_participant: data.new_participant,
  };
  if (typeof data.appointment_status !== 'undefined')
    param.appointment_status = data.appointment_status;

  return putAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments/${data.id}`,
    param,
  );
};

/**
 * Send Update schedule request
 * @param {Object} data Data to update schedule
 * @returns {Promise}
 */
export const deleteScheduleRequest = (data) => {
  const eventId = getEventFromStorage();
  return deleteAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments/${data.id}`,
  );
};
