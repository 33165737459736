import React, { useEffect, useState } from 'react';
import Calendar from '../features/calendar/Calendar';
import ErrorRenderHandler from '../handlers/ErrorRenderHandler';
import {useHistory} from "react-router-dom"
import { useDispatch } from 'react-redux';
import { changeDate, changeToDailyView, changeToWeeklyView } from '../features/calendar/calendarSlice';
import moment from 'moment';


const parseUrlAndSetParam = (defaultData) => {
    let urlParams = window.location.search.substr(1);
    let searchParam = new URLSearchParams(urlParams.length ? urlParams : '');

    Object.keys(defaultData).forEach((item) => {
      if(defaultData[item] !== null) {
        if(searchParam.has(item)) {
          defaultData[item] = searchParam.get(item);
          searchParam.set(item, defaultData[item]);
        } else {
          searchParam.append(item, defaultData[item]);
        }
      }
    })
    return searchParam;
  }
  const updateRouteParams = (updateData, newUrl, history) => {
    Object.keys(updateData).forEach((item) => {
      if (!newUrl.has(item)) {
        newUrl.append(item, updateData[item]);
        return;
      }
  
      if (updateData[item] === null) {
        newUrl.delete(item);
        return;
      }
  
      newUrl.set(item, updateData[item]);
      return;
    });
    if (newUrl.toString() !== window.location.search.substr(1)) {
      history.push({
        pathname: window.location.pathname,
        search: `?${newUrl.toString()}`
      })
    }
  }
  
/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
export default function CalendarPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [initialized, setInitialized] = useState(false)
    useEffect(() => {
        const newUrl = parseUrlAndSetParam({
          "from": null,
          "view": "daily",
          "scheduleModal": null,
        });
    
        let startDate = moment(newUrl.has('from') ? newUrl.get('from') : null);
        if(startDate.format('YYYY-MM-DD') === 'Invalid date') {
            startDate = moment();
            newUrl.set('from', moment().format('YYYY-MM-DD'));
        }
        
        dispatch(changeDate(startDate.format('YYYY-MM-DD')));
        updateRouteParams({ view: newUrl.get('view') === 'daily' ? 'daily': 'weekly' }, parseUrlAndSetParam({}), history);
        dispatch( newUrl.get('view') === 'daily' ? changeToDailyView() :  changeToWeeklyView());

        updateRouteParams({}, newUrl, history);
        setInitialized(true);
    }, [dispatch, history]);

    return (
        <div className="homepage">
            <ErrorRenderHandler>
               {initialized && (
                   <Calendar />
               )}
            </ErrorRenderHandler>
        </div>
    );

}