import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  NOTIFICATION_CREATED,
  NOTIFICATION_DELETED,
  NOTIFICATION_UPDATED,
} from '../../app/constants';
import { updateError } from '../../components/util/Helper';
import { parseApiResponseAndSendNotification } from '../../handlers/ApiErrorNotifier';
import { serviceTransformerCollection } from '../../transformers/serviceTransformer';
import {
  fetchAllServiceRequest,
  fetchAllFeServiceRequest,
  createNewServiceRequest,
  deleteServiceRequest,
  updateServiceRequest,
  fetchBoServiceDetailRequest,
} from './serviceApi';

const initialState = {
  loading: false,
  fetchingData: [],
  error: {
    hasError: false,
    status: 0,
    data: null,
  },
  services: [], // List of all services
  service: null, // selected service
};

export const fetchFeServiceList = createAsyncThunk(
  'service/fetchServiceList',
  async (eventId) => {
    const response = await fetchAllFeServiceRequest(eventId);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const fetchServiceList = createAsyncThunk(
  'service/fetchServiceList',
  async (eventId) => {
    const response = await fetchAllServiceRequest(eventId);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const fetchServiceDetail = createAsyncThunk(
  'service/fetchServiceDetail',
  async (payload) => {
    const response = await fetchBoServiceDetailRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const removeService = createAsyncThunk(
  'service/removeService',
  async (payload) => {
    const response = await deleteServiceRequest(payload);
    parseApiResponseAndSendNotification(response, NOTIFICATION_DELETED);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : { data: response.data, id: payload.id };
  },
);

export const updateService = createAsyncThunk(
  'service/updateService',
  async (payload) => {
    const response = await updateServiceRequest(payload);
    // The value we return becomes the `fulfilled` action payload
    parseApiResponseAndSendNotification(response, NOTIFICATION_UPDATED);
    return response.error ? response : response.data;
  },
);

export const createService = createAsyncThunk(
  'service/createService',
  async (payload) => {
    const response = await createNewServiceRequest(payload);
    parseApiResponseAndSendNotification(response, NOTIFICATION_CREATED);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    removeSelectedService: (state) => {
      state.service = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceList.pending, (state) => {
        state.fetchingData = ['fetchServiceList', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(fetchServiceList.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchServiceList',
        );
        state.loading = !!state.fetchingData.length;

        if (updateError(action.payload, state)) {
          return;
        }
        const data = action.payload.services.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });

        state.services = serviceTransformerCollection(data);
      })

      .addCase(fetchServiceDetail.pending, (state) => {
        state.fetchingData = ['fetchServiceDetail', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(fetchServiceDetail.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchServiceDetail',
        );
        state.loading = !!state.fetchingData.length;

        state.service = action.payload;
      })

      .addCase(createService.pending, (state) => {
        state.fetchingData = ['createService', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'createService',
        );
        state.loading = !!state.fetchingData.length;
        let data = serviceTransformerCollection([action.payload]);
        state.services = [...state.services, ...data];
      })
      .addCase(updateService.pending, (state) => {
        state.fetchingData = ['updateService', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'updateService',
        );
        state.loading = !!state.fetchingData.length;
      })
      .addCase(removeService.pending, (state) => {
        state.fetchingData = ['removeService', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(removeService.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'removeService',
        );
        state.loading = !!state.fetchingData.length;
        let data = action.payload;
        if (data.id) {
          state.services = state.services.filter((item) => item.id !== data.id);
          state.service = state.staff?.id === action.id ? null : state.staff;
        }
      });
  },
});

export const { removeSelectedService } = serviceSlice.actions;

export default serviceSlice.reducer;
