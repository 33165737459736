import { Modal, Button, Typography, Input } from 'antd';
import PropTypes from 'prop-types'; // Added import
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function AddVoucherModal({
  open = false,
  title,
  content,
  closeModal,
  handleOK,
}) {
  const { t } = useTranslation();
  const [voucherCode, setVoucherCode] = useState('');

  const handleVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value);
  };

  return (
    <Modal
      title={title || t('frontend:addVoucherCode')}
      open={open}
      onCancel={closeModal}
      width={'400px'}
      okType={'default'}
      footer={[
        <Button key="back" className="round-button" onClick={closeModal}>
          {t('frontend:servicepage.cancel')}
        </Button>,

        <Button
          key="ok"
          className="modal-button"
          onClick={() => handleOK(voucherCode)}
        >
          Ok
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        {t('frontend:addVoucherMessage')}
      </Typography.Paragraph>
      <Input
        style={{
          width: '100%',
          padding: 12,
          border: '1px solid #ccc',
          borderRadius: 4,
          marginBottom: 16,
        }}
        placeholder={t('frontend:code')}
        value={voucherCode}
        onChange={handleVoucherCodeChange}
      />
    </Modal>
  );
}

AddVoucherModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  handleOK: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
};

export default AddVoucherModal;
