import { rszDrg } from '../../card/resizeCard';

const tchDrgDrp = {
  cell: ['', null],
  oldCell: ['', null],
  cellSize: { w: 80, h: 40 },
  calWrap: { el: null, l: 0, t: 0, scx: null, scy: null },
  isDropEnabled: false,
  scheduleId: 0,
  counter: 0,
  tchStartTime: 0,
  isMenuVisible: 0,
  countMove: 0,

  getDropTargetElementsAtPoint: function (x, y, dropTargets) {
    if (rszDrg.ev.count) return false;
    const T = tchDrgDrp;

    if (!T.calWrap.el) {
      let el = document.getElementsByClassName('calendar-container');
      if (!el.length) return false;
      T.calWrap.el = el[0];
      let p = T.leftTop(T.calWrap.el);
      T.calWrap.l = p.l;
      T.calWrap.t = p.t;

      T.calWrap.scx = document.getElementById('calendarscroller');
      T.calWrap.scy = document.getElementById('calendarscroller');
    }

    let tmp,
      sx = T.calWrap.scx.scrollLeft,
      sy = T.calWrap.scy.scrollTop,
      cx = Math.ceil((x - T.calWrap.l + sx) / T.cellSize.w),
      cy = Math.ceil((y - T.calWrap.t + sy) / T.cellSize.h);

    tmp = cx + '_' + cy;
    T.counter++;

    if (T.oldCell[0] !== tmp && T.counter < 5) return;
    else T.counter = 0;

    if (T.oldCell[0] !== tmp) {
      T.cell[0] = tmp;
      T.cell[1] = T.getCell(x, y);

      T.isDropEnabled = T.cell[1] ? true : false;

      if (T.isDropEnabled) T.cellColor(T.cell[1], '#B19499');

      //console.log("change:", T.cell[0], " old: ",T.oldCell[0], " | ", T.isDropEnabled )

      T.oldCell[0] = T.copyCell(T.cell[0]);
      if (T.oldCell[1]) {
        T.cellColor(T.oldCell[1], 'transparent');
        T.oldCell[1] = null;
      }

      T.oldCell[1] = T.copyCell(T.cell[1]);
    }
    return false;
  },

  cellColor: function (cells, col) {
    if (col !== 'transparent') {
      const el = cells[cells.length - 1];
      el.style.backgroundColor = col;
      return;
    }
    for (let r = 0; r < cells.length; r++) {
      if (cells[r].offsetHeight === 40) {
        cells[r].style.backgroundColor = col;
      }
    }
  },

  // 'agenda-hover'  'leave   'calendar-hour agenda-bg'
  getCell: function (x, y) {
    let r,
      el,
      res = [],
      els = document.elementsFromPoint(x, y) || [];
    for (r = 0; r < els.length; r++) {
      el = els[r];

      if (el) {
        // leave
        if (el.className.indexOf('leave') !== -1) res.push(el); // return null;
        // available
        if (el.className.indexOf('agenda-hover') !== -1) res.push(el);
      }
    }
    return res.length ? res : null;
  },

  // custom handle
  tchHandleDrop: function (params) {
    const T = tchDrgDrp;
    let schId = T.scheduleId;
    T.scheduleId = 0;

    // no drop
    if (!T.isDropEnabled || !T.cell[1] || !T.cell[1].length) return false;

    // clear old highlighted cell
    if (T.oldCell[1]) {
      T.cellColor(T.oldCell[1], 'transparent');
      T.oldCell[1] = null;
    }

    let cell = T.cell[1].pop(); // use last!!!
    let prm = JSON.parse(cell.dataset.droparam);

    //console.log("DROP:", T.isDropEnabled, T.cell[0], " cell1", T.cell[1].length)
    //console.log("prm", prm, "old", params);

    // check if same cell
    if (
      params.column.id === prm.column.id &&
      params.hour === prm.hour &&
      params.minute === prm.minute
    ) {
      return false;
    }

    // e.g. - schedule.id: 1990  | droppable-5-2023-06-29-9-30  => `droppable-${column.id}-${currentDate}-${hour}-${minute}`
    let str = `droppable-${prm.column.id}-${prm.date}-${prm.hour}-${prm.minute}`;

    T.isDropEnabled = false;

    return { draggableId: schId, droppableId: str };
  },

  // --------------------------------
  // generic functions

  copyCell: function (cell) {
    return cell ? cell.slice(0) : null;
  },

  leftTop: function (j) {
    let l = 0,
      t = 0;
    while (j.offsetParent) {
      l += j.offsetLeft;
      t += j.offsetTop;
      j = j.offsetParent;
    }
    l += j.offsetLeft;
    t += j.offsetTop;
    return { l, t };
  },
}; //

export { tchDrgDrp };
