import React, { useEffect, useState } from 'react';
import {
  List,
  Switch,
  Modal,
  Spin,
  Typography,
  Button,
  Row,
  Col,
  Empty,
} from 'antd';

import {
  DefaultPageLayout,
  BigClockIcon,
  AddIcon,
  ExtraordinaryTimeList,
} from '../components/index';
import {
  EMPTY_MODE,
  INITIAL_MODE,
  NOTIFICATION_CREATED,
  NOTIFICATION_DELETED,
  NOTIFICATION_UPDATED,
  SHOW_MODE,
} from '../app/constants';
import { authRequest } from '../services/api';
import { getEventFromStorage } from '../services/token';
import { logHandler } from '../handlers/LogHandler';
import { useTranslation } from 'react-i18next';
import WeeklyShopTimeForm from '../components/form/WeeklyShopTimeForm';
import { sortById } from '../components/util/Helper';
import ExtraordinaryOpeningClosingCalendar from '../components/form/ExtraordinaryOpeningClosingCalendar';
import { parseApiResponseAndSendNotification } from '../handlers/ApiErrorNotifier';
import moment from 'moment';

let eventId = getEventFromStorage();

function ShopTimePage() {
  const [loading, setLoading] = useState(false);
  const [weekDates, setWeekDates] = useState([]);
  const [pageState, setPageState] = useState(EMPTY_MODE);
  const [extraordinaryTimes, setExtraordinaryTimes] = useState({
    opening_times: [],
    closing_times: [],
  });

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [isWeeklyModalOpen, setIsWeeklyModalOpen] = useState(false);
  const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] =
    useState(false);
  const [extraordinaryType, setExtraordinaryType] = useState(null);
  const [pageType, setPageType] = useState('opening');

  const { t } = useTranslation();

  const fetchApiData = () => {
    setLoading(true);
    setIsSidebarLoading(true);
    let date = moment().format('DD-MM-YYYY');
    let eventId = getEventFromStorage();

    Promise.all([
      authRequest().get(
        `/api/business/v1/events/${eventId}/event_times?locale=${localStorage.getItem(
          'lang',
        )}`,
      ),
      authRequest().get(
        `/api/business/v1/events/${eventId}/extraordinary_times?from=${date}`,
      ),
    ])
      .then((data) => {
        logHandler.log(data);
        setPageState(INITIAL_MODE);
        let eventTime = data[0].data.event_times;
        setWeekDates(eventTime.sort(sortById));

        let extraordinaryTime = data[1].data.extraordinary_times;
        setExtraordinaryTimes(extraordinaryTime);

        setIsSidebarLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setIsSidebarLoading(false);
        setLoading(false);
        logHandler.error(error);
      });
  };

  useEffect(() => fetchApiData(), []);

  const EmptyState = (
    <div className="h-100 py-4">
      <div className="my-2"></div>
      <div className="text-center py-4">
        <img src={BigClockIcon} alt="Orari" />
        <p className="text-center mt-4 lead">
          {t('shoptimepage.empty_message')}
        </p>
      </div>
    </div>
  );

  const changeChecked = (checked, day) => {
    setIsModalLoading(true);
    setIsSidebarLoading(true);
    authRequest()
      .put(
        `/api/business/v1/events/${eventId}/event_times/${
          day.id
        }?locale=${localStorage.getItem('lang')}`,
        {
          closed: !checked,
        },
      )
      .then((res) => {
        let newData = res.data;
        let events = weekDates.map((date) => {
          return date.id === newData.id ? newData : date;
        });

        setWeekDates(events);
        setIsSidebarLoading(false);
        setIsModalLoading(false);
      })
      .catch((error) => {
        logHandler.error(error);
        setIsSidebarLoading(false);
        setIsModalLoading(false);
      });
  };

  const changeTime = (day) => setIsWeeklyModalOpen(true);

  const handleTimeChangeFromWeeklyModal = (data) => {
    return handleTimeChange(data);
  };

  /**
   * Change time for a day
   * @param {*} data  format: {start_hour, end_hour, start_minute, end_minute, day}
   */
  const handleTimeChange = (data) => {
    setIsModalLoading(true);

    authRequest()
      .put(
        `/api/business/v1/events/${eventId}/event_times/${
          data.day.id
        }?locale=${localStorage.getItem('lang')}`,
        {
          closed: data.day.closed,
          from: `${data.start_hour}:${data.start_minute}`,
          to: `${data.end_hour}:${data.end_minute}`,
          break_start: data.break_start,
          break_end: data.break_end,
        },
      )
      .then((res) => {
        setIsWeeklyModalOpen(false);
        parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
        fetchApiData();
      })
      .catch((err) => {
        logHandler.error(err.resonseJson);
        parseApiResponseAndSendNotification(err, NOTIFICATION_UPDATED);
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  /**
   * Create extraordinary opening and closing
   * @param {*} data
   */
  const handleExtraordinaryTimeCreate = (data) => {
    setIsModalLoading(true);
    let eventId = getEventFromStorage();
    authRequest()
      .post(
        `/api/business/v1/events/${eventId}/extraordinary_times?locale=${localStorage.getItem(
          'lang',
        )}`,
        {
          time_type: extraordinaryType,
          date_from: data.startDate,
          date_to: data.endDate,
          from: `${data.start_hour}:${data.start_minute}`,
          to: `${data.end_hour}:${data.end_minute}`,
          break_start: data.break_start,
          break_end: data.break_end,
        },
      )
      .then((res) => {
        logHandler.log(res);
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        fetchApiData();
        setIsExtraordinaryModalOpen(false);
      })
      .catch((err) => {
        parseApiResponseAndSendNotification(err.response, NOTIFICATION_CREATED);
        logHandler.error(err);
      })
      .finally((fin) => {
        setIsModalLoading(false);
      });
  };

  const openOpeningExtraordinaryModal = () => {
    setIsExtraordinaryModalOpen(true);
    setExtraordinaryType('opening');
  };

  const openClosingExtraordinaryModal = () => {
    setIsExtraordinaryModalOpen(true);
    setExtraordinaryType('closing');
  };

  const selectClosingTime = () => {
    setPageType('closing');
    setPageState(SHOW_MODE);
  };

  const selectOpeningTime = () => {
    setPageType('opening');
    setPageState(SHOW_MODE);
  };

  const handleTimeDelete = (data) => {
    setLoading(true);
    let eventId = getEventFromStorage();
    authRequest()
      .delete(
        `api/business/v1/events/${eventId}/extraordinary_times/${
          data.id
        }?locale=${localStorage.getItem('lang')}`,
      )
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_DELETED);
        let dataKey =
          data.time_type === 'opening' ? 'opening_times' : 'closing_times';
        let items = extraordinaryTimes[dataKey].filter(
          (item) => item.id !== data.id,
        );
        setExtraordinaryTimes({
          ...extraordinaryTimes,
          ...{ [dataKey]: items },
        });
      })
      .catch((err) => {
        logHandler.log(err);
        parseApiResponseAndSendNotification(err.response, NOTIFICATION_DELETED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DefaultPageLayout
      loadingContent={loading}
      loadingSidebar={isSidebarLoading}
    >
      <DefaultPageLayout.Title>
        <div className="page-title-ag">
          <span style={{ paddingRight: '12px' }}>
            <svg
              style={{ position: 'relative', top: '3px' }}
              width="24"
              height="24"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.48876 1.62558C4.692 1.62558 1.60315 4.71452 1.60315 8.5112C1.60315 12.3079 4.692 15.3968 8.48876 15.3968C12.2855 15.3968 15.3744 12.3079 15.3744 8.5112C15.3744 4.71452 12.2855 1.62558 8.48876 1.62558ZM8.48876 16C4.35941 16 1 12.6405 1 8.5112C1 4.38193 4.35941 1.02246 8.48876 1.02246C12.6181 1.02246 15.9775 4.38193 15.9775 8.5112C15.9775 12.6405 12.6181 16 8.48876 16Z"
                fill="#B19499"
              />
              <path
                d="M8.48876 1.37558C4.55393 1.37558 1.35315 4.57645 1.35315 8.5112H1.85315C1.85315 4.85259 4.83008 1.87558 8.48876 1.87558V1.37558ZM1.35315 8.5112C1.35315 12.446 4.55393 15.6468 8.48876 15.6468V15.1468C4.83008 15.1468 1.85315 12.1698 1.85315 8.5112H1.35315ZM8.48876 15.6468C12.4236 15.6468 15.6244 12.446 15.6244 8.5112H15.1244C15.1244 12.1698 12.1475 15.1468 8.48876 15.1468V15.6468ZM15.6244 8.5112C15.6244 4.57645 12.4236 1.37558 8.48876 1.37558V1.87558C12.1475 1.87558 15.1244 4.85259 15.1244 8.5112H15.6244ZM8.48876 15.75C4.49749 15.75 1.25 12.5024 1.25 8.5112H0.75C0.75 12.7786 4.22134 16.25 8.48876 16.25V15.75ZM1.25 8.5112C1.25 4.52 4.49748 1.27246 8.48876 1.27246V0.772461C4.22134 0.772461 0.75 4.24386 0.75 8.5112H1.25ZM8.48876 1.27246C12.48 1.27246 15.7275 4.52 15.7275 8.5112H16.2275C16.2275 4.24386 12.7562 0.772461 8.48876 0.772461V1.27246ZM15.7275 8.5112C15.7275 12.5024 12.48 15.75 8.48876 15.75V16.25C12.7562 16.25 16.2275 12.7786 16.2275 8.5112H15.7275Z"
                fill="#B19499"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4H9V2H8V4Z"
                fill="#B19499"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 9H4V8H2V9Z"
                fill="#B19499"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 9H15V8H13V9Z"
                fill="#B19499"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15H9V13H8V15Z"
                fill="#B19499"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8798 6.5345C12.0401 6.38309 12.0401 6.13757 11.8798 5.98616L11.874 5.98062C11.7137 5.82921 11.4539 5.82921 11.2936 5.98062L8.7906 8.34573C8.78829 8.34792 8.78678 8.35052 8.78455 8.35273C8.69034 8.32101 8.58934 8.30286 8.48361 8.30286C8.36461 8.30286 8.25128 8.32522 8.14736 8.36495L4.70635 5.11356C4.54612 4.96215 4.28628 4.96215 4.12604 5.11356L4.12018 5.11909C3.95994 5.27053 3.95994 5.51602 4.12018 5.66743L7.6084 8.96346C7.5939 9.02401 7.58554 9.08674 7.58554 9.15145C7.58554 9.62007 7.98762 10 8.48361 10C8.97962 10 9.38167 9.62007 9.38167 9.15145C9.38167 9.07187 9.36927 8.99517 9.34759 8.92215C9.35724 8.91453 9.36767 8.90821 9.37675 8.89963L11.8798 6.5345Z"
                fill="#B19499"
              />
            </svg>
          </span>
          <span className="text-uppercase">
            {t('shoptimepage.navbar_title')}
          </span>
        </div>
      </DefaultPageLayout.Title>

      <DefaultPageLayout.Sidebar>
        {pageState !== EMPTY_MODE && (
          <List
            className="shop-time-lists"
            dataSource={weekDates}
            renderItem={(dayData) => (
              <List.Item
                key={`day-${dayData.day}`}
                className="list-border-dark appear-effect"
              >
                <List.Item.Meta
                  title={
                    <p className="my-0 text-capitalize text-white">
                      {dayData.day}
                    </p>
                  }
                />
                <div>
                  <span
                    className="pr-2 text-primary-theme cursor-pointer"
                    onClick={() => changeTime(dayData)}
                  >
                    {dayData.closed
                      ? t('shoptimepage.closed')
                      : `${dayData.from} - ${dayData.to}`}
                  </span>
                  <Switch
                    checked={!dayData.closed}
                    onChange={(checked) => changeChecked(checked, dayData)}
                  />
                </div>
              </List.Item>
            )}
          />
        )}
        {/* Removed due to issue INGASU-105 */}
        {/* <div className="text-center mt-4">
                    <Button type='primary' onClick={() => setIsWeeklyModalOpen(true)} ghost={true}>{ t('shoptimepage.change_button') }</Button>
                </div> */}
        <div className="w-100 mt-2 pt-2"></div>

        <div
          className="shoptime-title cursor-pointer"
          onClick={openOpeningExtraordinaryModal}
        >
          <Typography.Text className="py-2 px-2">
            {t('shoptimepage.sidebar.extraordinary_opening_time_title')}
          </Typography.Text>

          <img src={AddIcon} alt="Add extraordinary time" />
        </div>
        {extraordinaryTimes?.opening_times && (
          <List
            className="shop-time-lists"
            dataSource={extraordinaryTimes.opening_times.slice(0, 5)}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span className="text-white">
                      {t('shoptimepage.nodata')}
                    </span>
                  }
                />
              ),
            }}
            renderItem={(day) => (
              <List.Item
                key={`day-opening-${day.id}--${day.from}-${day.to}`}
                className="list-border-dark appear-effect"
              >
                <List.Item.Meta
                  title={
                    <div className="d-flex flex-row justify-content-between">
                      <p className="my-0 text-capitalize text-white">
                        {moment(day.date).format('ddd DD MMM YYYY')}
                      </p>
                      <span className="text-muted text-primary-theme">
                        {day.from}-{day.to}
                      </span>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        )}
        {extraordinaryTimes?.opening_times && (
          <div className="text-center mt-1 mb-4">
            <Button
              className="secondary-button-outline-white"
              onClick={selectOpeningTime}
              ghost={true}
            >
              {t('shoptimepage.opening_time_show')}
            </Button>
          </div>
        )}

        <div className="w-100 mt-2 pt-2"></div>

        <div
          className="shoptime-title cursor-pointer"
          onClick={openClosingExtraordinaryModal}
        >
          <Typography.Text className="py-2 px-2">
            {t('shoptimepage.sidebar.extraordinary_closing_time_title')}
          </Typography.Text>
          <img src={AddIcon} alt="Add extraordinary time" />
        </div>

        {extraordinaryTimes?.closing_times && (
          <List
            className="shop-time-lists"
            dataSource={extraordinaryTimes.closing_times.slice(0, 5)}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span className="text-white">
                      {t('shoptimepage.nodata')}
                    </span>
                  }
                />
              ),
            }}
            renderItem={(day) => (
              <List.Item
                key={`day-closing-${day.id}-${day.from}-${day.to}`}
                className="list-border-dark appear-effect"
              >
                <List.Item.Meta
                  title={
                    <div className="d-flex flex-row justify-content-between">
                      <p className="my-0 text-capitalize text-white">
                        {moment(day.date).format('ddd DD MMM YYYY')}
                      </p>
                      <span className="text-muted text-primary-theme">
                        {day.from}-{day.to}
                      </span>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        )}

        {extraordinaryTimes?.closing_times && (
          <div className="text-center mt-1 mb-4">
            <Button
              className="secondary-button-outline-white"
              onClick={selectClosingTime}
              ghost={true}
            >
              {t('shoptimepage.closing_time_show')}
            </Button>
          </div>
        )}
      </DefaultPageLayout.Sidebar>

      <DefaultPageLayout.Content className="bg-light-yellow">
        {EMPTY_MODE === pageState && EmptyState}
        <div className="my-4 w-100"></div>
        <Row>
          <Col span={22} offset={1}>
            {SHOW_MODE === pageState && (
              <ExtraordinaryTimeList
                title={
                  pageType === 'opening'
                    ? t('shoptimepage.opening_title')
                    : t('shoptimepage.closing_title')
                }
                allTime={
                  pageType === 'opening'
                    ? extraordinaryTimes.opening_times
                    : extraordinaryTimes.closing_times
                }
                onDelete={handleTimeDelete}
              />
            )}
          </Col>
        </Row>

        {isExtraordinaryModalOpen && (
          <Modal
            className="bg-light-yellow pb-0"
            open={isExtraordinaryModalOpen}
            onCancel={() => setIsExtraordinaryModalOpen(false)}
            closable={true}
            footer={null}
            maskClosable={true}
            width={'800px'}
            centered
          >
            <Spin spinning={isModalLoading} delay={200}>
              <ExtraordinaryOpeningClosingCalendar
                onSubmit={handleExtraordinaryTimeCreate}
              />
            </Spin>
          </Modal>
        )}

        <Modal
          className="bg-light-yellow pb-0"
          open={isWeeklyModalOpen}
          onCancel={() => setIsWeeklyModalOpen(false)}
          closable={true}
          footer={null}
          centered
          maskClosable={true}
          width={'800px'}
        >
          <Spin spinning={isModalLoading} delay={200}>
            <WeeklyShopTimeForm
              onSubmit={handleTimeChangeFromWeeklyModal}
              weekData={weekDates}
              changeChecked={changeChecked}
            />
          </Spin>
        </Modal>
      </DefaultPageLayout.Content>
    </DefaultPageLayout>
  );
}

export default ShopTimePage;
