import { Typography, Button } from 'antd';
import React, { Fragment } from 'react';
import { ServiceStaffSelect } from '../../../components';
import './ServiceList.less';
import ServicePlaceholderIcon from '../../../components/icons/ServicePlaceholderIcon.png';
import { t } from 'i18next';

const ServiceList = ({
  searchServiceList,
  selectedService,
  toggleServiceSelection,
  selectedStaff,
  setSelectedStaff,
  getOptions,
}) => {
  const preventBubble = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
  };

  return (
    <>
      {searchServiceList &&
        searchServiceList.map((item) => (
          <Fragment key={`service-${item.id}`}>
            <div
              className={`classCard ${
                selectedService.includes(item.id) && 'selected-service'
              }`}
            >
              <div
                className="classCardImg"
                style={{
                  backgroundImage: `url(${
                    item.image_url || ServicePlaceholderIcon
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  width: '140px',
                  float: 'left',
                  marginRight: '10px',
                }}
              ></div>

              <div className="service-list">
                <Typography.Title className="noselect title">
                  {item.title}
                </Typography.Title>
                <Typography.Text className="noselect duration">
                  {item.readableDuration}
                </Typography.Text>

                <div className="operator">
                  {selectedService.includes(item.id) && (
                    <div onClick={preventBubble}>
                      <ServiceStaffSelect
                        serviceId={item.id}
                        defaultValue={selectedStaff[item.id] || 0}
                        onSelect={(service) =>
                          setSelectedStaff({
                            ...selectedStaff,
                            [item.id]: service,
                          })
                        }
                        getOptions={getOptions}
                      />
                    </div>
                  )}
                </div>

                <Typography.Paragraph className="noselect description">
                  {item.description}
                </Typography.Paragraph>

                <Typography.Text className="noselect price">
                  {item.price}€
                </Typography.Text>
              </div>

              <Button
                onClick={() => toggleServiceSelection(item)}
                size="large"
                shape="round"
                className="buttonBook"
              >
                {selectedService.includes(item.id)
                  ? `${t('frontend:servicepage.cancel')}`
                  : `${t('frontend:servicepage.book')}`}
              </Button>
            </div>
          </Fragment>
        ))}
    </>
  );
};

export default ServiceList;
