import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, PageNotFoundIcon } from "../components/index";
/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
export default function PageNotFound() {

    const { t } = useTranslation();

    return (
        <div className="main-page vh-100 d-flex flex-column">
            <Header title={ t("error:page.not_found_page_name") } />

            <Row type="flex" align="center" className="w-100 h-100 text-center">
                <Col span={24}>
                    <div style={{ marginTop: 'calc(25vh - 85px)' }}>
                        <Space direction="vertical" size="large">
                            <img src={PageNotFoundIcon} alt="Page Not Found" />
                            <Typography.Title level={5}>{ t("error:page.not_found_title") }</Typography.Title>
                        </Space>
                    </div>
                </Col>
            </Row>
            
        </div>
    );

}