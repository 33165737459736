import {
  deleteAuthRequest,
  getAuthRequest,
  postAuthRequest,
} from '../../services/api';
import { getEventFromStorage } from '../../services/token';

export function fetchCustomerListRequest(keyword = '') {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/participants/search?query=${keyword}&locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

export function showCustomerRequest(id) {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/participants/${id}?locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

export function fetchCustomerTimelineRequest(id) {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/${eventId}/agenda_appointments?participant=${id}&locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

export function createCustomerRequest(data) {
  const eventId = getEventFromStorage();
  return postAuthRequest(
    `/api/business/v1/attendees/register/${eventId}?locale=${localStorage.getItem(
      'lang',
    )}`,
    {
      participant: {
        name: data.name,
        surname: data.surname,
        username: data.username,
        password: data.password,
        email: data.email,
        phone_number: data.phone_number,
        custom_fields: [
          { key: 'address', value: data.address },
          { key: 'postal_code', value: data.postal_code },
          { key: 'date_of_birth', value: data.date_of_birth },
          { key: 'phone_number', value: data.phone_number },
          { key: 'notes', value: data.notes || '' },
          {
            key: 'accept_terms_and_conditions',
            value: data.terms_and_conditions ?? false,
          },
        ],
      },
    },
  );
}

export function deleteCustomerRequest(id) {
  const eventId = getEventFromStorage();
  return deleteAuthRequest(
    `/api/business/v1/events/${eventId}/participants/${id}?locale=it`,
  );
}

export function updateCustomerRequest(data) {
  const eventId = getEventFromStorage();
  return postAuthRequest(
    `/api/business/v1/attendees/update/${eventId}?locale=${localStorage.getItem(
      'lang',
    )}`,
    data,
  );
}
