import React from 'react';
import { logHandler } from '../../../handlers/LogHandler';

/**
 * Error handler most commonly used to request after certain time
 * 
 * @returns {ErrorRenderHandler}
 */
class ErrorRenderHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      logHandler.error(this.props, error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div style={{position: 'relative', zIndex: 5}}>
              <p>Something went wrong. Sorry we couldnot figure out the issue</p>
              <button className="btn btn-primary" type="primary" onClick={(e) => this.setState({hasError: false})}>
                Reload
              </button>
          </div>
        );
      }
  
      return this.props.children; 
    }
}


export default ErrorRenderHandler;