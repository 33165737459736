// Timeline 
import { Card, Timeline } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { logHandler } from '../../handlers/LogHandler';
import "./NotificationTimeline.less";

function NotificationTimeline({ timeline }) {
   
    const sortByDateDesc = (data) => {
        try{
            return data.sort((a,b) => {
                return (+(new Date(b.publication_date * 1000))) - (+new Date(a.publication_date * 1000));
            });
        }catch(error) {
            logHandler.error(error);
            return data;
        }
    }

    return (
        <div className="notification-timeline">
            {!!timeline.length && (
                <Timeline>
                    {sortByDateDesc(timeline).map(item => (
                        <Timeline.Item className="d-flex detail-wrapper" key={`timeline-${item.id}-${item.publication_date}`}>
                            <div className="time">
                                <p className="text">{moment(item.publication_date * 1000).format('dddd')}</p>
                                <p className="text my-1">{moment(item.publication_date * 1000).format('DD/MM/YYYY')}</p>
                                <p className="text my-1">{moment(item.publication_date * 1000).format('HH:mm')}</p>
                            </div>

                            <Card className="detail ml-4 p-2" aria-label="notification message">
                               {item.message}
                            </Card>
                            
                        </Timeline.Item>
                    ))}
                </Timeline>
            )}
        </div>
    );  
}

NotificationTimeline.propTypes = {
    timeline: PropTypes.arrayOf(PropTypes.object)
}


export default NotificationTimeline;