import './ContextMenu.less';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteSchedule } from '../../../../features/schedule/scheduleSlice';
import { parseApiResponseAndSendNotification } from '../../../../handlers/ApiErrorNotifier';
import { logHandler } from '../../../../handlers/LogHandler';
import { NOTIFICATION_DELETED } from '../../../../app/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomWindowEvent,
  triggerEventListenerRefresh,
} from '../../../../services/event';
import { selectSchedules } from '../../../../features/calendar/calendarSlice';

function ContextMenu({ left, top, closeMenu, schedule }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schedules = useSelector(selectSchedules);

  let osoa = localStorage.getItem('one_service_on_appointment') || '';

  let menuList = [
    {
      id: 1,
      title: t('calendar.card.title_copy'),
      subtitle: t('calendar.card.subtitle_copy'),
    },
    {
      id: 2,
      title: t('calendar.card.title_copyall'),
      subtitle: t('calendar.card.subtitle_deleteall'),
    },
  ];

  if (osoa)
    menuList = [
      ...menuList,
      {
        id: 3,
        title: t('calendar.card.title_deleteone'),
        subtitle: t('calendar.card.subtitle_deleteone'),
      },
      {
        id: 4,
        title: t('calendar.card.title_deleteall'),
        subtitle: t('calendar.card.subtitle_deleteall'),
      },
    ];

  // click on menu context
  function itemAction(e, id) {
    e.stopPropagation();
    e.target.parentNode.style.backgroundColor = '#b0b0b0';

    // 1 = copy
    if (id === 1) {
      localStorage.setItem('copy_type', '');
      localStorage.setItem('copy_schedule', JSON.stringify(schedule));
    }

    // 2 = copy all of client
    if (id === 2) {
      const items = schedules.filter(
        (item) => item.participant.id === schedule.participant.id,
      );

      let minDate = items[0].start_date;
      items.forEach((item) => {
        if (item.start_date < minDate) minDate = item.start_date;
      });

      // type multiple store date of day with min hour:min
      localStorage.setItem('copy_type', minDate);
      localStorage.setItem('copy_schedule', JSON.stringify(items || []));
    }

    // 3 = delete selected
    if (id === 3) {
      Modal.confirm({
        title: t('calendar.card.title_confirm'),
        content: (
          <div>
            <p>{t('calendar.card.delete_confirm')}</p>
          </div>
        ),
        onOk() {
          removeSchedule(schedule.id, schedule.start_date);
        },
      });
    }

    // 4 = delete all of client
    if (id === 4) {
      Modal.confirm({
        title: t('calendar.card.title_confirm'),
        content: (
          <div>
            <p>{t('calendar.card.delete_confirm_all')}</p>
          </div>
        ),
        onOk() {
          removeAllSchedule(schedule);
        },
      });
    }

    setTimeout(() => {
      closeMenu(0);
    }, 350);
  }

  const removeAllSchedule = async (schedule) => {
    // search participant.id
    const participantId = schedule.participant.id;

    schedules.forEach(async (item) => {
      if (item.participant.id === participantId)
        await removeSchedule(item.id, null);
    });

    refreshPage(schedule.start_date);
  };

  // delete single appointment and refresh if start_date
  const removeSchedule = async (id, start_date) => {
    await dispatch(deleteSchedule(id, start_date))
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_DELETED);
      })
      .catch((error) => {
        logHandler.error(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_DELETED);
      });

    if (start_date) refreshPage(start_date);
  };

  function refreshPage(date) {
    date = date ? date.substring(0, 10) : '';
    triggerEventListenerRefresh(CustomWindowEvent.SCHEDULE_REFRESH, date);
  }

  return (
    <div
      className="cl_boxMenu"
      style={{
        left: left + 8 + 'px',
        top: top + 'px',
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <table style={{ width: '100%' }}>
        <tbody>
          {menuList.map((item) => {
            return (
              <tr
                key={item.id}
                onClick={(e) => {
                  itemAction(e, item.id);
                }}
              >
                <td className="cl_menuRow">
                  <div className="cl_rowTitle">{item.title}</div>
                  <div className="cl_rowSubtitle">{item.subtitle}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ContextMenu;
