import { Modal, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function RemoveVoucherModal({
  open = false,
  title,
  content,
  closeModal,
  handleOK,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={title || t('frontend:removeVoucherCode')}
      open={open}
      onCancel={closeModal}
      width={'400px'}
      okType={'default'}
      footer={[
        <Button key="back" className="round-button" onClick={closeModal}>
          {t('frontend:servicepage.cancel')}
        </Button>,

        <Button key="ok" className="modal-button" onClick={handleOK}>
          Ok
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        {content || t('frontend:removeVoucherMessage')}
      </Typography.Paragraph>
    </Modal>
  );
}

RemoveVoucherModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  handleOK: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
};

export default RemoveVoucherModal;
