import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row, Select, Space, Spin, Typography } from "antd";
import { range } from '../util/Helper';
import { useEffect } from 'react';
const { Option } = Select;

function TimePauseForm({ loading, title, initialValue, onSubmit}) {
    const { t } = useTranslation();
    const [ form ] = Form.useForm();

    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(initialValue);
    }, [initialValue, form])
    

    return (
        <Spin spinning={loading} delay={300}>

            <Typography.Paragraph className="time-modal-title text-center">{title}</Typography.Paragraph>
            
            <div className="py-2"></div>

            <Form {...layout} form={form} name="control-ref" initialValues={initialValue} onFinish={onSubmit}>
                <Row>
                    <Col className="my-2" offset={1} span={11}>
                        <Space size="large">
                            <Form.Item className="h-100 m-0" name="startHour" label="Dalle" rules={[{ required: true }]}>
                                <Select showSearch data-testid="start_hour" style={{ minWidth: 100 }}>
                                    {range(0,23).map(hour => (
                                        <Option key={`start-${hour}`} value={hour}>{hour}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            :
                            <Form.Item className="h-100 m-0" name="startMinute" rules={[{ required: true }]}>
                                <Select showSearch data-testid="start_minute" style={{ minWidth: 100 }}>
                                    <Option value={0}>{0}</Option>
                                    <Option value={15}>{15}</Option>
                                    <Option value={30}>{30}</Option>
                                    <Option value={45}>{45}</Option>
                                </Select>
                            </Form.Item>
                        </Space>
                    </Col>

                    <Col className="my-2" span={11}>
                        <Space size="large">
                            <Form.Item className="h-100 m-0" name={"endHour"} label="ALLE" rules={[{required: true}]}>
                                <Select showSearch data-testid="end_hour" style={{ minWidth: 100 }}>
                                    {range(0,23).map(hour => (
                                        <Option key={`end-${hour}`} value={hour}>{hour}</Option>
                                    ))}
                                </Select>
                            </Form.Item> : 
                            <Form.Item className="h-100 m-0" name="endMinute" rules={[{required: true}]}>
                                <Select showSearch data-testid="end_minute" style={{ minWidth: 100 }}>
                                    <Option value={0}>{0}</Option>
                                    <Option value={15}>{15}</Option>
                                    <Option value={30}>{30}</Option>
                                    <Option value={45}>{45}</Option>
                                </Select>
                            </Form.Item>

                        </Space>
                    </Col>
                    
                    <Col span={24} className="py-4" />

                    <Col className="my-2 text-center" offset={1} span={22}>
                        <Form.Item wrapperCol={{ offset: 0}}>
                            <Button htmlType="submit" size="large" className="rounded-0 primary-button text-light font-weight-bold px-4">{ t('common:button.save') }</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
}

TimePauseForm.propTypes = {
    loading: PropTypes.bool, 
    title: PropTypes.string, 
    initialValue: PropTypes.object, 
    onSubmit: PropTypes.func
}


export default TimePauseForm;