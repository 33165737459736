import { getAuthRequest } from "../../services/api";
import { getEventFromStorage } from "../../services/token";


export function fetchUnreadNotificationCountRequest() {
    const eventId = getEventFromStorage();
    return getAuthRequest(`/api/business/v1/events/${eventId}/notifications/count`);
}


export function fetchNotificationRequest(page = 1) {
    const eventId = getEventFromStorage();
    return getAuthRequest(`/api/business/v1/events/${eventId}/notifications?page=${page}`);
}