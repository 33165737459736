import './TableServiceCard.less';
import { getHourMinFromSec, cloneDeep } from '../util/Generic';
import { TrashIcon } from '..';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import CancelModal from '../../components/modal/CancelModal';

const TableServiceCard = (props) => {
  const { t } = useTranslation();

  const [cancModal, setCancModal] = useState(null);

  let data = props.data?.services || [];

  const isCancellable = (props.data?.days || 0) < 0 ? false : true;

  if (isCancellable) {
    data = setRowspan(cloneDeep(data));
  }

  function setRowspan(data) {
    let r,
      n,
      ida = 0;
    for (r = 0; r < data.length; r++) {
      if (!r) {
        ida = data[0].appointmentId;
        n = checkNextRowspan(ida, 0);
      } else {
        // if as previous
        if (data[r].appointmentId === ida) {
          n = 0;
        } else {
          // new appointment id
          ida = data[r].appointmentId;
          n = checkNextRowspan(ida, r);
        }
      }
      data[r].rowspan = n;
    }
    return data;
  }

  function checkNextRowspan(ida, r) {
    let i,
      n = 1;
    for (i = r + 1; i < data.length; i++) {
      if (data[i].appointmentId === ida) n++;
    }
    return n;
  }

  const handleCancOk = () => {
    if (!cancModal) return false;
    props.onCancelAppointment(cancModal); // appointmentId
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setCancModal(null);
  };

  return (
    <>
      <table className="tableCard">
        <thead>
          <tr>
            <th style={{ textAlign: 'left', paddingLeft: '7px' }}>
              {t('frontend:reservationpage.service')}{' '}
              <span style={{ opacity: '0.7' }}>
                / {t('frontend:reservationpage.operator')}
              </span>
            </th>
            <th>{t('frontend:reservationpage.duration')}</th>
            {isCancellable && <th>{t('frontend:reservationpage.cancel')}</th>}
            <th>{t('frontend:reservationpage.amount')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: 'left' }}>
                  {item.name}
                  <span style={{ opacity: '0.7' }}>
                    &nbsp;/{' '}
                    {item.operator || t('frontend:reservationpage.anyone')}
                  </span>
                </td>
                <td>{getHourMinFromSec(item.time)}</td>
                {isCancellable && item.rowspan > 0 && (
                  <td rowSpan={item.rowspan || 1}>
                    <Button
                      ghost={true}
                      size="small"
                      className="h-100 border-0"
                      onClick={() => setCancModal(item.appointmentId)}
                    >
                      <img
                        src={TrashIcon}
                        alt={t('frontend:reservationpage.delete_one')}
                      />
                    </Button>
                  </td>
                )}
                <td>€ {item.amount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!!cancModal && (
        <CancelModal
          open={cancModal ? true : false}
          title={t('frontend:reservationpage.warning')}
          content={t('frontend:reservationpage.confirm_delete_single')}
          handleOK={handleCancOk}
          closeModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default TableServiceCard;
