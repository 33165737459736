import { useCallback, useEffect, useRef, useState } from "react"
import { range } from "../../util/Helper"
import { CalendarThemeProvider } from "../theme"
import { DefaultOpeningHours } from "../theme/data"
import WeeklyCalendarBody from "./body"
import WeeklyCalendarHeader from "./header"
import PropTypes from 'prop-types'
import moment from "moment"
import "./WeeklyCalendar.less"
import { ScheduleProvider } from "../providers/ScheduleProvider"
import React from "react"

/**
 * Weekly Calendar is for managing weekly agenda
 * 
 * @param {Object} theme  Theme of the calendar
 * @param {Object} dateOption Opening time and other options
 * @param {Object} columns  List of columns
 * @param {Object} template  List of template options
 * @param {Object} schedules  List of schedule
 * @param {Object} onCellClick  Fired when cell is clicked
 * @param {Object} onColumnClick  Fired when column is clicked
 * @param {Object} onScheduleClick  Fired when schedule is clicked
 * @param {Object} onScheduleDoubleClick  Fired when double clicked on schedule
 * @returns {JSX}
 */
function WeeklyCalendar({ 
    onScheduleClick,
    onScheduleDblClick,

    //
    onCellClick,
    
    // Styles
    height = null,
    
    // Template & theme
    theme,
    template,
    
    // Data provided
    schedules,
    dateOption,
    columns,
    dragAndDrop = false
}) {
  const [openingHours, setOpeningHours] = useState(DefaultOpeningHours)
  const [allHours, setAllHours] = useState([])
  const [allDays, setAllDays] = useState([])
  const columnRef = useRef(null)
  const hourRef = useRef(null)


  /**
   * Synchoronize horizontal and vertical scroll in calendar
   * 
   * @param {Object} e Event
   */
  const synchronizeScroll = (e) => {
    if(columnRef) columnRef.current.scrollLeft = e.target.scrollLeft;
    if(hourRef) hourRef.current.scrollTop = e.target.scrollTop;
  }


  /**
   * Initialize scrolling event listener
   * @returns {function}
   */
  const initializeScrollListener = () => {
    let calendarScroller = null;
    
    setTimeout(() => {
      calendarScroller = document.getElementById('calendarscroller');
      calendarScroller && calendarScroller.addEventListener('scroll', synchronizeScroll);
    }, 1000);

    return () => {
        calendarScroller && calendarScroller.removeEventListener('scroll', synchronizeScroll, true);
    };
  }

  const handleCellClick = useCallback((cellData) => {
      if(typeof onCellClick === 'function') {
        cellData.start_date = dateOption.date.clone().set({ hour: cellData.hour, minute: cellData.minute});
        cellData.end_date = cellData.start_date.clone().add(15,'minutes');
        onCellClick(cellData);
      }
  },[dateOption.date, onCellClick])

  useEffect(initializeScrollListener,[])

  useEffect(() => {
    const option = {...openingHours, ...dateOption};
    (JSON.stringify(openingHours) !== JSON.stringify(option)) && setOpeningHours(option);
    const newHours = range(option.start, option.end);
    (JSON.stringify(newHours) !== JSON.stringify(allHours)) && setAllHours(newHours);
    const newDays = [0,1,2,3,4,5,6].map(days => moment(dateOption.date).add(days, 'days').format('YYYY-MM-DD'));
    (JSON.stringify(newDays) !== JSON.stringify(allDays)) && setAllDays(newDays);
  }, [allDays, allHours, dateOption, openingHours])

    
  return (
    <CalendarThemeProvider theme={theme} weekly={true} dateOption={dateOption} templates={template} height={height || 600}>
    <ScheduleProvider 
      schedules={schedules}
      scheduleClick={onScheduleClick}
      scheduleDblClick={onScheduleDblClick}
      dragAndDrop={dragAndDrop}
    >
    <div className="weekly-calendar position-relative overflow-hidden" style={{height: (height || 600 )}}>
        {/* Header */}
        <WeeklyCalendarHeader
            columns={columns}
            allDays={allDays}
            columnRef={columnRef}

        />
        {/* Header End */}

        {/* Body Start */}
        <WeeklyCalendarBody
            allDays={allDays}
            hourRef={hourRef}
            columns={columns}
            
            hours={allHours}
            onCellClick={handleCellClick}
        />
        {/* Body End */}

    </div>
    </ScheduleProvider>
    </CalendarThemeProvider>
  );
}

WeeklyCalendar.propTypes = {
  dateOption: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired, 
  height: PropTypes.number,
  schedules: PropTypes.array,
  onCellClick: PropTypes.func,
  template: PropTypes.object,
  onScheduleClick: PropTypes.func,
  onScheduleDoubleClick: PropTypes.func
}


export default React.memo(WeeklyCalendar);