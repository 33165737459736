import {
  DatePicker,
  Modal,
  Col,
  Row,
  Space,
  Button,
  Checkbox,
  Badge,
  Card,
} from 'antd';
import { useState, useRef, useCallback, useEffect } from 'react';

import { DefaultPageLayout } from './../components/index';
import { useTranslation } from 'react-i18next';
import {
  LineChartOutlined,
  PlusOutlined,
  RollbackOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  IssuesCloseOutlined,
  DollarOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { getEventFromStorage } from '../services/token';
import { reportRequest } from '../services/api';
import '../styles/report_card.less';

import { fetchStaffList, selectStaffList } from '../features/staff/staffSlice';
import { useDispatch, useSelector } from 'react-redux';

function ReportPage() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkFilter, setCheckFilter] = useState([
    'new_clients',
    'return_frequencies',
    'lost_customers',
  ]);
  const [repData, setRepData] = useState(null);
  const [apiError, setApiError] = useState(false);
  const rangeDates = useRef({ from: '', to: '' });

  const { t } = useTranslation();

  let eventId = getEventFromStorage();

  // staff
  const dispatch = useDispatch();
  const allStaff = useSelector(selectStaffList);

  const fetchApiData = useCallback(() => {
    if (!allStaff.length) {
      dispatch(fetchStaffList());
    }
  }, [allStaff, dispatch]);

  useEffect(() => fetchApiData(), [fetchApiData]);

  const getStaffById = () => {
    let r,
      staffIds = {};

    for (r = 0; r < allStaff.length; r++) {
      staffIds[allStaff[r].id] = allStaff[r].name + ' ' + allStaff[r].surname;
    }
    return staffIds;
  };

  // report api - POST /api/business/v1/report/{{event_id}}
  const fetchApiReport = () => {
    setApiError(false);
    let dateFrom = rangeDates.current.from,
      dateTo = rangeDates.current.to,
      fromTo = dateTo >= dateFrom;

    if (!dateFrom || !dateTo || !fromTo) {
      if (!dateFrom || !dateTo) fromTo = true;
      Modal.info({
        title: 'Info',
        content: (
          <div>
            <p>
              {fromTo ? t('reportpage.filldate') : t('reportpage.orderDate')}
            </p>
          </div>
        ),
        onOk() {},
      });
      return false;
    }

    setLoading(true);
    reportRequest()
      .post(`/api/business/v1/report/${eventId}`, {
        from: dateFrom,
        to: dateTo,
      })
      .then((res) => {
        setLoading(false);
        setRepData(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        setApiError(true);
      });
  };

  const onDateChangeFrom = (date, dateString) => {
    setRepData(null);
    setApiError(false);
    rangeDates.current.from = date ? date.format('YYYY-MM-DD') : null;
  };

  const onDateChangeTo = (date, dateString) => {
    setRepData(null);
    setApiError(false);
    rangeDates.current.to = date ? date.format('YYYY-MM-DD') : null;
  };

  const showFilterModal = () => {
    setIsModalOpen(true);
  };

  const handleFilterOk = () => {
    setIsModalOpen(false);
  };
  const handleFilterCancel = () => {
    setIsModalOpen(false);
  };

  const onCheckChange = (values) => {
    setCheckFilter(values);
  };

  const getCardContent = (key) => {
    var str = '',
      datakey = repData[key];

    if (typeof datakey == 'undefined') key = '';

    switch (key) {
      case 'new_clients':
        str = (
          <>
            <div className="cl_iconCard">N</div>
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;

      case 'return_frequencies':
        str = (
          <>
            <RollbackOutlined className="cl_iconCard" />
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;

      case 'lost_customers':
        str = (
          <>
            <CloseCircleOutlined className="cl_iconCard" />
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;

      case 'hours_worked':
        str = (
          <>
            <ClockCircleOutlined className="cl_iconCard" />
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;

      case 'most_performed_service':
        str = (
          <>
            <IssuesCloseOutlined className="cl_iconCard" />
            <div className="cl_valueCardService">
              <svg
                width="14"
                height="14"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9.3"
                  cy="9"
                  r="7.5"
                  fill={datakey?.color || '#D4D3CF'}
                  stroke="#FFFDFD"
                  strokeWidth="3"
                />
              </svg>

              {' ' + datakey?.title || 'none'}

              <div className="cl_valueCardServiceSub">
                {datakey?.description}
              </div>

              {datakey.image.url && (
                <img
                  src={datakey.image.url}
                  style={{
                    maxHeight: '90px',
                    maxWidth: '140px',
                    float: 'right',
                    border: '1px solid #AAA',
                  }}
                  alt="service"
                />
              )}

              <div
                style={{
                  fontSize: '13px',
                  fontStyle: 'italic',
                  marginTop: '20px',
                }}
              >
                {t('reportpage.price') + ': ' + datakey?.price}
                <br />
                {t('reportpage.execution_time') +
                  ': ' +
                  datakey?.execution_time +
                  ' min.'}
              </div>
            </div>
          </>
        );
        break;

      case 'staffs':
        let staffIds = getStaffById(allStaff);
        str = (
          <div className="cl_wrapTabStaffs">
            <table className="cl_tabStaffs">
              <thead>
                <tr>
                  <th>{t('reportpage.operator')}</th>
                  <th>{t('reportpage.appointments')}</th>
                  <th>{t('reportpage.services')}</th>
                </tr>
              </thead>
              <tbody>
                {datakey.map((item) => (
                  <tr key={item.id}>
                    <td style={{ textAlign: 'left', padding: '0 6px 0 6px' }}>
                      {staffIds[item.id] || (
                        <span style={{ color: '#D66', fontStyle: 'italic' }}>
                          {t('reportpage.operator_deleted')}
                        </span>
                      )}
                    </td>
                    <td>{item.appointments}</td>
                    <td>{item.services}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
        break;

      case 'spent_more_than':
        str = (
          <>
            <DollarOutlined className="cl_iconCard" />
            <div style={{ marginTop: '20px' }}>
              {Object.keys(datakey).map((euro) => (
                <div key={euro}>
                  <span className="cl_euro">{'€ ' + euro + ' -> nr. '}</span>
                  <span className="cl_freq_euro">{datakey[euro]}</span>
                </div>
              ))}
            </div>
          </>
        );
        break;

      case 'spent_less_than':
        str = (
          <>
            <DollarOutlined className="cl_iconCard" />
            <div style={{ marginTop: '20px' }}>
              {Object.keys(datakey).map((euro) => (
                <div key={euro}>
                  <span className="cl_euro">{'€ ' + euro + ' -> nr. '}</span>
                  <span className="cl_freq_euro">{datakey[euro]}</span>
                </div>
              ))}
            </div>
          </>
        );
        break;

      case 'hairdresser_booking':
        str = (
          <>
            <div className="cl_iconCard"></div>
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;
      case 'client_booking':
        str = (
          <>
            <div className="cl_iconCard"></div>
            <div className="cl_valueCard">{datakey || 0}</div>
          </>
        );
        break;

      default:
        str = <div className="cl_valueError">Value not found</div>;
    }

    return <>{str}</>;
  };

  let filterData = [
    { key: 'new_clients', disabled: true },
    { key: 'return_frequencies', disabled: true },
    { key: 'lost_customers', disabled: true },
    { key: 'most_performed_service' },
    { key: 'staffs' },
    { key: 'spent_more_than' },
    { key: 'spent_less_than' },
    { key: 'hours_worked' },
    { key: 'client_booking' },
    { key: 'hairdresser_booking' },
  ];

  return (
    <>
      <DefaultPageLayout loadingContent={loading} hideSidebar={true}>
        <DefaultPageLayout.Title>
          <div className="page-title-ag">
            <LineChartOutlined className="iconHeader" />
            <span className="text-uppercase">{t('common:report')}</span>
          </div>
        </DefaultPageLayout.Title>

        <DefaultPageLayout.Content>
          <div style={{ margin: '20px' }}>
            <Space>
              <span>{t('reportpage.from')}</span>
              <DatePicker onChange={onDateChangeFrom} format={'DD-MM-YYYY'} />
              <span>{t('reportpage.to')}</span>
              <DatePicker onChange={onDateChangeTo} format={'DD-MM-YYYY'} />
            </Space>
            <div style={{ marginTop: '28px' }}>
              <Space>
                <span style={{ width: '100px' }}>{t('reportpage.filter')}</span>
                <Button
                  style={{ width: '180px' }}
                  className="secondary-button-outline"
                  onClick={showFilterModal}
                >
                  {t('reportpage.add_filter')}
                  <PlusOutlined />
                </Button>

                <Badge
                  count={checkFilter.length}
                  showZero
                  color={checkFilter.length > 3 ? '#E00' : '#46F'}
                  style={{ margin: '-19px 0 0 -19px' }}
                />

                <Button
                  type="primary"
                  className="secondary-button"
                  style={{ width: '144px' }}
                  onClick={fetchApiReport}
                >
                  {t('reportpage.compute')}
                </Button>
              </Space>
            </div>

            <div style={{ marginTop: '20px' }}>
              {apiError && (
                <div className="cl_notFound">
                  <WarningOutlined />
                  {' ' + t('reportpage.notfound')}
                </div>
              )}
              {repData &&
                filterData.map((item) => (
                  <Card
                    key={item.key}
                    title={t('reportpage.' + item.key)}
                    bordered={false}
                    style={{
                      display: checkFilter.includes(item.key)
                        ? 'block'
                        : 'none',
                      float: 'left',
                      margin: '0 10px 10px 0',
                      width: '380px',
                      height: '300px',
                      boxShadow: '0px 4px 12px #ccc',
                      borderRadius: '9px',
                    }}
                  >
                    {getCardContent(item.key)}
                  </Card>
                ))}
            </div>
          </div>
        </DefaultPageLayout.Content>
      </DefaultPageLayout>

      <Modal
        title={t('reportpage.add_filter')}
        open={isModalOpen}
        footer={[
          <Button
            key="close"
            className="secondary-button"
            onClick={handleFilterOk}
          >
            {t('reportpage.close')}
          </Button>,
        ]}
        cancelButtonProps={{
          ghost: true,
        }}
        onCancel={handleFilterCancel}
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          defaultValue={checkFilter}
          onChange={onCheckChange}
        >
          {filterData.map((item) => {
            return (
              <Row key={item.key}>
                <Col
                  span={22}
                  style={{
                    fontSize: '16px',
                    color: item.disabled ? '#aaa' : 'inherit',
                  }}
                >
                  {t('reportpage.' + item.key)}
                </Col>
                <Col span={2} style={{ textAlign: 'right', height: '40px' }}>
                  <Checkbox
                    value={item.key}
                    disabled={item.disabled || false}
                  ></Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
      </Modal>
    </>
  );
}

export default ReportPage;
