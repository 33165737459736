import PropTypes from 'prop-types';
import {
  Form,
  Calendar,
  Row,
  Col,
  Divider,
  Select,
  Typography,
  Button,
  Space,
  Card,
} from 'antd';
import { useEffect, useState } from 'react';
import './ExtraordinaryOpeningClosingCalendar.less';
import moment from 'moment';
import { range } from '../util/Helper';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../index';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import TimePauseForm from './TimePauseForm';

const { Option } = Select;

const START_DATE = 1;
const END_DATE = 2;

/**
 * Add extraordinary opening and closing date for the shop
 *
 * @param {*} param0
 * @returns {JSX}
 */
function ExtraordinaryOpeningClosingCalendar({
  initialValue,
  onSubmit,
  className,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cursor, setCursor] = useState(START_DATE);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [day, setDay] = useState({
    start_hour: 0,
    start_minute: 0,
    end_hour: 0,
    end_minute: 0,
  });

  const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

  useEffect(() => {
    if (initialValue) {
      setStartDate(initialValue.startDate);
      setEndDate(initialValue.endDate);
    }
    form.resetFields();
    form.setFieldsValue(initialValue ? { ...initialValue } : {});
  }, [form, initialValue]);

  const handleSubmit = (data) => {
    let pauseData = day.startHour
      ? {
          break_start: `${day.startHour}:${day.startMinute}`,
          break_end: `${day.endHour}:${day.endMinute}`,
        }
      : { break_start: '', break_end: '' };
    typeof onSubmit === 'function' &&
      onSubmit({
        ...data,
        ...pauseData,
        startDate,
        endDate,
      });
  };

  const handleDateSelect = (date) => {
    if (cursor === START_DATE) {
      setStartDate(date.format('YYYY-MM-DD'));
      setCursor(END_DATE);
      setEndDate(null);
    }

    if (cursor === END_DATE) {
      if (+new Date(startDate) > +date) {
        let endDate = startDate;
        setStartDate(date.format('YYYY-MM-DD'));
        setEndDate(endDate);
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
      setCursor(START_DATE);
    }
  };

  const resetAll = () => {
    setStartDate(null);
    setEndDate(null);
    setCursor(START_DATE);
  };

  const dateFullCellRender = (cellValue) => {
    let className = [];
    if (startDate && endDate) {
      className.push(
        cellValue.isBetween(moment(startDate), moment(endDate))
          ? 'selecting'
          : '',
      );
      className.push(
        cellValue.isSame(moment(endDate), 'date')
          ? 'selecting selection-end'
          : '',
      );
    }

    if (startDate) {
      className.push(
        cellValue.isSame(moment(startDate), 'date') ? 'selection-start' : '',
      );
    }
    className = className.join(' ');
    return (
      <div className={`calendar-date-value ${className}`}>
        {cellValue.format('DD')}
      </div>
    );
  };

  const headerRender = ({ value, onChange }, type) => {
    return (
      <div style={{ padding: 8 }}>
        <Typography.Paragraph className="text-center text-uppercase" level={4}>
          {value.format('MMMM (YYYY)')}
        </Typography.Paragraph>
        {type === 'start' && (
          <Button
            className="calendar-prev"
            onClick={() => {
              const newValue = value.clone();
              newValue.subtract(1, 'month');
              setDate(newValue.format('YYYY-MM-DD'));
            }}
          >
            <LeftOutlined />
          </Button>
        )}
        {type === 'end' && (
          <Button
            className="calendar-next"
            onClick={() => {
              const newValue = value.clone();
              setDate(newValue.format('YYYY-MM-DD'));
            }}
          >
            <RightOutlined />
          </Button>
        )}
      </div>
    );
  };

  const handlePauseSubmit = (data) => {
    setIsPauseModalOpen(false);
    // endHour,endMinute,startHour,startMinute
    setDay(data);
  };

  const isChangingStartDate = cursor === START_DATE;
  return (
    <Form
      {...layout}
      className={`${className} standalone-calendar`}
      name="control-ref"
      form={form}
      onFinish={handleSubmit}
    >
      <Card>
        <Row>
          <Col span={12}>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Typography.Text>Giorni</Typography.Text>

              <Button
                className="mx-3 my-1 secondary-button-outline"
                onClick={resetAll}
              >
                {t('shoptimepage.extraordinarymodal.reset')}
              </Button>
            </div>
          </Col>

          <Col
            className="calendar-date"
            style={{
              backgroundColor: isChangingStartDate ? '#dbdce1' : '',
            }}
            span={6}
          >
            <img src={CalendarIcon} alt="Calendar" />
            <Typography.Text className="text-uppercase px-1">
              {!!startDate
                ? moment(startDate).format('ddd, DD MMM')
                : `${
                    isChangingStartDate
                      ? t('shoptimepage.extraordinarymodal.current_selection')
                      : t('shoptimepage.extraordinarymodal.start_date')
                  } `}
            </Typography.Text>
          </Col>

          <Col
            className="calendar-date"
            style={{
              backgroundColor: !isChangingStartDate ? '#dbdce1' : '',
            }}
            span={6}
          >
            <img src={CalendarIcon} alt="Calendar" />

            <Typography.Text className="text-uppercase px-1">
              {!!endDate
                ? moment(endDate).format('ddd, DD MMM')
                : `${
                    !isChangingStartDate
                      ? t('shoptimepage.extraordinarymodal.current_selection')
                      : t('shoptimepage.extraordinarymodal.end_date')
                  }`}
            </Typography.Text>
          </Col>

          <Divider className="my-3" />
          <Col span={11}>
            <Calendar
              value={moment(date)}
              mode="month"
              dateFullCellRender={dateFullCellRender}
              fullscreen={false}
              defaultValue={moment()}
              onSelect={handleDateSelect}
              headerRender={(val) => headerRender(val, 'start')}
            />
          </Col>
          <Col span={2}>
            <Divider type="vertical" />
          </Col>
          <Col span={11}>
            <Calendar
              mode="month"
              dateFullCellRender={dateFullCellRender}
              fullscreen={false}
              defaultValue={moment().add(1, 'month')}
              value={moment(date).add(1, 'month')}
              onSelect={handleDateSelect}
              headerRender={(val) => headerRender(val, 'end')}
            />
          </Col>
        </Row>
      </Card>

      {!initialValue?.onlyDate && (
        <>
          <div className="w-100 py-2 my-2"></div>
          <Card>
            <Row>
              <Col span={22} offset={1}>
                <Typography.Paragraph className="font-16 text-center py-0 my-0">
                  Orari
                </Typography.Paragraph>
              </Col>
            </Row>
            <div className="py-2 w-100" />
            <Row>
              <Col span={12}>
                <Space size="large">
                  <p className="p-0 timtable-text m-0">
                    {t('timetablemodal.starttime')}
                  </p>
                  <Form.Item
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: t('error:timetable.start_hour_required'),
                      },
                    ]}
                    name={'start_hour'}
                  >
                    <Select
                      showSearch
                      placeholder={t('timetablemodal.placeholder.start_hour')}
                      style={{ width: 100 }}
                    >
                      {range(0, 23).map((hour) => (
                        <Option key={`start-${hour}`} value={hour}>
                          {hour}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <p className="timtable-text">:</p>
                  <Form.Item
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: t('error:timetable.start_minute_required'),
                      },
                    ]}
                    name={'start_minute'}
                  >
                    <Select
                      showSearch
                      placeholder={t('timetablemodal.placeholder.start_minute')}
                      style={{ width: 100 }}
                    >
                      <Option value={0}>{0}</Option>
                      <Option value={15}>{15}</Option>
                      <Option value={30}>{30}</Option>
                      <Option value={45}>{45}</Option>
                    </Select>
                  </Form.Item>
                </Space>
              </Col>

              <Col span={12}>
                <Space size="large">
                  <p className="p-0 timtable-text m-0">
                    {t('timetablemodal.endtime')}
                  </p>
                  <Form.Item
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: t('error:timetable.end_hour_required'),
                      },
                    ]}
                    name={'end_hour'}
                  >
                    <Select
                      showSearch
                      placeholder={t('timetablemodal.placeholder.end_hour')}
                      style={{ width: 100 }}
                    >
                      {range(0, 23).map((hour) => (
                        <Option key={`end-${hour}`} value={hour}>
                          {hour}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <p className="timtable-text">:</p>
                  <Form.Item
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: t('error:timetable.end_minute_required'),
                      },
                    ]}
                    name={'end_minute'}
                  >
                    <Select
                      showSearch
                      placeholder={t('timetablemodal.placeholder.end_minute')}
                      style={{ width: 100 }}
                    >
                      <Option value={0}>{0}</Option>
                      <Option value={15}>{15}</Option>
                      <Option value={30}>{30}</Option>
                      <Option value={45}>{45}</Option>
                    </Select>
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={24} className="text-center mt-4">
                <Button
                  onClick={() => setIsPauseModalOpen(true)}
                  htmlType="button"
                  className="secondary-button-outline"
                >
                  AGGIUNGI PAUSA
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      )}

      <Row>
        <Col span={24} className="text-center mt-4">
          <Button type="primary" className="secondary-button" htmlType="submit">
            {t('common:button.save')}
          </Button>
        </Col>
      </Row>
      <Modal
        open={isPauseModalOpen}
        onCancel={() => setIsPauseModalOpen(false)}
        closable={true}
        footer={null}
        centered
        maskClosable={true}
        width={'900px'}
        className="bg-light-yellow pb-0"
      >
        <TimePauseForm
          loading={false}
          initialValue={{
            startHour: day.startHour,
            startMinute: day.startMinute,
            endHour: day.endHour,
            endMinute: day.endMinute,
          }}
          title={t('shoptimepage.pausemodal.title')}
          onSubmit={handlePauseSubmit}
        />
      </Modal>
    </Form>
  );
}

ExtraordinaryOpeningClosingCalendar.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ExtraordinaryOpeningClosingCalendar;
