/**
 * Localstorage Keys
 */
const FRONTEND_ACCESS_TOKEN = 'user_access_token';
const BACKEND_ACCESS_TOKEN = 'stored_access_token';
const BACKEND_EVENT_IDENTIFIER = 'stored_event_id';

/**
 * Set the token in localStorage
 *
 * @param {String} token Access Token
 * @param {Boolean} remove Pass true to remove this field
 */
export const setTokenInStorage = (token, remove = false) => {
  if (remove) {
    localStorage.removeItem(BACKEND_ACCESS_TOKEN);
    return;
  }
  localStorage.setItem(BACKEND_ACCESS_TOKEN, token);
};

/**
 * Set eventid
 *
 * @param {String} eventId Event id
 * @param {Boolean} remove Remove this field
 */
export const setEventInStorage = (eventId, remove = false) => {
  if (remove) {
    localStorage.removeItem(BACKEND_EVENT_IDENTIFIER);
    return;
  }
  localStorage.setItem(BACKEND_EVENT_IDENTIFIER, eventId);
};

/**
 * Get event id
 *
 * @param {String} token Access Token
 */
export const getEventFromStorage = () => {
  return localStorage.getItem(BACKEND_EVENT_IDENTIFIER, null);
};

/**
 * Get access token
 *
 * @returns {String} Access Token
 */
export const getTokenFromStorage = () => {
  return localStorage.getItem(BACKEND_ACCESS_TOKEN, null);
};

export const getFeTokenFromStorage = () => {
  return localStorage.getItem(FRONTEND_ACCESS_TOKEN, null);
};

/**
 * Set token validity
 *
 * @param {String} eventId Event id
 * @param {Boolean} remove Remove this field
 */
export const setTokenValidityInStorage = (validity, remove = false) => {
  if (remove) {
    localStorage.removeItem('stored_validity_time');
    return;
  }
  localStorage.setItem('stored_validity_time', validity);
};

/**
 * Get token validity date
 *
 * @returns {Date|null} Token Validity Expiry Time
 */
export const getTokenValidityFromStorage = () => {
  let validity = localStorage.getItem(BACKEND_EVENT_IDENTIFIER, null);
  return validity ? new Date(validity * 1000) : null;
};

/**
 * Set the token in localStorage for frontend
 *
 * @param {String} token Access Token
 * @param {Boolean} remove Pass true to remove this field
 */
export const setFrontendTokenInStorage = (token, remove = false) => {
  if (remove) {
    localStorage.removeItem(FRONTEND_ACCESS_TOKEN);
    return;
  }
  localStorage.setItem(FRONTEND_ACCESS_TOKEN, token);
};

/**
 * Get access token for frontend user
 *
 * @returns {String} Access Token
 */
export const getFrontendTokenFromStorage = () => {
  return localStorage.getItem(FRONTEND_ACCESS_TOKEN, null);
};
