import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';

const LOADING = 'loading';
const LOADED = 'loaded';
const MIN_LEGTH_NOT_EXCEEDED = 'min';

export const SearchAutocomplete = ({
  currentOption,
  value,
  fetchOptions,
  debounceTimeout,
  onChange,
  minSearchLength = 0,
  ...props
}) => {
  const [searchState, setSearchState] = useState(LOADED);
  const [options, setOptions] = useState(currentOption ? [currentOption] : []);
  const fetchRef = useRef(0);

  const handleChange = (selectedId) =>
    selectedId && onChange(options.find((x) => x.id === selectedId.value));

  const debounceFetcher = useMemo(() => {
    const loadOptions = (keywords) => {
      const defaultValue = currentOption
        ? [
            {
              ...currentOption,
              label: currentOption.name,
              value: currentOption.id,
            },
          ]
        : [];
      if (keywords.length < minSearchLength) {
        setOptions(defaultValue);
        setSearchState(MIN_LEGTH_NOT_EXCEEDED);
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions(defaultValue);
      setSearchState(LOADING);

      fetchOptions(keywords).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(
          newOptions.map((item) => ({
            ...item,
            label: `${item.name} ${item.surname}`,
            value: item.id,
          })),
        );
        setSearchState(LOADED);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, minSearchLength, currentOption]);

  const notFound =
    searchState === LOADED ? null : (
      <>
        {searchState === MIN_LEGTH_NOT_EXCEEDED &&
          `Must be at least ${minSearchLength} character`}
        {searchState === LOADING && <Spin size="small" />}
      </>
    );

  const defaultValue = () => {
    let option = options.find((item) => item.id === value);
    if (!option) return;

    return {
      ...option,
      label: `${option.name} ${option.surname}`,
      value: option.id,
    };
  };

  return (
    <Select
      labelInValue
      showSearch
      autoClearSearchValue
      allowClear
      filterOption={false}
      defaultValue={defaultValue()}
      onSearch={debounceFetcher}
      notFoundContent={notFound}
      onChange={handleChange}
      options={options}
      {...props}
    />
  );
};
