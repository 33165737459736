import {
  Button,
  Input,
  Spin,
  Typography,
  Carousel,
  Modal,
  Alert,
  notification,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthHeader, SearchIcon } from '../../components';
import { frontendAuthRequest } from '../../services/api';
import {
  fetchShopDetail,
  frontendUser,
  shopDetail,
} from '../../features/frontend/frontendSlice';
import { selectServiceList } from '../../features/service/serviceSelector';
import { fetchFeServiceList } from '../../features/service/serviceSlice';
import './ChooseServicePage.less';
import ServiceList from './bookComponents/ServiceList';
import { LeftOutlined } from '@ant-design/icons';
import DateTime from './bookComponents/DateTime';
import ReviewConfirmBook from './bookComponents/ReviewConfirmBook';
import moment from 'moment';

/**
 * Routes component containing routes for the whole application
 *
 * @returns {JSX}
 *
 */
export default function ChooseServicePage() {
  const shop = useSelector(shopDetail);
  const services = useSelector(selectServiceList);
  const [searchServiceList, setSearchServiceList] = useState([]);
  const profile = useSelector(frontendUser);

  const { hairdresserId } = localStorage.getItem('fe-eventId');

  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [timeSlots, setTimeSlots] = useState({ times: [] });
  const [selectedTime, setSelectedTime] = useState({
    from: '',
    to: '',
    date: '',
  });
  const [staffAllOptions, setStaffAllOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendButton, setSendButton] = useState(false);
  const [endModal, setEndModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchShopDetail());
  }, [dispatch]);

  const fetchApiData = useCallback(() => {
    dispatch(fetchFeServiceList(hairdresserId));
  }, [dispatch, hairdresserId]);

  useEffect(() => {
    setSearchServiceList(services.filter((itm) => !itm.only_service));
  }, [services]);

  useEffect(() => {
    fetchApiData();
  }, [fetchApiData]);

  const onSearch = (input) => {
    let value = input.target.value;
    if (value.length) {
      setSearchServiceList(
        services.filter(
          (item) =>
            item.title.toLowerCase().indexOf(value.toLowerCase()) >= 0 &&
            !item.only_service,
        ),
      );
      return;
    }
    setSearchServiceList(services.filter((itm) => !itm.only_service));
  };

  const toggleServiceSelection = (item) => {
    if (selectedService.includes(item.id)) {
      setSelectedService(selectedService.filter((id) => item.id !== id));
      let newStaff = { ...selectedStaff };
      delete newStaff[item.id];
      setSelectedStaff(newStaff);
      return;
    }
    setSelectedService([...selectedService, item.id]);
    setSelectedStaff({ ...selectedStaff, [item.id]: 0 });
  };

  const handleContinue0 = (e) => {
    localStorage.setItem(
      'stored_selected_service',
      JSON.stringify(selectedService),
    );
    localStorage.setItem(
      'stored_selected_staff',
      JSON.stringify(selectedStaff),
    );
    nextSlide();
  };

  const handleContinue1 = (e) => {
    nextSlide();
  };

  const handleBack1 = (e) => {
    prevSlide();
  };

  const getServiceTotal = useCallback(() => {
    return services.reduce(
      (carry, current) =>
        (carry += selectedService.includes(current.id) ? current.price : 0),
      0,
    );
  }, [selectedService, services]);

  // slider
  const slider = useRef(null);
  const [slideNumber, setSlideNumber] = useState(0);

  const onChangeSlide = (currentSlide) => {
    setSlideNumber(currentSlide);
  };

  const nextSlide = () => {
    slider.current.next();
    sliderScrollTo();
  };

  const prevSlide = () => {
    slider.current.prev();
    sliderScrollTo();
  };

  const firstSlide = () => {
    slider.current.goTo(0, true);
  };

  function clearBook() {
    setTimeSlots({ times: [] });
    setCurrentDate(moment());
    setSelectedDate(null);
    setSelectedService([]);
    setSelectedStaff({});
    setSendButton(false);
    setSelectedTime({
      from: '',
      to: '',
      date: '',
    });
  }

  function sliderScrollTo() {
    const obj = document.getElementsByClassName(
      'wrapperSlider bg-light-gray',
    )[0];
    setTimeout(() => {
      obj.scrollTop = 0;
    }, 150);
  }

  const getOptions = (opt) => {
    // merge options key => labels
    const newOpt = { ...staffAllOptions };
    opt.forEach((el) => {
      if (newOpt[el.id] === undefined) newOpt[el.id] = el.label;
    });
    setStaffAllOptions(newOpt);
  };

  // generate appointment
  const handleGenerateAppointment = (e) => {
    setSendButton(true);
    setLoading(true);
    const eventId = localStorage.getItem('fe-eventId');
    const userId = localStorage.getItem('fe-userId');

    frontendAuthRequest()
      .post(`/api/business/v1/${eventId}/agenda_appointments?locale=en`, {
        agenda_type: 'appointment',
        operator: true,
        time_slots: true,
        // participant_id: profile.id,
        participant_id: userId,

        created_by: 1,
        phone_number: profile.phone_number,
        start_date: `${selectedTime.date} ${selectedTime.from}`,
        end_date: `${selectedTime.date} ${selectedTime.to}`,
        data: Object.keys(selectedStaff).map((serviceId) => ({
          agenda_services_attributes: [{ service_id: serviceId, note: '' }],
          staff_id: selectedStaff[serviceId],
        })),
      })
      .then((res) => {
        if (res.status !== 200) {
          setEndModal(true);
        }
        if (res.status > 201) {
          showErrorBook();
        }
      })
      .catch((err) => {
        showErrorBook();
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const showErrorBook = () => {
    setSendButton(false);
    notification['error']({
      message: 'Errore nella creazione della prenotazione!',
      description: 'Verifica la connessione ad internet e riprova.',
    });
  };

  useLayoutEffect(() => {
    const carousel = document.getElementsByClassName('ant-carousel')[0];

    carousel.addEventListener(
      'touchmove',
      (e) => {
        e.stopPropagation();
      },
      false,
    );
  }, []);

  return (
    <div className="main-page">
      <AuthHeader title={shop.name} />

      <div className="wrapperSlider bg-light-gray">
        <Spin spinning={loading}>
          <Carousel
            ref={slider}
            afterChange={onChangeSlide}
            dots={false}
            dragEnabled={false}
          >
            {/* 0 SELECT SERVICES */}
            <div className="contentSlider">
              {Boolean(shop?.image?.url) && (
                <div
                  className="text-center bg-light-gray"
                  style={{
                    background: `url(${shop.image.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '180px',
                  }}
                ></div>
              )}

              <div className="slideTitle">
                {t('frontend:servicepage.selectServices')}
              </div>

              <div style={{ margin: '10px 20px' }}>
                <Input
                  placeholder={`${t('calendar.search_treatment')}`}
                  size={'large'}
                  suffix={<img src={SearchIcon} alt="Search Services" />}
                  onChange={onSearch}
                  className="serchInput"
                />

                {!services.length && (
                  <Spin spinning={true} delay={300}>
                    <div
                      style={{
                        height: 300,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography.Text>Loading ...</Typography.Text>
                    </div>
                  </Spin>
                )}

                {!!services.length && (
                  <ServiceList
                    searchServiceList={searchServiceList}
                    selectedService={selectedService}
                    toggleServiceSelection={toggleServiceSelection}
                    selectedStaff={selectedStaff}
                    setSelectedStaff={setSelectedStaff}
                    getOptions={getOptions}
                  />
                )}

                <div className="w-100 py-2 my-1"></div>
              </div>
            </div>

            {/* 1 SELECT DATE TIME */}
            <div className="contentSlider">
              <div className="slideTitle">
                {t('frontend:servicepage.choose_date_time')}
              </div>
              <div className="contentDate">
                <DateTime
                  selectedStaff={selectedStaff}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  timeSlots={timeSlots}
                  setTimeSlots={setTimeSlots}
                />
              </div>
            </div>

            {/* 2 REVIEW AND CONFIRM */}
            <div className="slideTitle">
              {slideNumber === 2 && (
                <>
                  <div className="slideTitle">
                    {t('frontend:servicepage.review_confirm')}
                  </div>
                  <div className="contentDate">
                    <ReviewConfirmBook
                      services={services}
                      selectedService={selectedService}
                      selectedStaff={selectedStaff}
                      selectedTime={selectedTime}
                      setSelectedTime={setSelectedTime}
                      staffAllOptions={staffAllOptions}
                      getServiceTotal={getServiceTotal}
                    />
                  </div>
                </>
              )}
            </div>
          </Carousel>
        </Spin>
      </div>

      {/** FOOTER */}
      <div className="footerSlider">
        {/* 0 Continue (booking) */}
        {!slideNumber && (
          <Button
            onClick={handleContinue0}
            size="medium"
            shape="round"
            className="continue-button"
            disabled={!selectedService.length}
          >
            {t('frontend:servicepage.continueButton')}
          </Button>
        )}

        {/* 1 Continue (date time) */}
        {slideNumber === 1 && (
          <>
            <Button
              onClick={handleBack1}
              size="medium"
              shape="round"
              className="text-center back-button"
            >
              <LeftOutlined className="centerLeftIco" />
            </Button>

            <Button
              onClick={handleContinue1}
              size="medium"
              shape="round"
              className="continue-button"
              disabled={!selectedTime.date}
            >
              {t('frontend:servicepage.continueButton')}
            </Button>
          </>
        )}

        {slideNumber === 2 && (
          <>
            <Button
              onClick={(e) => prevSlide()}
              size="medium"
              shape="round"
              className="text-center back-button"
            >
              <LeftOutlined className="centerLeftIco" />
            </Button>

            <Button
              onClick={handleGenerateAppointment}
              size="medium"
              shape="round"
              className="continue-button"
              style={{ width: '200px' }}
              disabled={sendButton}
            >
              {t('frontend:servicepage.bookButton')}
            </Button>
          </>
        )}
        <div className="footterReview">
          {selectedService.length > 0 ? (
            <div style={{ marginLeft: slideNumber ? '60px' : '0' }}>
              {selectedService.length +
                ' ' +
                (selectedService.length === 1
                  ? t('frontend:servicepage.service')
                  : t('frontend:servicepage.services'))}
              <br />
              <span style={{ fontWeight: '700' }}>€ {getServiceTotal()}</span>
            </div>
          ) : (
            <>{t('frontend:servicepage.no_service')}</>
          )}
        </div>
      </div>

      {endModal && (
        <Modal
          title={false}
          centered
          open={endModal}
          footer={null}
          closable={false}
          maskClosable={false}
        >
          <div className="contentEndModal">
            <div className="tickicon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            <div className="confirmation-title">
              {t('frontend:confirmationpage.title')}
            </div>

            <Alert
              style={{ margin: '20px' }}
              message={
                t('frontend:reservationpage.cancellationText1') +
                (shop?.delete_notice_hours || 24) +
                t('frontend:reservationpage.cancellationText2')
              }
              type="warning"
            />

            <div style={{ padding: '20px' }}>
              <Link
                to="/reservation"
                className="link-button"
                data-testid="reservation-page"
              >
                {t('frontend:confirmationpage.goToReservation')}
              </Link>
            </div>

            <div style={{ padding: '20px 0 10px 0' }}>
              <Link
                to="/choose-service"
                className="link-button"
                data-testid="reservation-page"
                onClick={() => {
                  setEndModal(false);
                  clearBook();
                  firstSlide();
                }}
              >
                {t('frontend:confirmationpage.bookAgain')}
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
