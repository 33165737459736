import React from 'react';
import { useCalendarTheme } from '../../theme';
import PropTypes from 'prop-types';
import TemplateRender from '../../util/TemplateRender';

function DailyCalendarHeader({ columns, columnRef }) {
  const { computedStyles, theme: CalendarTheme, template } = useCalendarTheme();

  return (
    <div
      className="calendar-header flex-row"
      style={{ height: CalendarTheme.header.height }}
      data-testid="calendar-header"
    >
      <div
        className="calendar-hours-header"
        style={computedStyles.calendarHourHeaderStyle}
      ></div>
      <div
        className="calendar-columns flex-row overflow-x-scroll"
        id="calendarscrollLeft"
        ref={columnRef}
        style={computedStyles.calendarHeaderColumnContainerStyle}
      >
        {!!columns.length &&
          columns.map((column) => (
            <div
              className="calendar-column calendar-column-header"
              key={column.id}
              style={computedStyles.calendarColumnHeaderStyle}
            >
              <TemplateRender
                template={template.headerColumnTemplate || null}
                item={column}
                type={'daily'}
              >
                <div className="flex-column-center h-100">
                  <p className="m-0 text-center">{column.title}</p>
                </div>
              </TemplateRender>
            </div>
          ))}
      </div>
    </div>
  );
}

DailyCalendarHeader.propTypes = {
  template: PropTypes.object,
  columnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  columns: PropTypes.array.isRequired,
};

export default DailyCalendarHeader;
