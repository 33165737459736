const shouldLogError = process.env.NODE_ENV === 'development';

/**
 * Provide logging option in development and production mode
 * there are five modes debug, info, log, warn & error
 * these modes are used to handle the severity of log
 */
export const logHandler = {
    /**
     * Debug Mode
     * 
     * @param {object|string} message Data to log
     */
    debug: (message) => shouldLogError && console.debug(message),
    
    /**
     * Info Mode
     * 
     * @param {object|string} message Data to log
     */
    info: (message) => shouldLogError && console.info(message),
    
    /**
     * Log Mode
     * 
     * @param {object|string} message Data to log
     */
    log: function() { shouldLogError && console.log(...arguments); },
    
    /**
     * Warn Mode
     * 
     * @param {object|string} message Data to log
     */
    warn: (message) => shouldLogError && console.warn(message),

    /**
     * Error Mode
     * 
     * @param {object|string} message Data to log
     */
    error: (message) => shouldLogError && console.error(message)
};