import PropTypes from 'prop-types';
import { Card, Input, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { filterDataFromKeyword, sortByKey } from '../util/Helper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logHandler } from '../../handlers/LogHandler';

function StaffService({ staff, services })
{
    const [keyword, setKeyword] = useState('');
    const [serviceLists, setServiceLists] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if(keyword.length) {
            try{
                let lists = filterDataFromKeyword(services, keyword, ["title"])
                lists = sortByKey(lists, "title");
                setServiceLists(lists);
                return;
            }catch(error) {
                logHandler.error(error);
                return;
            }
        }

        setServiceLists(services);
    }, [keyword, services])

    const onSearch = (e) => setKeyword(e.target.value);
    const showServices = !!serviceLists.length;

    return (
        <Card>
            <div className="d-flex justify-content-between pb-4">
                <span></span>
                <Typography.Text className="d-flex flex-column align-items-center justify-content-center text-uppercase">{ t('common:active_services') } ({staff.name})</Typography.Text>

                <Input
                    data-testid="searchbar"
                    placeholder={ t("staffpage.service_search_placeholder") }
                    size="large"
                    style={{maxWidth: 200}}
                    suffix={ 
                        <SearchOutlined style={{ fontSize: '20px' }}/>
                    }
                    onChange={onSearch}
                />
            </div>
            <div className="w-100 mt-2 py-2"></div>
            <div data-testid="service-lists">
                {showServices && serviceLists.map(item => (
                    <div key={`service-${item.id}`} className="list">
                        <div className="w-100 d-flex py-2 flex-row justify-content-between">
                            <Typography.Text className="font-16">{item.title}</Typography.Text>
                            <Typography.Text className="font-16">{item.price}</Typography.Text>
                            <Typography.Text  className="font-16">{ item.readableDuration }</Typography.Text>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
}


StaffService.propTypes = {
    staff: PropTypes.object,
    services: PropTypes.array,
}



export default StaffService;