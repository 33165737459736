import React, { createContext, useContext, useEffect, useState } from 'react';

const ScheduleProviderContext = createContext({
    schedules: [],
    scheduleClick: null, 
    scheduleDblClick: null,
    scheduleDrop: null,
    dragAndDrop: false
  });

/**
 * Use calendar theme
 * @returns any
 */
export const useSchedulesProvider = () => {
    return useContext(ScheduleProviderContext);
}


/**
 * Schedule Context Provider For Managing Calendar Theme And Options
 * 
 * @param {Object} theme  Theme of the calendar
 * @param {Array} schedules  Schedule list
 * @param {Function} scheduleClick  Click event on schedule
 * @param {Function} scheduleDblClick  Double click event on schedule
 * @param {Function} scheduleDrop  Drop event of schedule
 * @param {JSX} children Children
 * @returns {JSX}
 */
 export function ScheduleProvider({ schedules, children, scheduleClick, scheduleDblClick, dragAndDrop }) {
  const [task, setTask] = useState([]);
  
  useEffect(() => setTask(schedules), [schedules])
  return(
    <ScheduleProviderContext.Provider value={{schedules: task, scheduleClick: scheduleClick, scheduleDblClick: scheduleDblClick, dragAndDrop: dragAndDrop}}>
        {children}
    </ScheduleProviderContext.Provider>
  );

}
