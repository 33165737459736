import { Col, Layout, Row, Spin } from 'antd';
import Navbar from '../header/Header';

const Header = () => null;

const Title = () => null;

const Sidebar = () => null;

const Content = () => null;

const { Sider, Content: AntdContent } = Layout;

/**
 * Default Page Layouts
 *
 * @param {*} param0
 * @returns {JSX}
 */
const DefaultPageLayout = ({
  children,
  loadingContent = false,
  loadingSidebar = false,
  bodyClass,
  hideSidebar = false,
}) => {
  const renderChildren = children && typeof children.find === 'function';
  const onlySingleChildrenPassed =
    children && typeof children.find !== 'function';

  if (onlySingleChildrenPassed) {
    throw new Error(
      'Page Layout Expects At Least Two Child Component To Have Value. Try with Header, Content, Title, Sidebar',
    );
  }

  const header = renderChildren && children.find((el) => el.type === Header);
  const sidebar = renderChildren && children.find((el) => el.type === Sidebar);
  const content = renderChildren && children.find((el) => el.type === Content);
  const title = renderChildren && children.find((el) => el.type === Title);

  return (
    <div className="main-page">
      <Navbar title={title ? title.props.children : null}>
        {header ? header.props.children : null}
      </Navbar>

      <Layout style={{ minHeight: 'calc(100vh - 86px)' }} className={bodyClass}>
        {!hideSidebar && (
          <Sider
            width={320}
            theme={'light'}
            style={{ background: '#4a4e69', minHeight: 'calc(100vh - 86px)' }}
          >
            <div className="mt-4"></div>
            <Row>
              <Col offset={2} span={20}>
                <Spin
                  style={{ minHeight: '80vh' }}
                  spinning={loadingSidebar}
                  delay={200}
                >
                  {sidebar ? sidebar.props.children : null}
                </Spin>
              </Col>
            </Row>
          </Sider>
        )}

        <AntdContent>
          <Spin spinning={loadingContent} delay={200}>
            {content ? content.props.children : null}
          </Spin>
        </AntdContent>
      </Layout>
    </div>
  );
};

DefaultPageLayout.Header = Header;
DefaultPageLayout.Sidebar = Sidebar;
DefaultPageLayout.Content = Content;
DefaultPageLayout.Title = Title;

export default DefaultPageLayout;
