import {
  deleteAuthRequest,
  getAuthRequest,
  postAuthRequest,
  putAuthRequest,
} from '../../services/api';
import { getEventFromStorage } from '../../services/token';

export function fetchStaffListRequest() {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/staffs?locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

export function fetchStaffHolidayListRequest(data) {
  const eventId = getEventFromStorage();
  return getAuthRequest(
    `/api/business/v1/events/${eventId}/staffs/${data.id}/holidays?from=${
      data.from
    }&to=${data.to}&locale=${localStorage.getItem('lang')}`,
  );
}

export function createStaffRequest(data) {
  const eventId = getEventFromStorage();
  return postAuthRequest(
    `/api/business/v1/events/${eventId}/staffs?locale=${localStorage.getItem(
      'lang',
    )}`,
    data,
  );
}

export function deleteStaffRequest(id) {
  const eventId = getEventFromStorage();
  return deleteAuthRequest(
    `/api/business/v1/events/${eventId}/staffs/${id}?locale=${localStorage.getItem(
      'lang',
    )}`,
  );
}

export function updateStaffRequest(data) {
  const eventId = getEventFromStorage();
  let id = data.id;
  delete data.id;
  return putAuthRequest(
    `/api/business/v1/events/${eventId}/staffs/${id}?locale=${localStorage.getItem(
      'lang',
    )}`,
    data,
  );
}
