/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './DailyCalendar.less';
import PropTypes from 'prop-types';
import DailyCalendarHeader from './header';
import DailyCalendarBody from './body';
import { CalendarThemeProvider } from '../theme';
import { range } from '../../util/Helper';
import { DefaultOpeningHours } from '../theme/data';
import { ScheduleProvider } from '../providers/ScheduleProvider';
import ErrorRenderHandler from '../handlers/ErrorRenderHandler';

/**
 * Daily Calendar is for managing user daily
 *
 * @param {Object} theme  Theme of the calendar
 * @param {Object} dateOption Opening time and other options
 * @param {Object} columns  List of columns
 * @param {Object} template  List of template options
 * @param {Object} schedules  List of schedule
 * @param {Object} onCellClick  Fired when cell is clicked
 * @param {Object} onColumnClick  Fired when column is clicked
 * @param {Object} onScheduleClick  Fired when schedule is clicked
 * @param {Object} onScheduleDoubleClick  Fired when double clicked on schedule
 * @returns {JSX}
 */
const DailyCalendar = React.memo(function DailyCalendar({
  onScheduleClick,
  onScheduleDblClick,

  //
  date,
  onCellClick,

  // Styles
  height = null,

  // Template
  theme,
  template,

  // Data provided
  schedules,
  dateOption,
  columns,
  dragAndDrop = false,
}) {
  const [openingHours, setOpeningHours] = useState(DefaultOpeningHours);
  const [allHours, setAllHours] = useState([]);
  const columnRef = useRef(null);
  const hourRef = useRef(null);

  /**
   * Synchoronize horizontal and vertical scroll in calendar
   *
   * @param {Object} e Event
   */
  const synchronizeScroll = (e) => {
    if (columnRef && columnRef.current)
      columnRef.current.scrollLeft = e.target.scrollLeft;
    if (hourRef && hourRef.current)
      hourRef.current.scrollTop = e.target.scrollTop;
  };

  /**
   * Initialize scrolling event listener
   * @returns {function}
   */
  const initializeScrollListener = () => {
    let calendarScroller = null;

    setTimeout(() => {
      calendarScroller = document.getElementById('calendarscroller');
      calendarScroller &&
        calendarScroller.addEventListener('scroll', synchronizeScroll);
    }, 1000);

    return () => {
      calendarScroller &&
        calendarScroller.removeEventListener('scroll', synchronizeScroll, true);
    };
  };

  const handleCellClick = useCallback(
    (cellData) => {
      if (typeof onCellClick === 'function') {
        cellData.start_date = dateOption.date
          .clone()
          .set({ hour: cellData.hour, minute: cellData.minute });
        cellData.end_date = cellData.start_date.clone().add(15, 'minutes');
        onCellClick(cellData);
      }
    },
    [dateOption],
  );

  useEffect(initializeScrollListener, []);

  useEffect(() => {
    if (dateOption !== undefined) {
      const option = { ...openingHours, ...dateOption };
      setOpeningHours(option);
      setAllHours(range(option.start, option.end));
    }
  }, [dateOption, dateOption.date]);

  return (
    <CalendarThemeProvider
      theme={theme}
      dateOption={dateOption}
      templates={template}
      height={height || 600}
    >
      <ScheduleProvider
        schedules={schedules}
        scheduleClick={onScheduleClick}
        scheduleDblClick={onScheduleDblClick}
        dragAndDrop={dragAndDrop}
      >
        <div
          className="calendar position-relative overflow-hidden"
          style={{ height: height || 600 }}
        >
          <ErrorRenderHandler>
            {/* Header */}
            <DailyCalendarHeader columns={columns} columnRef={columnRef} />
          </ErrorRenderHandler>

          {/* Header End */}
          <ErrorRenderHandler>
            {/* Body Start */}
            <DailyCalendarBody
              date={date}
              hourRef={hourRef}
              columns={columns}
              hours={allHours}
              onCellClick={handleCellClick}
            />
            {/* Body End */}
          </ErrorRenderHandler>
        </div>
      </ScheduleProvider>
    </CalendarThemeProvider>
  );
});

DailyCalendar.propTypes = {
  height: PropTypes.number,
  dateOption: PropTypes.object,

  columns: PropTypes.array.isRequired,
  schedules: PropTypes.array,
  template: PropTypes.object,
  theme: PropTypes.object,

  onCellClick: PropTypes.func,
  onScheduleClick: PropTypes.func,
  onScheduleDrop: PropTypes.func,
  onScheduleDoubleClick: PropTypes.func,
};

export default DailyCalendar;
