import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateError } from "../../components/util/Helper";
import { fetchNotificationRequest, fetchUnreadNotificationCountRequest } from "./notificationApi";

const initialState = {
    loading: false,
    fetchingData : [],
    error: {
      hasError: false,
      status: 0,
      data: null
    },
    unreadCount: 0,
    notifications: []
};
  


export const fetchNotificationCount = createAsyncThunk(
    'notification/fetchNotificationCount',
    async () => {
      const response = await fetchUnreadNotificationCountRequest();
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);

export const fetchNotifications = createAsyncThunk(
    'notification/fetchNotifications',
    async (page) => {
      const response = await fetchNotificationRequest(page);
      // The value we return becomes the `fulfilled` action payload
      return response.error ? response : response.data;
    }
);
  

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(fetchNotificationCount.pending, (state) => {
          state.fetchingData = ["fetchNotificationCount", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
        .addCase(fetchNotificationCount.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchNotificationCount");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload.unread_notifications;
          state.notifications = data;
        })
  
        .addCase(fetchNotifications.pending, (state) => {
          state.fetchingData = ["fetchNotifications", ...state.fetchingData];
          state.loading = !!state.fetchingData.length;
        })
  
        .addCase(fetchNotifications.fulfilled, (state, action) => {
          state.fetchingData = state.fetchingData.filter(item => item !== "fetchNotifications");
          state.loading = !!state.fetchingData.length;
  
          if(updateError(action.payload, state)){return;}
          const data = action.payload.notifications;
          state.notifications = data;
        });
    },
});

export const selectUnreadCount = (state) => state.notification.unreadCount;
export const selectNotificationList = (state) => state.notification.notifications;



export default notificationSlice.reducer;