export const CustomWindowEvent = {
  SCHEDULE_DROPPED: 'schedule:drop',
  SCHEDULE_REFRESH: 'schedule:refresh',
};

export function triggerEventListener(eventName, detail = {}, extra = '') {
  let triggerElement = detail.element ?? window;
  const options = detail.eventOptions ?? {};

  delete detail.element;
  delete detail.eventOptions;

  triggerElement.dispatchEvent(
    new CustomEvent(`${eventName}${extra}`, {
      detail: detail,
      ...options,
    }),
  );
}

export function triggerEventListenerRefresh(eventName, detail = null) {
  window.dispatchEvent(new CustomEvent(`${eventName}`, { detail: detail }));
}
