import { Form, Input, Button, Upload, notification } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Client update or create form 
 * @param {Object} param0 
 * @returns 
 */
function StaffCreateForm({ initialValue, onSubmit }) {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([])
    
    const { t } = useTranslation();

    const onFinishFailed = (errorInfo) => {
        notification["error"]({
            title: "Action Failed",
            message: errorInfo?.getMessage() || errorInfo?.message || "Please try again later"
        })
    };

    const normFile = (e) => (Array.isArray(e)) ? e : e && e.fileList;

    const layout = {
        labelCol: { offset: 2, span: 6 },
        wrapperCol: { span: 10 },
    };

    useEffect(() => {
        form.setFieldsValue(initialValue);
    }, [form, initialValue])
    
    // Dummy request to simulate file upload for antd
    const dummyRequest = ({ file, onSuccess }) => setTimeout(() => { onSuccess("ok"); }, 0);


    const handleSubmit = (data) => {
        let newData = {...data};
        if(fileList.length) {
            newData.image = fileList[0].base64;
        }else{
            newData.image = null;
        }

        typeof onSubmit === 'function' && onSubmit(newData);
    }

    const handleUpload = (info) => {
        let fileList = [...info.fileList];
        // Accept 5 files only
        fileList = fileList.slice(-5);
        fileList.forEach(function (file, index) {
          let reader = new FileReader();
          reader.onload = (e) => {
            file.base64 = e.target.result;
          };
          reader.readAsDataURL(file.originFileObj);
        });
        setFileList(fileList);
    };

    return (
        <div>
            <Form {...layout} 
                form={form}
                initialValues={initialValue} 
                onFinish={handleSubmit} 
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item labelAlign="left" name={'name'} label={ t("staffpage.label.name") } rules={[{ required: true }]}>
                    <Input size="large" placeholder={ t("staffpage.placeholder.name") } />
                </Form.Item>

                <Form.Item labelAlign="left" name={'surname'} label={ t("staffpage.label.surname") } rules={[{ required: true }]}>
                    <Input size="large" placeholder={ t("staffpage.placeholder.surname") } />
                </Form.Item>

                <Form.Item labelAlign="left" name={'email'} label={ t("staffpage.label.email") } rules={[{ required: false, type: 'email' }]}>
                    <Input type={"email"} size="large" placeholder={ t("staffpage.placeholder.email") } />
                </Form.Item>

                <Form.Item labelAlign="left" name={'phone_number'} label={ t("staffpage.label.phone_number") } rules={[{ required: true }]}>
                    <Input size="large" placeholder={ t("staffpage.placeholder.phone_number") } />
                </Form.Item>
                
                <Form.Item labelAlign="left"  label={ t("staffpage.label.avatar") }>
                    <Form.Item name="image" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <Upload.Dragger name="files" maxCount={1} onChange={handleUpload} customRequest={dummyRequest}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{ t("common:drag_to_upload_text") }</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>


                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">{ t('staffpage.button.create') }</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

StaffCreateForm.propTypes = {
    initialValue: PropTypes.object, 
    onSubmit: PropTypes.func
};

export default StaffCreateForm;