import { Col, Row, Space, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationTimeline from '../components/timeline/NotificationTimeline';
import { logHandler } from '../handlers/LogHandler';
import { authRequest } from '../services/api';
import { getEventFromStorage } from '../services/token';
import { DefaultPageLayout } from './../components/index';

function NotificationPage() {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { t } = useTranslation();

  const fetchApiData = useCallback(() => {
    setLoading(true);
    let eventId = getEventFromStorage();
    authRequest()
      .get(`/api/business/v1/events/${eventId}/notifications`)
      .then((res) => {
        setLoading(false);
        setNotifications(res.data.notifications);
        logHandler.log(res);
      })
      .catch((error) => {
        logHandler.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => fetchApiData(), [fetchApiData]);

  const notificationIcon = (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50017 15C6.90888 15 6.42874 14.5506 6.42874 13.9991H5.35731C5.35731 15.1025 6.31892 16 7.50017 16C8.68142 16 9.64303 15.1025 9.64303 13.9991H8.5716C8.5716 14.5506 8.09147 15 7.50017 15ZM14.5107 10.4628C13.5745 9.63094 12.8587 8.76031 12.8587 5.80937C12.8587 3.32219 10.7362 1.29375 8.03589 1.04844V0.5C8.03589 0.22375 7.79615 0 7.50017 0C7.20419 0 6.96446 0.22375 6.96446 0.5V1.04875C4.26412 1.29406 2.14168 3.32219 2.14168 5.80937C2.14168 8.76 1.4255 9.63094 0.489335 10.4628C0.0215888 10.8784 -0.126068 11.5069 0.11266 12.0638C0.356411 12.6325 0.943019 13 1.60731 13H13.393C14.0573 13 14.6439 12.6322 14.8877 12.0634C15.1264 11.5066 14.9784 10.8784 14.5107 10.4628ZM13.393 12H1.60731C1.13085 12 0.892795 11.4853 1.22829 11.1872C2.39548 10.15 3.21311 8.98906 3.21311 5.80969C3.21311 3.70406 5.13064 2 7.50017 2C9.86937 2 11.7872 3.70375 11.7872 5.80937C11.7872 8.97656 12.5978 10.1438 13.7721 11.1869C14.1089 11.4866 13.8675 12 13.393 12Z"
        fill="black"
      />
    </svg>
  );

  return (
    <DefaultPageLayout loadingContent={loading} hideSidebar={true}>
      <DefaultPageLayout.Title>
        <div className="page-title-ag">
          <Space>
            <div className="mt-1">{notificationIcon}</div>
            <span className="px-1 text-uppercase">
              {t('notificationpage.navbar_title')}
            </span>
          </Space>
        </div>
      </DefaultPageLayout.Title>

      <DefaultPageLayout.Content className="bg-light-yellow">
        <div className="my-4 py-4 w-100"></div>
        <Row>
          <Col span={18} offset={3}>
            <Spin spinning={loading} style={{ minHeight: '80vh' }}>
              <NotificationTimeline timeline={notifications} />
            </Spin>
          </Col>
        </Row>

        <div className="my-4 py-4 w-100"></div>
      </DefaultPageLayout.Content>
    </DefaultPageLayout>
  );
}

export default NotificationPage;
