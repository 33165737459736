import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCalendarTheme } from '../../theme';
import TemplateRender from '../../util/TemplateRender';
import { useTranslation } from 'react-i18next';
import ContextMenu from './ContextMenu';

// touch drag drop
import { tchDrgDrp } from '../../dnd/touchDragDrop.js';

function ScheduleCard({
  schedule,
  clickedSchedule,
  doubleClickedSchedule,
  containerStyle = {},
}) {
  let timer = 0;
  let delay = 200;
  let prevent = false;

  const [height, setHeight] = useState(15);
  const { template, theme } = useCalendarTheme();

  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({ x: 10, y: 10 });

  const { t } = useTranslation();

  useEffect(() => {
    const cellHeight = theme.body.cellHeight;

    const diffInMinute = moment(schedule.start_date).diff(
      moment(schedule.end_date),
      'minute',
    );

    // Minus this if you want to adjust padding or margin. Please make sure
    // to substract for each side. eg. 2 px is 4 in substraction as top
    // 2 and bottom 2 makes it 4px.
    let height = (Math.abs(diffInMinute) / 15) * cellHeight;
    height = Math.abs(diffInMinute) >= 600 ? '100%' : Math.ceil(height);
    setHeight(height);
  }, [schedule, theme.body.cellHeight]);

  function handleClick(e) {
    if (typeof clickedSchedule === 'function') {
      clickedSchedule(schedule);
    }
  }

  const clickedOnce = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    timer = setTimeout(function () {
      if (!prevent) {
        handleClick(e);
      }
      prevent = false;
    }, delay);
  };

  function handleDoubleClick(e) {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    clearTimeout(timer);
    prevent = true;
    if (typeof doubleClickedSchedule === 'function') {
      doubleClickedSchedule(schedule);
    }
  }

  // right click & long touch
  function handleRightClick(e) {
    e.preventDefault();
    if (e.button === 2) e.stopPropagation(); // mouse click dx

    let rect,
      count = 0,
      j = e.target;

    while (j.className !== 'schedule') {
      j = j.parentNode;
      count++;
      if (count > 8) return false; // secure loop limit
    }

    j = j.parentNode.parentNode;
    rect = j.getBoundingClientRect();

    setPoints({
      x: e.nativeEvent.pageX - rect.left,
      y: e.nativeEvent.pageY - rect.top,
    });

    tchDrgDrp.isMenuVisible = 1;
    setClicked(true);
    showMask(true);
  }

  function closeMenu(action) {
    tchDrgDrp.isMenuVisible = 0;
    setClicked(false);
    showMask(false);
  }

  function showMask(mode) {
    const jmask = document.getElementById('id_maskMenu');

    if (mode) {
      jmask.style.display = 'block';
      jmask.addEventListener('click', closeMenu, false);
    } else {
      jmask.removeEventListener('click', closeMenu, false);
      jmask.style.display = 'none';
    }
  }

  // in touch start drag on schedule
  function handleTouchStart(e) {
    tchDrgDrp.scheduleId = schedule.id;
    tchDrgDrp.countMove = 0;
    tchDrgDrp.tchStartTime = new Date().getTime();
    return true;
  }

  function handleTouchMove(e) {
    tchDrgDrp.countMove++;
    return true;
  }

  function handleTouchEnd(e) {
    const T = tchDrgDrp;
    const tms = new Date().getTime();
    const deltaTime = tms - T.tchStartTime;

    //console.log('tchEnd:  count: ' + T.countMove + 'schedule: ' + deltaTime);

    if (T.countMove < 6 && deltaTime > 310 && deltaTime < 900) {
      e.stopPropagation();

      tchDrgDrp.tchStartTime = 0;
      handleRightClick(e);
    }
  }

  const heightStyle =
    height === '100%'
      ? { maxHeight: 400 }
      : { maxHeight: height, height: height };
  let widthStyle = {};
  // Dynamically added from transformer
  const totalInnerDeps = schedule?._overlaps?.length || 0;
  if (totalInnerDeps) {
    containerStyle.zIndex += totalInnerDeps;
    widthStyle = {
      border: '1px solid white',
      width: `${100 - totalInnerDeps * 10}%`,
      marginLeft: `${totalInnerDeps * 10}%`,
    };
  }

  const mainContainerStyle = {
    ...{ overflow: 'hidden' },
    ...containerStyle,
    ...widthStyle,
    ...heightStyle,
    top: (moment(schedule.start_date).minutes() % 15) * 2.6,
  };

  return (
    <>
      <div
        className="schedule"
        style={mainContainerStyle}
        onClick={clickedOnce}
        onContextMenu={handleRightClick}
        onDoubleClick={handleDoubleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <TemplateRender
          item={schedule}
          template={template.renderSchedule}
          type={'daily'}
        >
          <div className="schedule-card" style={{ height: height }}>
            <div className="schedule-title">
              <p>
                {schedule.title} {height === '100%' ? '(R)' : ''}
              </p>
            </div>
          </div>
        </TemplateRender>
        <p
          style={{
            display: height === '100%' ? 'block' : 'none',
            fontSize: 10,
            background: 'green',
            margin: 0,
            color: 'white',
            paddingLeft: 2,
          }}
        >
          {t('common:height_truncate')}
        </p>
      </div>
      {clicked && (
        <ContextMenu
          left={points.x}
          top={points.y}
          closeMenu={closeMenu}
          schedule={schedule}
        ></ContextMenu>
      )}
    </>
  );
}

ScheduleCard.propTypes = {
  schedule: PropTypes.object.isRequired,
  clickedSchedule: PropTypes.func,
  doubleClickedSchedule: PropTypes.func,
  containerStyle: PropTypes.object,
};

export default ScheduleCard;
