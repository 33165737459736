import { Modal, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { frontendLogout } from '../../features/frontend/frontendSlice';
import { setFrontendTokenInStorage } from '../../services/token';

function LogoutModal({ open = false, closeModal, onLogout }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    setFrontendTokenInStorage('', true);
    dispatch(frontendLogout());
    onLogout();
  };

  return (
    <Modal
      title="Logout"
      open={open}
      onCancel={closeModal}
      width={'400px'}
      okType={'default'}
      footer={[
        <Button key="back" className="round-button" onClick={closeModal}>
          {t('frontend:servicepage.cancel')}
        </Button>,

        <Button key="ok" className="modal-button" onClick={handleLogout}>
          OK
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        {t('frontend:logoutConfirmation')}
      </Typography.Paragraph>
    </Modal>
  );
}

LogoutModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  onLogout: PropTypes.func,
};

export default LogoutModal;
