import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isLoggedIn: false,
  error: false,
  loading: true,
  message: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.loading = false;
      state.message = null;
      state.error = false;
    },

    login: (state) => {
      state.isLoggedIn = true;
      state.message = null;
      state.error = false;
      state.loading = false;
    },

    userIsAuthenticating: (state) => {
      state.loading = true;
    },

    // set authentication error
    setAuthError: (state, action) => {
      const { message } = action.payload;
      state.error = true;
      state.message = message;
      state.loading = false;
      state.isLoggedIn = false;
    }
  },
  
});

export const { logout, login, setAuthError, userIsAuthenticating } = authSlice.actions;

export const isLoggedIn =  (state) => state.auth.isLoggedIn;
export const hasAuthError =  (state) => state.auth.error;
export const authMessage =  (state) => state.auth.message;


export default authSlice.reducer;
