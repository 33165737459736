import { getDurationInHumanReadableFormat } from '../components/util/Helper';
import moment from 'moment';

/*
 * Convert seconds to hour minute second format
 *
 * @param {Integer} timeInSeconds Time in seconds
 * @returns {String}
 */
const getDurationForServices = (timeInSeconds) => {
  if (timeInSeconds === null) {
    return '00';
  }
  let format = new Date(timeInSeconds * 1000);
  return `${
    format.getUTCHours() ? format.getUTCHours() + ' h' : ''
  } ${format.getUTCMinutes()}m`.trim();
};

/**
 * Transforms the list of services
 *
 * @param {Array} services List of services to transform
 * @returns {Array}
 */
export const serviceTransformerCollection = (services) => {
  return services.map((item) => {
    item.hourFormat = getDurationInHumanReadableFormat(item.execution_time);
    item.readableDuration = getDurationForServices(item.execution_time);
    item.duration = item.execution_time;
    return item;
  });
};

// split services and recalculate start_date
export const serviceSplitToArraySchedule = (joinData) => {
  var r,
    k,
    duration,
    startDt,
    data = [];

  // service: [ {service_id: 42, note: undefined, duration: 9000} ]
  for (r = 0; r < joinData.service.length; r++) {
    let singleData = {};
    for (k in joinData) {
      if (k === 'service') singleData[k] = [joinData.service[r]];
      else singleData[k] = joinData[k];
    }

    // startDate (no first)
    if (r) singleData.startDate = data[r - 1].endDate;

    // change endDate
    duration = singleData.service[0].duration;
    startDt = moment(singleData.startDate);
    singleData.endDate = startDt
      .clone()
      .add(duration, 'seconds')
      .format('YYYY-MM-DD HH:mm');

    data.push(singleData);
  }

  return data;
};
