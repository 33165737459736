import { notification } from 'antd';
import { t } from 'i18next';
import { NOTIFICATION_ACTION, NOTIFICATION_CREATED, NOTIFICATION_DELETED, NOTIFICATION_UPDATED } from '../app/constants';
import { logHandler } from './LogHandler';

/**
 * 
 * @param {Object} response Response From Api
 * @returns null
 */
export const parseApiResponseAndSendNotification = (response, mode) => {
    response = response instanceof Error ? response.response : response
    if(![ NOTIFICATION_CREATED, NOTIFICATION_DELETED, NOTIFICATION_UPDATED, NOTIFICATION_ACTION ].includes(mode)) {
        logHandler.error(`Notification type of ${mode} is not valid`);return ;
    }
    let statusCode = response.status;
    
    let message = {
        [NOTIFICATION_CREATED]: {
            success: t("notification.created_title"),
            error: t("notification.error_created_title")
        },
        [NOTIFICATION_UPDATED]: {
            success: t("notification.updated_title"),
            error: t("notification.error_updated_title")
        },
        [NOTIFICATION_DELETED]: {
            success: t("notification.deleted_title"),
            error: t("notification.error_deleted_title")
        },
        [NOTIFICATION_ACTION]: {
            success: t("notification.action_title"),
            error: t("notification.error_action_title")
        }
    };

    let description = {
        [NOTIFICATION_CREATED]: {
            success: t("notification.created_message"),
            error: t("notification.error_created_message")
        },
        [NOTIFICATION_UPDATED]: {
            success: t("notification.updated_message"),
            error: t("notification.error_updated_message")
        },
        [NOTIFICATION_DELETED]: {
            success: t("notification.deleted_message"),
            error: t("notification.error_deleted_message")
        },
        [NOTIFICATION_ACTION]: {
            success: null,
            error: null
        }
    }


    if(statusCode === 1) {
        notification["error"]({
            message: t('notification.default_offline_api_req_title'),
            description: t('notification.default_offline_api_req_message'),
        });
        return;
    }

    if(statusCode === 422) {
        notification["error"]({
            message: message[mode]["error"],
            description: response.data.error || response.data.message || description[mode]["error"],
        });
        return;
    }

    if(response.type) {
        statusCode = response.type && response.type.includes('fulfilled') ? 200 : 400;
    }

    if(response.error) {
        notification["error"]({
            message: message[mode]["error"],
            description: response.data.error || description[mode]["error"],
        });
        return;
    }
 
    if(statusCode === 200) {
        if(response?.data?.status === 400) {
            notification["error"]({
                message: response?.data?.message || message[mode]["error"],
                // description: response?.data?.message || description[mode]["error"],
            });
            return;
        }
        notification["success"]({
            message: message[mode]['success'],
            // description: description[mode]['success'],
        });
    }
    return;
}