export const range = (min, max) =>
  [...Array(max - min + 1).keys()].map((i) => i + min);

/**
 * Sort the array of object based on the provided key of object
 *
 * @param {Array} item Array of object to sort
 * @param {String} key Key of object to sort
 * @returns {Integer}
 */
export const sortByKey = (item, key) => {
  if (key === undefined) return item;
  return [...item].sort((a, b) => String(a[key]).localeCompare(b[key]));
};

/**
 * Sort the array of object based on the id of object
 *
 * @param {Array} itemA Object
 * @param {Array} itemB Array to sort
 * @returns {Integer}
 */
export const sortById = (itemA, itemB) => {
  if (itemA.id < itemB.id) {
    return -1;
  }
  if (itemA.id > itemB.id) {
    return 1;
  }
  return 0;
};

/**
 * Update error state in the state
 *
 * @param {*} error Error of the reducer
 * @param {*} stateError Current state of the reducer
 * @returns {boolean}
 */
export const updateError = (error, stateError) => {
  if (!error.error) {
    return false;
  }
  stateError.error.hasError = true;
  stateError.error.status = error.status;
  stateError.error.data = error.data;
  return true;
};

/**
 * Convert seconds to hour minute second format
 *
 * @param {Integer} timeInSeconds Time in seconds
 * @returns {String}
 */
export const getDurationInHumanReadableFormat = (timeInSeconds) => {
  if (timeInSeconds === null || timeInSeconds === undefined) {
    return '00';
  }
  let format = new Date(timeInSeconds * 1000).toJSON().substr(11, 8);
  return format.split('00:').join('');
};

// '2024-02-13' =>  13/02/2024
export const dateToItaFormat = (date) => {
  if (!date) return '';
  const dt = date.toLowerCase().split('-');
  if (dt.length !== 3) return date;
  return dt[2] + '/' + dt[1] + '/' + dt[0];
};

/**
 * Filter in searchIn and return only matched data
 *
 * @param {Array} data Data to filter on
 * @param {String} keyword Key to search for
 * @param {Array} searchIn List of keys
 * @returns {Array}
 */
export const filterDataFromKeyword = (data, keyword, searchIn) => {
  if (keyword.length === null) {
    return data;
  }

  return data.filter((item) => {
    try {
      return searchIn.some((key) => item[key].toLowerCase().includes(keyword));
    } catch (error) {
      return false;
    }
  });
};

// only for date
export const filterDateFromKeyword = (data, keyword, searchIn) => {
  if (keyword.length === null) {
    return data;
  }

  return data.filter((item) => {
    try {
      return searchIn.some((key) =>
        dateToItaFormat(item[key]).includes(keyword),
      );
    } catch (error) {
      return false;
    }
  });
};
