/**
 * Set and get current language of the app
 */

import { LANG_TYPES } from '../app/constants';
import i18n from '../i18n';

/**
 * Get the current language of system
 *
 * @returns {string}
 */
export const getCurrentLanguage = () => {
  let lang = localStorage.getItem('lang') || 'it';
  return lang || 'it';
};

/**
 * Set the current language of the system
 * @param {string} lang Language name
 * @returns {string}
 */
export const setCurrentLanguage = (lang) => {
  lang = Object.keys(LANG_TYPES).includes(lang) ? lang : getCurrentLanguage();
  localStorage.setItem('lang', lang);
  i18n.changeLanguage(LANG_TYPES[lang]);
  return lang;
};
