import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { range } from '../util/Helper';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import TimePauseForm from './TimePauseForm';
const { Option } = Select;

function TimeDurationLeaveForm({ title, initialValue, onSubmit, className }) {
  const { t } = useTranslation();
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [day, setDay] = useState({});
  const [form] = Form.useForm();

  const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

  const getDayFormat = useCallback((dayFromApi) => {
    if (dayFromApi === null || dayFromApi === undefined) {
      return {};
    }
    const { from, to } = dayFromApi;
    return {
      start_hour: from.length ? parseInt(from.split(':')[0]) : 0,
      start_minute: from.length ? parseInt(from.split(':')[1]) : 0,
      end_hour: to.length ? parseInt(to.split(':')[0]) : 0,
      end_minute: to.length ? parseInt(to.split(':')[1]) : 0,
    };
  }, []);

  useEffect(() => {
    setDay({
      id: initialValue?.id,
      break_end: initialValue?.break_end,
      break_start: initialValue?.break_start,
      from: initialValue?.from,
      leave: initialValue?.leave,
      to: initialValue?.to,
    });
    form.resetFields();
    form.setFieldsValue(getDayFormat(initialValue));
  }, [form, initialValue, getDayFormat]);

  const handleSubmit = (data) =>
    typeof onSubmit === 'function' &&
    onSubmit({
      ...data,
      break_end: day.break_end,
      break_start: day.break_start,
      day: day,
    });

  const addStaffPause = (data) => {
    setIsPauseModalOpen(false);
    setDay({
      ...day,
      break_end: `${data.endHour}:${data.endMinute}`,
      break_start: `${data.startHour}:${data.startMinute}`,
    });
  };
  return (
    <div>
      <Form {...layout} name="control-ref" form={form} onFinish={handleSubmit}>
        <Card className={className}>
          <Row>
            <Col span={24}>
              <Typography.Paragraph
                level={5}
                className="text-center font-14 text-uppercase"
              >
                {title || ''}
              </Typography.Paragraph>
            </Col>

            {/** range dal - al */}
            <div className="my-1 py-1 w-100"></div>

            <Col span={12}>
              <Space size="large">
                <p className="timtable-text">{t('timetablemodal.starttime')}</p>
                <Form.Item
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: t('error:timetable.start_hour_required'),
                    },
                  ]}
                  name={'start_hour'}
                >
                  <Select
                    showSearch
                    placeholder={t('timetablemodal.placeholder.start_hour')}
                    style={{ width: 100 }}
                  >
                    {range(0, 23).map((hour) => (
                      <Option key={`start-${hour}`} value={hour}>
                        {hour}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <p className="timtable-text">:</p>
                <Form.Item
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: t('error:timetable.start_minute_required'),
                    },
                  ]}
                  name={'start_minute'}
                >
                  <Select
                    showSearch
                    placeholder={t('timetablemodal.placeholder.start_minute')}
                    style={{ width: 100 }}
                  >
                    <Option value={0}>{0}</Option>
                    <Option value={15}>{15}</Option>
                    <Option value={30}>{30}</Option>
                    <Option value={45}>{45}</Option>
                  </Select>
                </Form.Item>
              </Space>
            </Col>

            <Col span={12}>
              <Space size="large">
                <p className="timtable-text">{t('timetablemodal.endtime')}</p>
                <Form.Item
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: t('error:timetable.end_hour_required'),
                    },
                  ]}
                  name={'end_hour'}
                >
                  <Select
                    showSearch
                    placeholder={t('timetablemodal.placeholder.end_hour')}
                    style={{ width: 100 }}
                  >
                    {range(0, 23).map((hour) => (
                      <Option key={`end-${hour}`} value={hour}>
                        {hour}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <p className="timtable-text">:</p>

                <Form.Item
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: t('error:timetable.end_minute_required'),
                    },
                  ]}
                  name={'end_minute'}
                >
                  <Select
                    showSearch
                    placeholder={t('timetablemodal.placeholder.end_minute')}
                    style={{ width: 100 }}
                  >
                    <Option value={0}>{0}</Option>
                    <Option value={15}>{15}</Option>
                    <Option value={30}>{30}</Option>
                    <Option value={45}>{45}</Option>
                  </Select>
                </Form.Item>
              </Space>
            </Col>

            <div className="my-2 py-1 w-100"></div>

            <Col span={12} offset={6} className="text-center">
              <Button
                onClick={() => setIsPauseModalOpen(true)}
                htmlType="button"
                className="secondary-button-outline"
              >
                {t('shoptimepage.pausemodal.title')}
              </Button>
            </Col>
          </Row>
        </Card>
        <Row>
          <div className="w-100 pt-4 mt-2"></div>
          <Col span={8} offset={9}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ minWidth: 180 }}
              className="secondary-button"
            >
              {t('shoptimepage.save')}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        open={isPauseModalOpen}
        onCancel={() => setIsPauseModalOpen(false)}
        closable={true}
        footer={null}
        centered
        maskClosable={true}
        width={'900px'}
        className="bg-light-yellow pb-0"
      >
        <TimePauseForm
          loading={false}
          initialValue={{
            startHour: day?.break_start?.length
              ? day.break_start.split(':')[0]
              : 0,
            startMinute: day?.break_start?.length
              ? day.break_start.split(':')[1]
              : 0,
            endHour: day?.break_end?.length ? day.break_end.split(':')[0] : 0,
            endMinute: day?.break_end?.length ? day.break_end.split(':')[1] : 0,
          }}
          title={t('shoptimepage.pausemodal.title')}
          onSubmit={addStaffPause}
        />
      </Modal>
    </div>
  );
}

TimeDurationLeaveForm.propTypes = {
  initialValue: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string,
    id: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default TimeDurationLeaveForm;
