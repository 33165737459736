import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './Header.less';
import 'antd/dist/antd.css';
import Sidebar from '../sidebar/Sidebar';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

function Header({ title = null, children }) {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <div className="page-header flex-row-between noselect w-100">
      <div className="ml-20 d-flex flex-row justify-content-between align-items-center h-100">
        {title}
      </div>

      {children}

      <div
        className="d-flex flex-row justify-content-between align-items-center h-100 hamburger-container"
        style={{ width: 94 }}
      >
        <Button
          ghost={true}
          className="menu-icon"
          onClick={(e) => setOpenSidebar(true)}
        >
          <MenuOutlined />
        </Button>
        <Sidebar
          closeSidebar={() => setOpenSidebar(false)}
          open={openSidebar}
        />
      </div>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};

export default Header;
