import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { range } from '../../util/Helper';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

function GeneralLeave({
  loading,
  title,
  initialValue,
  onSubmit,
  onhandleReset,
}) {
  const { t } = useTranslation();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Spin spinning={loading} delay={300}>
      <Typography.Paragraph className="time-modal-title text-center">
        {title}
      </Typography.Paragraph>

      <div className="py-2"></div>

      <Form
        {...layout}
        name="control-ref"
        initialValues={initialValue}
        onFinish={onSubmit}
      >
        <Row>
          <Col className="my-2" offset={1} span={11}>
            <Space size="large">
              <Form.Item
                className="h-100 m-0"
                name="startHour"
                label="Dalle"
                rules={[{ required: true }]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  data-testid="start_hour"
                  style={{ minWidth: 100 }}
                >
                  {range(0, 23).map((hour) => (
                    <Option key={`start-${hour}`} value={hour}>
                      {hour}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <p className="timtable-text">:</p>

              <Form.Item
                className="h-100 m-0"
                name="startMinute"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  data-testid="start_minute"
                  style={{ minWidth: 100 }}
                >
                  <Option value={0}>{0}</Option>
                  <Option value={15}>{15}</Option>
                  <Option value={30}>{30}</Option>
                  <Option value={45}>{45}</Option>
                </Select>
              </Form.Item>
            </Space>
          </Col>

          <Col className="my-2" span={11}>
            <Space size="large">
              <Form.Item
                className="h-100 m-0"
                name={'endHour'}
                label="ALLE"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  data-testid="end_hour"
                  style={{ minWidth: 100 }}
                >
                  {range(0, 23).map((hour) => (
                    <Option key={`end-${hour}`} value={hour}>
                      {hour}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{' '}
              <p className="timtable-text">:</p>
              <Form.Item
                className="h-100 m-0"
                name="endMinute"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  data-testid="end_minute"
                  style={{ minWidth: 100 }}
                >
                  <Option value={0}>{0}</Option>
                  <Option value={15}>{15}</Option>
                  <Option value={30}>{30}</Option>
                  <Option value={45}>{45}</Option>
                </Select>
              </Form.Item>
            </Space>
          </Col>

          <Col span={24} className="py-4" />

          <Col className="my-2" offset={1} span={22}>
            <Form.Item name="note" wrapperCol={20}>
              <Input.TextArea placeholder={'Eventuali Note'} rows={5} />
            </Form.Item>
          </Col>

          <Col className="my-2 mx-4" span={6}>
            <Button
              type="default"
              size="large"
              shape="round"
              htmlType="button"
              onClick={onhandleReset}
              className="px-4 secondary-button-outline"
            >
              {t('common:button.reset')}
            </Button>
          </Col>

          <Col className="my-2 text-center" span={12}>
            <Form.Item wrapperCol={{ offset: 0 }}>
              <Button
                htmlType="submit"
                size="large"
                className="rounded-0 secondary-button font-weight-bold"
              >
                {t('common:button.save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

GeneralLeave.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default GeneralLeave;
