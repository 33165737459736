/**
 * 
 * @param {object} defaultData Default Param To Provide
 * @returns 
 */
export const parseUrlAndSetParam = (defaultData) => {
  let urlParams = window.location?.search?.substr(1);
  let searchParam = new URLSearchParams(urlParams.length ? urlParams : '');

  Object.keys(defaultData).forEach((item) => {
    if (defaultData[item] !== null) {
      if (searchParam.has(item)) {
        defaultData[item] = searchParam.get(item);
        searchParam.set(item, defaultData[item]);
      } else {
        searchParam.append(item, defaultData[item]);
      }
    }
  })
  return searchParam;
}



export const updateRouteParams = (updateData, newUrl, history) => {

  Object.keys(updateData).forEach((item) => {
    if (updateData[item] === null) {
      newUrl.delete(item);
      return;
    }

    if (!newUrl.has(item)) {
      newUrl.append(item, updateData[item]);
      return;
    }
    newUrl.set(item, updateData[item]);
    return;
  });


  if (newUrl.toString() !== window.location.search.substr(1)) {
    history.push({
      pathname: window.location.pathname,
      search: `?${newUrl.toString()}`
    })
  }
}