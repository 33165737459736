import axios from 'axios';
import {
  apiRequest,
  frontendAuthRequest,
  postAuthRequest,
} from '../../services/api';
// import { getEventFromStorage } from '../../services/token';
import {
  transformLoginRequest,
  transformRegisterRequest,
} from './frontendTransformer';

// Send Frontend Login Request
export function postFrontendLoginRequest(data) {
  // const eventId = getEventFromStorage();
  return postAuthRequest(
    `/api/business/v1/attendees/login/1?lang=it`,
    transformLoginRequest(data),
  );
}

export function fetchFrontendUserRequest(data) {
  // const eventId = getEventFromStorage();
  return postAuthRequest(
    `/api/business/v1/attendees/login/1?lang=it`,
    transformLoginRequest(data),
  );
}

export function setapp() {
  return apiRequest().post(
    `/api/business/v1/setapp?lang=${localStorage.getItem('lang')}`,
    {
      company: `4mxkShdWlMn/6yKZr1rMBQ==`,
      bundle_id: 'kotuko.inbellezza.staging',
    },
    {
      headers: {
        Authorization: `Token token="767dffb9ffb6fb30a3dc7eb7e985e1f8"`,
      },
    },
  );
}

export function fetchFrontendHairdressers(userid) {
  return apiRequest().get(
    `/api/business/v1/event/1/usereventlist?lang=${localStorage.getItem(
      'lang',
    )}`,
    {
      headers: {
        Authorization: `Token token="767dffb9ffb6fb30a3dc7eb7e985e1f8"`,
        'USER-ID': userid,
      },
    },
  );
}

// Send Register Request
export async function postFrontendRegisterRequest(data) {
  try {
    const res = await axios.post(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/api/business/v1/attendees/register/1?lang=${localStorage.getItem(
        'lang',
      )}`,
      transformRegisterRequest(data),
    );
    return res;
  } catch (error) {
    return error;
  }
}

export function fetchShopDetailRequest() {
  const eventId = localStorage.getItem('fe-eventId');
  return apiRequest().get(`/api/business/v1/events/${eventId}/shop-details`);
}

export function fetchUserDetailRequest() {
  return frontendAuthRequest().get(`/api/business/v1/users/profile`);
}

export function removeHairdresser(event_Id, userid) {
  return apiRequest().delete(`api/business/v1/event/${event_Id}/remove_event`, {
    headers: {
      Authorization: `Token token="767dffb9ffb6fb30a3dc7eb7e985e1f8"`,
      'USER-ID': userid,
    },
  });
}

export function addvoucher(vouchercode, userid) {
  return apiRequest().post(
    `api/business/v1/event/1/setvoucher `,
    { voucher_code: vouchercode },
    {
      headers: {
        Authorization: `Token token="767dffb9ffb6fb30a3dc7eb7e985e1f8"`,
        'USER-ID': userid,
      },
    },
  );
}
