import PropTypes from 'prop-types';
import { Card, Col, notification, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import TimeDurationLeaveForm from './TimeDurationLeaveForm';
import { logHandler } from '../../handlers/LogHandler';
import { useState } from 'react';
import { sortById } from '../util/Helper';

function WeeklyShopTimeForm({
  changeChecked,
  weekData,
  onSubmit,
  className = '',
}) {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    logHandler.log(weekData);
  }, [weekData]);

  const handleDayClick = async (day) => {
    const updatedDay = day.closed ? true : false;
    await changeChecked(updatedDay, day);
    setSelectedDay({ ...day, closed: !updatedDay });
  };

  const handleSubmit = (data) => {
    // Send notification when day is not selected
    if (selectedDay === null) {
      notification['error']({
        message: t('error:shoptime.weeklyTimeModal.day_not_selected'),
        description: t('error:shoptime.weeklyTimeModal.select_day'),
      });
      return null;
    }
    typeof onSubmit === 'function' && onSubmit({ ...data, day: selectedDay });
  };

  const checkedSvg = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07824 18.7518L20.644 6.77737C21.0367 6.37076 21.0367 5.71146 20.644 5.30485L19.2217 3.83232C18.829 3.42572 18.1921 3.42565 17.7994 3.83232L8.36708 13.5978L3.96337 9.03851C3.57064 8.63191 2.93383 8.63191 2.54104 9.03851L1.11877 10.511C0.726036 10.9176 0.726036 11.577 1.11877 11.9836L7.65591 18.7517C8.0487 19.1584 8.68545 19.1584 9.07824 18.7518Z"
        fill="black"
      />
    </svg>
  );

  return (
    <div>
      <Card>
        <div className="w-100 mt-4 py-1"></div>
        <Row gutter={10}>
          {weekData.sort(sortById).map((day) => (
            <Col
              span={6}
              key={`day-${day.id}`}
              className={`mb-4 text-center cursor-pointer dailycard ${
                selectedDay && selectedDay.id === day.id ? 'selected' : ''
              }`}
              onClick={() => {
                setSelectedDay(day);
              }}
            >
              <div
                className="icon-placeholder"
                onClick={() => handleDayClick(day)}
              >
                {!day.closed && checkedSvg}
              </div>
              <Card className={`${className} weekly-time-card`}>
                <Typography.Paragraph className="weekly_date_title mb-0">
                  {day.day}
                </Typography.Paragraph>
                {day.closed ? (
                  <p className="time">{t('week_schedule_form.closed')}</p>
                ) : (
                  <p className="time">
                    {day.from} - {day.to}
                  </p>
                )}
              </Card>
            </Col>
          ))}
        </Row>

        <TimeDurationLeaveForm
          onSubmit={handleSubmit}
          className="bg-light-yellow"
          initialValue={selectedDay}
        />
      </Card>
    </div>
  );
}

WeeklyShopTimeForm.propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  weekData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WeeklyShopTimeForm;
