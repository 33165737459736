import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCalendarTheme } from '../../theme';
import TemplateRender from '../../util/TemplateRender';

function ScheduleCard({ schedule, clickedSchedule, doubleClickedSchedule, containerStyle = {} }) {
    let timer = 0;
    let delay = 200;
    let prevent = false;

    const [height, setHeight] = useState(15)
    const { template, theme} = useCalendarTheme();
  
    useEffect(() => {
        const cellHeight = theme.body.cellHeight;
        const diffInMinute = moment(schedule.start_date).diff(moment(schedule.end_date), "minute");
        setHeight(((Math.abs(diffInMinute) / 15) * cellHeight) - 8);
    }, [schedule, theme.body.cellHeight])

    function handleClick(e) {
        if(typeof clickedSchedule === 'function'){
            clickedSchedule(schedule);
        }
    }

    const clickedOnce = (e) => {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      timer = setTimeout(function() {
        if (!prevent) {
            handleClick(e);
        }
        prevent = false;
      }, delay);
    }

    function handleDoubleClick(e){
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();

        clearTimeout(timer);
        prevent = true;
        if(typeof doubleClickedSchedule === 'function'){
            doubleClickedSchedule(schedule);
        }
    }

    return (
        <div className="schedule" draggable style={{...{maxHeight: height},...containerStyle}} onClick={clickedOnce} onDoubleClick={handleDoubleClick}>
            <TemplateRender item={schedule} template={template.renderSchedule} type={"weekly"}>
                <div className="schedule-card" style={{height: height}}>
                    <div className="schedule-title">
                        ...
                    </div>
                </div>
            </TemplateRender>
        </div>
    );
}


ScheduleCard.propTypes = {
    schedule: PropTypes.object.isRequired,
    clickedSchedule: PropTypes.func,
    doubleClickedSchedule: PropTypes.func
};


export default ScheduleCard;