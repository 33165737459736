export const columns = [
  {
    id: 1,
    title: 'simon',
    holiday: [],
  },
  {
    id: 2,
    title: 'luca',
    holiday: [
      {
        start_date: new Date().toISOString().substr(0, 10) + ' 08:00',
        end_date: new Date().toISOString().substr(0, 10) + ' 09:00',
      },
      {
        start_date: new Date().toISOString().substr(0, 10) + ' 10:00',
        end_date: new Date().toISOString().substr(0, 10) + ' 14:25',
      },
      {
        start_date: new Date().toISOString().substr(0, 10) + ' 16:00',
        end_date: new Date().toISOString().substr(0, 10) + ' 20:00',
      },
    ],
  },
  {
    id: 3,
    title: 'Lucy',
    holiday: [],
  },
  {
    id: 4,
    title: 'Nasu',
    holiday: [],
  },
];

export const schedules = [
  {
    id: '123',
    column_id: 1,
    staff_id: 1,
    participant: { name: 'someone' },
    title: 'Awesome',
    start_date: new Date().toISOString().substr(0, 10) + ' 12:45',
    end_date: new Date().toISOString().substr(0, 10) + ' 14:00',
  },
  {
    id: '323',
    column_id: 2,
    staff_id: 2,
    participant: { name: 'someone' },
    title: 'Meeting with mahesh',
    start_date: new Date().toISOString().substr(0, 10) + ' 10:45',
    end_date: new Date().toISOString().substr(0, 10) + ' 11:00',
  },
  {
    id: 'woksdji',
    column_id: 3,
    staff_id: 3,
    participant: { name: 'someone' },
    title: 'Meeting with all person',
    start_date: new Date().toISOString().substr(0, 10) + ' 08:00',
    end_date: new Date().toISOString().substr(0, 10) + ' 11:00',
  },
  {
    id: '4oksdji',
    column_id: 4,
    staff_id: 4,
    participant: { name: 'someone' },
    title: 'Meeting with new one',
    start_date: new Date().toISOString().substr(0, 10) + ' 12:00',
    end_date: new Date().toISOString().substr(0, 10) + ' 15:00',
  },
];

/**
 * All Leave Types And Short Code
 */
export const totalLeaveTypes = {
  permit: 'permit',
  holiday: 'holiday',
  lunch_break: 'lunch break',
  meeting: 'meeting',
  commercial_appointment: 'commercial appointment',
  formation: 'formation',
  recovery: 'recovery',
  illness: 'illness',
  other_salon: 'Other salon',
  time_strength_of_day: 'Time strength of the day',
  overbooking: 'overbooking',
};
