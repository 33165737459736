/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Modal, Popover, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ExtraordinaryOpeningClosingCalendar from '../../components/form/ExtraordinaryOpeningClosingCalendar';
import {
  CalendarHeader,
  DailyCalendar,
  LeaveModal,
  ScheduleCard,
  ScheduleModal,
  WeeklyCalendar,
} from '../../components/index';

import { NOTIFICATION_CREATED } from '../../app/constants';
import { rszDrg } from '../../components/card/resizeCard';
import {
  parseUrlAndSetParam,
  updateRouteParams,
} from '../../components/util/RouteHelper';
import {
  moveMultiAppointment,
  diffHoursMin,
} from '../../components/util/Generic';
import { parseApiResponseAndSendNotification } from '../../handlers/ApiErrorNotifier';
import ErrorRenderHandler from '../../handlers/ErrorRenderHandler';
import { logHandler } from '../../handlers/LogHandler';
import { authRequest } from '../../services/api';
import { CustomWindowEvent } from '../../services/event';
import { getEventFromStorage } from '../../services/token';
import {
  changeDate,
  changeToDailyView,
  changeToWeeklyView,
  fetchAllDeleteReasonsForSchedules,
  fetchAllSchedules,
  fetchAllStatusColors,
  isDailyView,
  selectCalendarColumn,
  selectCalendarState,
  selectColors,
  selectDate,
  selectSchedules,
  selectShopTime,
  updateDraggedSchedule,
} from './calendarSlice';
import { totalLeaveTypes as AllLeaveType } from './data';
import axios from 'axios';

const theme = { body: { cellHeight: 40 } };

export default function Calendar() {
  const dispatch = useDispatch();

  const date = useSelector(selectDate);
  const schedules = useSelector(selectSchedules);
  const dailyView = useSelector(isDailyView);
  const loading = useSelector(selectCalendarState);
  const columns = useSelector(selectCalendarColumn);
  const colors = useSelector(selectColors);
  const shopTime = useSelector(selectShopTime);

  const { t } = useTranslation();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState({});

  const [leaveModal, setLeaveModal] = useState(false);
  const [holidayData, setHolidayData] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [dateOption, setDateOption] = useState({
    start: 6,
    end: 18,
    date: moment(date),
  });

  const [popoverState, setPopoverState] = useState({});

  const onDateChanged = useCallback(
    async (newDate) => {
      updateRouteParams(
        {
          from: newDate.clone().format('YYYY-MM-DD'),
          view: dailyView ? 'daily' : 'weekly',
        },
        parseUrlAndSetParam({}),
        history,
      );
      dispatch(changeDate(newDate.clone().format('YYYY-MM-DD')));
    },
    [dailyView],
  );

  const allowPrevDateThanToday = shopTime.length
    ? Boolean(shopTime[0]?.visit_previous_date)
    : false;

  const handleDrop = (e) => {
    const data = e.detail;
    if (!Object.keys(data).includes('draggableId')) {
      return;
    }
    onScheduleDrop(data);
  };

  // drop schedule
  useEffect(() => {
    window.addEventListener(CustomWindowEvent.SCHEDULE_DROPPED, handleDrop);

    return () => {
      window.removeEventListener(
        CustomWindowEvent.SCHEDULE_DROPPED,
        handleDrop,
      );
    };
  }, [schedules, dailyView]);

  // refresh after resize / holiday
  useEffect(() => {
    window.addEventListener(
      CustomWindowEvent.SCHEDULE_REFRESH,
      refreshCalendarDay,
    );

    return () => {
      window.removeEventListener(
        CustomWindowEvent.SCHEDULE_REFRESH,
        refreshCalendarDay,
      );
    };
  }, []);

  useEffect(() => {
    if (!shopTime.length) return;
    let currentShopTime = shopTime[0];
    const start = parseInt(currentShopTime.from.split(':')[0]);
    const end = parseInt(currentShopTime.to.split(':')[0]);

    if (!allowPrevDateThanToday && moment(date).isBefore(moment(), 'date')) {
      onDateChanged(moment());
    } else {
      setDateOption({ start, end, date: moment(date) });
    }
  }, [shopTime]);

  const onViewChange = (val) => {
    updateRouteParams(
      { view: val ? 'daily' : 'weekly' },
      parseUrlAndSetParam({}),
      history,
    );
    dispatch(val ? changeToDailyView() : changeToWeeklyView());
  };

  const fetchTodaySchedule = (date, abort = {}) => {
    let startDate = moment(date);
    if (typeof startDate === 'string') {
      startDate = moment(date);
    }
    const endDate = dailyView
      ? null
      : startDate.clone().add(6, 'days').format('YYYY-MM-DD');
    dispatch(
      fetchAllSchedules({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate,
        requestConfig: abort,
      }),
    )
      .unwrap()
      .catch((err) => {
        logHandler.error(err);
      });
  };

  useEffect(() => {
    let url = parseUrlAndSetParam({});
    if (url.has('scheduleModal')) {
      let scheduleModalId = url.get('scheduleModal');
      schedules.forEach((item) => {
        if (String(item.id) === String(scheduleModalId)) {
          onScheduleClick(item);
        }
      });
    }
  }, [schedules]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchTodaySchedule(date, { cancelToken: source.token });
    return () => {
      source.cancel();
    };
  }, [dailyView, date]);

  useEffect(() => {
    dispatch(fetchAllStatusColors());
    dispatch(fetchAllDeleteReasonsForSchedules());
  }, []);

  function openLeaveModal(modalType, column) {
    if (modalType === 'holiday') {
      setModalData({
        onlyDate: true,
        modalType,
        column: { ...column, currentDate: date },
      });
      setHolidayData(true);
    } else {
      setModalData({ modalType, column: { ...column, currentDate: date } });
      setLeaveModal(true);
    }
  }

  // copy operator appontments
  const copyOperator = (column) => {
    const items = schedules.filter((item) => item.staff_id === column.id);

    let minDate = items.length ? items[0].start_date : '-';
    items.forEach((item) => {
      if (item.start_date < minDate) minDate = item.start_date;
    });

    localStorage.setItem('copy_type', minDate);
    localStorage.setItem('copy_schedule', JSON.stringify(items || []));

    if (!items || !items.length) {
      Modal.warning({
        title: 'Attenzione!',
        content: 'Nessun appuntamento copiato per questo operatore.',
      });
    }
  };

  // paste operator appontments
  const pasteOperator = (column) => {
    const type = localStorage.getItem('copy_type');
    let copySchedule = JSON.parse(localStorage.getItem('copy_schedule') || []);

    if (!copySchedule.length) {
      Modal.warning({
        title: 'Attenzione!',
        content: 'Nessun appuntamento da incollare.',
      });
      return;
    }
    const dt = type.split(' ');
    const param = {
      schedules: copySchedule,
      staffId: column.id,
      hourMinTo: shopTime[0].from,
      dateTo: shopTime[0].date,
      dateFrom: dt[0],
      hourMinFrom: dt[1],
    };

    if (param.hourMinTo > param.hourMinFrom) {
      // ask to shift appointments hours
      Modal.confirm({
        title: `${t('calendar.confirm_paste')}`,
        content: (
          <div>
            <p>
              {t('calendar.start_time')} <br></br>
              {t('calendar.move_appointment_time')}
            </p>
          </div>
        ),
        onOk() {
          moveMultiAppointment(
            param,
            diffHoursMin(param.hourMinTo, param.hourMinFrom),
          );
        },
      });
    } else {
      moveMultiAppointment(param, 0);
    }
    //console.log('schedules', param.schedules);
  };

  const scheduleTemplate = useCallback(
    (item) => {
      if (dailyView) return <ScheduleCard schedule={item} />;
      return <div style={{ background: schedules.color, height: '100%' }} />;
    },
    [dailyView],
  );

  const headerColumnTemplate = useCallback(
    (row, type) => {
      const columnSelection = (
        <Menu
          style={{ width: 256 }}
          mode="vertical"
          selectable={false}
          onClick={() => {
            hide(row.id);
          }}
          className="antd-menu-no-border"
        >
          {Object.keys(AllLeaveType).map((leaveType) => (
            <Menu.Item
              key={leaveType}
              className="text-uppercase text-center list"
              onClick={(e) => {
                openLeaveModal(leaveType, row);
              }}
            >
              {t(`calendar.${AllLeaveType[leaveType]}`)}
            </Menu.Item>
          ))}

          <Menu.Item
            key={'copyOperator'}
            className="text-uppercase text-center list"
            onClick={() => {
              copyOperator(row);
            }}
          >
            {t(`calendar.copy_appointments_operator`)}
          </Menu.Item>

          <Menu.Item
            key={'pasteOperator'}
            className="text-uppercase text-center list"
            onClick={() => {
              pasteOperator(row);
            }}
          >
            {t(`calendar.paste_appointments_operator`)}
          </Menu.Item>
        </Menu>
      );

      const hide = (id) => {
        setPopoverState((state) => ({ ...state, [id]: false }));
      };

      const handleOpenChange = (newOpen, id) => {
        setPopoverState((state) => ({ ...state, [id]: newOpen }));
      };

      return (
        <Popover
          className={
            'flex-column-center text-center h-100 cursor-pointer border-0 m-0'
          }
          content={columnSelection}
          trigger="click"
          open={popoverState[row.id] ?? false}
          onOpenChange={(newOpen) => handleOpenChange(newOpen, row.id)}
        >
          <Typography.Text className="text-uppercase noselect">
            {type === 'daily' ? row.title : row.title.substr(0, 2)}
          </Typography.Text>
        </Popover>
      );
    },
    [date, schedules, popoverState],
  );

  const onCellClick = useCallback((item) => {
    setAppointment(null);
    const startDate = moment(item.date).set({
      hour: item.hour,
      minute: item.minute,
      seconds: 0,
    });
    // Don't check for holiday
    // if(item.column.holidays.some(item => startDate.add({'second': 2}).isBetween(item.start_date, item.end_date))) { return; }
    setSelectedCell({
      staff: item.column,
      date: startDate,
      start_time: item.start_date.format('HH:mm'),
      end_time: item.end_date.format('HH:mm'),
    });
    setOpenModal(true);
  }, []);

  const onLeaveModalSubmit = (e) => {
    setLeaveModal(false);
    e.shouldRerender && fetchTodaySchedule(date);
    setModalData(null);
  };

  const closeLeaveModal = (e) => {
    setOpenModal(false);
    updateRouteParams(
      { scheduleModal: null },
      parseUrlAndSetParam({}),
      history,
    );
    if (e.shouldRerender) {
      fetchTodaySchedule(date);
    }
  };

  const onScheduleClick = useCallback(
    (e) => {
      setAppointment(e);
      updateRouteParams(
        { scheduleModal: e.id },
        parseUrlAndSetParam({}),
        history,
      );
      setSelectedCell({
        date: moment(e.start_date),
        staff: columns.filter((x) => e.staff_id === x.id)[0],
      });

      if (rszDrg.noclick) rszDrg.noclick = 0;
      else setOpenModal(true);
    },
    [columns],
  );

  // refresh this day
  const refreshCalendarDay = (e) => {
    const data = e.detail || date;
    fetchTodaySchedule(data);
  };

  const onScheduleDrop = (result) => {
    // Update New Schedule
    let agenda = schedules.filter(
      (item) => parseInt(result.draggableId) === parseInt(item.id),
    );
    if (!agenda.length) {
      return;
    }
    try {
      let newAgenda = Object.assign({}, JSON.parse(JSON.stringify(agenda[0])));
      delete newAgenda._options;
      const destinationData = result.destination.droppableId.split('-');
      const differenceMinute = Math.abs(
        moment(newAgenda.start_date).diff(newAgenda.end_date, 'minutes'),
      );
      const newDate = !dailyView
        ? moment(
            `${destinationData[2]}-${destinationData[3]}-${destinationData[4]}`,
          )
        : moment(newAgenda.start_date);

      let newStartDate = newDate.clone().set({
        hour: parseInt(destinationData[5]),
        minutes: parseInt(destinationData[6]),
      });
      let newEndDate = newStartDate.clone().add(differenceMinute, 'minutes');

      newAgenda.start_date = newStartDate.format('YYYY-MM-DD HH:mm');
      newAgenda.end_date = newEndDate.format('YYYY-MM-DD HH:mm');
      newAgenda.column_id = parseInt(destinationData[1]);
      newAgenda.staff_id = parseInt(destinationData[1]);

      // operator if different column
      if (newAgenda.column_id !== agenda[0].column_id) {
        newAgenda.operator = true;
      }

      dispatch(
        updateDraggedSchedule({
          new: { ...newAgenda },
          old: { ...agenda[0] },
        }),
      )
        .then((res) => fetchTodaySchedule(date))
        .catch((err) => logHandler.error(err));
    } catch (error) {
      logHandler.log(error);
    }
  };

  const headerTemplates = useMemo(() => {
    return {
      headerColumnTemplate: headerColumnTemplate,
      renderSchedule: scheduleTemplate,
    };
  }, [headerColumnTemplate, popoverState]);

  const height = window.innerHeight - 85;

  const handleStaffHolidayCreate = (data) => {
    let eventId = getEventFromStorage();

    let {
      startDate,
      endDate,
      start_hour = 0,
      start_minute = 15,
      end_hour = 23,
      end_minute = 45,
    } = data;

    if (!startDate || !endDate) {
      Modal.warning({
        title: 'Attenzione!',
        content: 'Selezionare la data di inizio e fine.',
      });

      return false;
    }

    startDate = moment(startDate).set({
      hour: start_hour,
      minute: start_minute,
    });
    endDate = moment(endDate).set({ hour: end_hour, minute: end_minute });

    const staffId = modalData.column.id;

    let isBetween = false,
      dfrom,
      dto,
      dcur;
    try {
      dfrom = new Date(moment(startDate).format('YYYY-MM-DDTHH:mm')).getTime();
      dto = new Date(moment(endDate).format('YYYY-MM-DDTHH:mm')).getTime();
      dcur = new Date(modalData.column.currentDate).getTime();

      isBetween = dcur >= dfrom && dcur <= dto;
    } catch (e) {
      setHolidayData(null);
      return false;
    }

    authRequest()
      .post(`/api/business/v1/${eventId}/agenda_appointments`, {
        agenda_type: 'holiday',
        staff_id: staffId,
        start_date: startDate.format('DD-MM-YYYY HH:mm'),
        end_date: endDate.format('DD-MM-YYYY HH:mm'),
      })
      .then((res) => {
        logHandler.log(res);
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
      })
      .catch((error) => {
        logHandler.log(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_CREATED);
      })
      .finally((err) => {
        setHolidayData(null);
        // reload page if in current date
        if (isBetween) refreshCalendarDay();
      });
  };

  return (
    <div className="wrapper-calendar-container">
      <CalendarHeader
        onDateChanged={onDateChanged}
        changeView={onViewChange}
        date={date}
        allowPrevDay={
          allowPrevDateThanToday
            ? true
            : !moment(date).isSameOrBefore(moment(), 'date')
        }
        dailyCalendar={dailyView}
        allStatusColors={colors}
      />

      <Spin spinning={loading} size="large" delay={500}>
        {dailyView ? (
          <DailyCalendar
            height={height}
            columns={columns}
            schedules={schedules}
            template={headerTemplates}
            dragAndDrop={true}
            date={date}
            theme={theme}
            dateOption={dateOption}
            onCellClick={onCellClick}
            onScheduleClick={onScheduleClick}
          />
        ) : (
          <WeeklyCalendar
            height={height}
            columns={columns}
            schedules={schedules}
            template={headerTemplates}
            dragAndDrop={false}
            theme={theme}
            dateOption={dateOption}
            onCellClick={onCellClick}
          />
        )}
      </Spin>
      {/* Modal */}
      <ErrorRenderHandler>
        <div className="calendar-modal">
          {openModal && (
            <ScheduleModal
              closeModal={closeLeaveModal}
              cancel={closeLeaveModal}
              open={openModal}
              staff={selectedCell.staff}
              appointment={appointment}
              dateOption={selectedCell}
            />
          )}

          {leaveModal && (
            <LeaveModal
              open={leaveModal}
              closeModal={(e) => onLeaveModalSubmit(e)}
              modalType={modalData}
            />
          )}

          {holidayData && (
            <Modal
              className="bg-light-yellow pb-0"
              open={holidayData !== null}
              onCancel={() => setHolidayData(null)}
              closable={true}
              footer={null}
              maskClosable={true}
              width={'800px'}
              centered
            >
              <Spin spinning={loading} delay={200}>
                <ExtraordinaryOpeningClosingCalendar
                  initialValue={modalData}
                  onSubmit={handleStaffHolidayCreate}
                />
              </Spin>
            </Modal>
          )}
        </div>
      </ErrorRenderHandler>
      {/* Modal End */}
      <div
        id="id_maskMenu"
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          opacity: '0.3',
          display: 'none',
          zIndex: 990,
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      ></div>
    </div>
  );
}
