import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function BackendRedirectionPage() {
    const { t } = useTranslation();
    
    useEffect(() => {
        window.location.href = `${process.env.REACT_APP_BACKEND_BASE_URL}`;
        return () => {}
    }, [])

    return (
        <div>
            <h1>{ t('common:redirect_to_backend') }</h1>
        </div>
    );
}

export default BackendRedirectionPage;