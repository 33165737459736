/**
 * Transformer for participant
 *
 * @param {Object} data List of data
 * @returns {Object}
 */
export const participantTransformerResource = (data) => {
  const customFields = getCustomFields(data.custom_fields);

  return {
    id: data.id,
    username: data?.username,
    password: data?.password,
    email: data?.email,
    name: data?.name,
    surname: data?.surname,
    job_title: data?.job_title,
    active: data?.active,
    avatar: data?.avatar,
    company: data?.company,
    ...customFields,
    // Hotfix: Older data have phone_number inside custom field whereas newer migration have it outside
    phone_number: data?.phone_number ?? customFields?.phone_number
  };
};

const getCustomFields = (fields) => {
  let r,
    key,
    field,
    keyFields = {};
  for (r = 0; r < fields.length; r++) {
    field = fields[r];
    key = field.key;
    if (key === 'accept_terms_and_conditions') key = 'terms_and_conditions';
    keyFields[key] = field.value;
  }
  return keyFields;
};

/**
 * Transform data for list of participant
 * @param {Array} data
 * @returns Array
 */
export const participantTransformerCollection = (data) => {
  return data.map((item) => participantTransformerResource(item));
};

// convert x api partecipant update
export const getApiFormatFromFormData = (data) => {
  return {
    participant: {
      name: data.name,
      surname: data.surname,
      username: data.username,
      password: data.password,
      email: data.email,
      phone_number: data.phone_number,
      custom_fields: [
        {
          key: 'address',
          value: data.address,
        },
        {
          key: 'postal_code',
          value: data.postal_code,
        },
        {
          key: 'date_of_birth',
          value: data.date_of_birth,
        },
        {
          key: 'phone_number',
          value: data.phone_number,
        },
        {
          key: 'notes',
          value: data.notes,
        },
        {
          key: 'accept_terms_and_conditions',
          value: data.terms_and_conditions ?? false,
        },
      ],
    },
  };
};
