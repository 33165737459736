import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorRenderHandler from '../components/calendar/handlers/ErrorRenderHandler';
import { withAlertMessagesContainer } from '../components/network/withOnlineMessage';
import BackendRedirectionPage from '../pages/BackendRedirectionPage';
import CalendarPage from '../pages/CalendarPage';
import ChatPage from '../pages/ChatPage';
import CommunicationPage from '../pages/CommunicationPage';
import CustomerRegisterPage from '../pages/CustomerRegisterPage';
import ReportPage from '../pages/ReportPage';

//import AppointmentConfirmationPage from '../pages/Frontend/AppointmentConfirmationPage';
//import AppointmentTimeSelectionPage from '../pages/Frontend/AppointmentTimeSelectionPage';
// import AuthPage from '../pages/Frontend/AuthPage';
import ChooseServicePage from '../pages/Frontend/ChooseServicePage';
import EmbedPage from '../pages/Frontend/EmbedPage';
import FrontendUserProfile from '../pages/Frontend/ProfilePage';
import ProfileReservationPage from '../pages/Frontend/ProfileReservationPage';

import HomePage from '../pages/HomePage';
import LogoutPage from '../pages/LogoutPage';
import NotificationPage from '../pages/NotificationPage';
import PageNotFound from '../pages/PageNotFound';
import ProfilePage from '../pages/ProfilePage';
import ServicePage from '../pages/ServicePage';
import ShopPage from '../pages/ShopTimePage';
import StaffPage from '../pages/StaffPage';
import { BackendPrivateRoute, FrontendPrivateRoute } from './RouteType';
import FeLogin from '../pages/Frontend/FeLogin';
import FeRegister from '../pages/Frontend/FeRegister';
import Hairdresser from '../pages/Frontend/HairdresserListPage';

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Routes = () => (
  <ErrorRenderHandler>
    <Switch>
      <BackendPrivateRoute exact path="/" component={HomePage} />

      <BackendPrivateRoute exact path="/agenda" component={CalendarPage} />
      <BackendPrivateRoute
        exact
        path="/customer-registry"
        component={CustomerRegisterPage}
      />
      <BackendPrivateRoute
        exact
        path="/communication"
        component={CommunicationPage}
      />
      <BackendPrivateRoute exact path="/chat" component={ChatPage} />
      <BackendPrivateRoute exact path="/report" component={ReportPage} />

      <BackendPrivateRoute exact path="/shop" component={ShopPage} />
      <BackendPrivateRoute exact path="/service" component={ServicePage} />
      <BackendPrivateRoute exact path="/staff" component={StaffPage} />
      <BackendPrivateRoute
        exact
        path="/notification"
        component={NotificationPage}
      />
      <BackendPrivateRoute exact path="/profile" component={ProfilePage} />
      <Route exact path="/logout" component={LogoutPage} />

      <Route exact path="/redirect" component={BackendRedirectionPage} />

      {/* Frontend Routes */}
      {/* <Route exact path="/login" component={AuthPage} /> */}
      <Route exact path="/login" component={FeLogin} />
      <Route exact path="/Register" component={FeRegister} />
      {/* <Route exact path="/hairdresser" component={Hairdresser} /> */}

      <Route exact path="/embed" component={EmbedPage} />

      <FrontendPrivateRoute
        // optional={true}
        exact
        path="/hairdresser"
        component={Hairdresser}
      />
      <FrontendPrivateRoute
        // optional={true}
        exact
        path={`/choose-service`}
        component={ChooseServicePage}
      />

      <FrontendPrivateRoute
        // optional={true}
        exact
        path="/user-profile"
        component={FrontendUserProfile}
      />
      <FrontendPrivateRoute
        // optional={true}
        exact
        path="/reservation"
        component={ProfileReservationPage}
      />

      {/*<FrontendPrivateRoute
        exact
        path="/time-selection"
        component={AppointmentTimeSelectionPage}
      />
      <FrontendPrivateRoute
        exact
        path="/appointment-confirmation/:id"
        component={AppointmentConfirmationPage}
      />*/}
      {/* ./ Frontend Routes */}

      <Route component={PageNotFound} />
    </Switch>
  </ErrorRenderHandler>
);

export default withAlertMessagesContainer(Routes);
