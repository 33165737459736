import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LANG_TYPES } from "./app/constants";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n

  .use(I18NextHttpBackend)

  .use(initReactI18next)

  .init({
    fallbackLng: LANG_TYPES['en'],
    ns: ['translation', 'common', 'error', 'frontend'],

    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;