import { useCallback, useEffect, useState } from 'react';
import OneSignalReact from 'react-onesignal';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AuthorizationLoading, Unauthorized } from '../components/index';
import {
  isLoggedIn,
  login,
  logout,
  userIsAuthenticating,
} from '../features/auth/authSlice';
import {
  fetchUserDetail,
  frontendLogin,
  frontendLogout,
  isFrontendLoggedIn,
} from '../features/frontend/frontendSlice';
import { logHandler } from '../handlers/LogHandler';
import { authRequest, validateTokenAndSetToStorage } from '../services/api';
import { setCurrentLanguage } from '../services/language';
import {
  getEventFromStorage,
  getFrontendTokenFromStorage,
  getTokenFromStorage,
} from '../services/token';

/**
 * Pass only authorized/authenticated routes
 *
 * @param {*} param0
 * @returns
 */
export const BackendPrivateRoute = ({ exact, component, path }) => {
  const auth = useSelector(isLoggedIn);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const authorizeUser = useCallback(
    async (token, eventId, validity) => {
      await dispatch(userIsAuthenticating());
      const validated = await validateTokenAndSetToStorage(
        token,
        eventId,
        validity,
      );
      await dispatch(validated ? login() : logout());
    },
    [dispatch],
  );

  useEffect(() => {
    if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
      OneSignalReact.init({
        allowLocalhostAsSecureOrigin: true,
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
      });
      logHandler.log('Initialized Push Notification');
      (async () => {
        let userID = await OneSignalReact.getUserId();
        try {
          await authRequest().post(
            `/api/business/v1/events/2/notifications/subscribe`,
            {
              user_one_signal_subscribed_id: userID,
            },
          );
        } catch (error) {
          console.log('Push notification user not sent');
        }
      })();
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      return;
    }
    const query = new URLSearchParams(window.location.search);
    let token = query.get('token');
    let eventId = query.get('event_id');
    let validity = query.get('expiration');

    if (token === null && eventId === null) {
      token = getTokenFromStorage();
      eventId = getEventFromStorage();
    }

    token && eventId && !auth && authorizeUser(token, eventId, validity);
    if (token === null || eventId === null) {
      dispatch(logout());
    }
  }, [auth, authorizeUser, dispatch]);

  if (loading) {
    return <Route path={path} exact={exact} component={AuthorizationLoading} />;
  }

  const ele = auth === true ? component : Unauthorized;

  return <Route path={path} exact={exact} component={ele} />;
};

/**
 * Pass only authorized/authenticated routes
 *
 * @param {*} param0
 * @returns
 */
export const FrontendPrivateRoute = ({
  exact,
  component,
  path,
  optional = false,
}) => {
  const auth = useSelector(isFrontendLoggedIn);
  const fetchingData = useSelector((state) => state.frontend.fetchingData);
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const authorizeUser = useCallback(async () => {
    await dispatch(fetchUserDetail()).then((res) => {
      dispatch(res.payload?.id ? frontendLogin() : frontendLogout());
    });
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 400);
  }, []);

  useEffect(() => {
    if (auth) {
      return;
    }
    setCurrentLanguage(`${localStorage.getItem('lang')}`);

    const query = new URLSearchParams(window.location.search);
    let token = query.get('token');
    let eventId = query.get('event_id');

    if (token === null && eventId === null) {
      token = getFrontendTokenFromStorage();
      eventId = getEventFromStorage();
    }

    token && eventId && !auth && authorizeUser();
    if (token === null && eventId === null) {
      dispatch(frontendLogout());
    }
  }, [auth, authorizeUser, dispatch]);

  const loading = Array.isArray(fetchingData)
    ? fetchingData.includes('fetchUserDetail')
    : true;

  if (optional) {
    return <Route path={path} exact={exact} component={component} />;
  }

  if (firstLoad || (loading && !optional)) {
    return <Route path={path} exact={exact} component={AuthorizationLoading} />;
  }

  if (!auth) {
    return <Redirect to={'/login'} />;
  }

  return <Route path={path} exact={exact} component={component} />;
};
