import { sortById } from '../components/util/Helper';

/**
 * Transforms the list of staff
 *
 * @param {Array} staffs List of staff to transform
 * @returns {Array}
 */
export const calendarStaffTransformerCollection = (staffs) => {
  return staffs
    .map((item) => {
      if (item.name) {
        item.title = item.name;
      }
      // Put staff break in holiday. So that the calendar renders it as
      // not available time
      let holidays = item.holidays ?? [];
      item.dates.forEach((currItem) => {
        // Generating holiday before start time and after endtime for calendar
        let newHolidays = [
          {
            id: `generated-holiday-${currItem.break_start}-start-break`,
            start_date: `${currItem.date} 00:00`,
            end_date: `${currItem.date} ${currItem.from}`,
          },
          {
            id: `generated-holiday-${currItem.break_start}-end-break`,
            start_date: `${currItem.date} ${currItem.to}`,
            end_date: `${currItem.date} 23:59`,
          },
        ];
        holidays = [...holidays, ...newHolidays];
        if (!currItem.break_start.length) {
          return;
        }
        holidays = [
          ...holidays,
          {
            id: `generated-holiday-${currItem.break_start}`,
            start_date: `${currItem.date} ${currItem.break_start}`,
            end_date: `${currItem.date} ${currItem.break_end}`,
          },
        ];
      });
      item.holidays = holidays;
      return item;
    })
    .sort(sortById);
};

/**
 * Transforms the list of schedules
 *
 * @param {Array} schedules List of staff to transform
 * @returns {Array}
 */
export const scheduleTransformerCollection = (schedules) => {
  const transformedSchedules = schedules
    .map((item) => {
      item.column_id = item.hasOwnProperty('staff_id')
        ? item.staff_id
        : item.staff_id || item.staff?.id;
      item.title = `${item.participant.name} ${item.participant.surname}`;
      return item;
    })
    .reduce((carry, item) => {
      if (!carry[item.staff_id]) carry[item.staff_id] = [];
      carry[item.staff_id].forEach((schedule) => {
        //   Add overlapping ids to overlaps attribute
        const currentStartTimestamp = +new Date(item.start_date);
        const scheduleEndTimestamp = +new Date(schedule.end_date);
        const scheduleStartTimestamp = +new Date(schedule.start_date);
        if (
          (scheduleEndTimestamp > currentStartTimestamp &&
            scheduleStartTimestamp < currentStartTimestamp) ||
          scheduleStartTimestamp === currentStartTimestamp
        ) {
          if (!item._overlaps) {
            item._overlaps = schedule?._options?.overlaps
              ? schedule?._options.overlaps
              : [];
          }
          item._overlaps.push(schedule.id);
        }
      });
      carry[item.staff_id].push(item);
      return carry;
    }, {});

  return Object.values(transformedSchedules)
    .flatMap((i) => i)
    .sort(sortById);
};
