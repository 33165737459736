import { ConfigProvider } from 'antd';
import en_US from "antd/lib/locale/en_US";
import it_IT from "antd/lib/locale/it_IT";
import moment from 'moment';
import 'moment/locale/it';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.less';
import Routes from './routes/Routes';
import { getCurrentLanguage, setCurrentLanguage } from './services/language';


function App() {

  const { t } = useTranslation(); 
  // Set system language and moment locale
  useEffect(() => { 
    let lang = setCurrentLanguage(null);
    moment.locale(lang === 'en' ? 'en' : 'it');
  }, [])

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t("error:validation.required"),
    types: {
      email: t("error:validation.types.email"),
      number: t("error:validation.types.number"),
    },
    number: {
      range: t("error:validation.number.range"),
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const getLocaleForAntd = () => {
    return getCurrentLanguage() === 'en' ? en_US : it_IT;
  }

  return (
    <ConfigProvider locale={getLocaleForAntd()} form={{validateMessages}}>
      <div className="App" data-testid='app'>
        <Router>
            <Routes />
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
