import PropTypes from 'prop-types';
import {
  Form,
  Card,
  Input,
  Space,
  Typography,
  Col,
  Row,
  Button,
  Spin,
  Modal,
} from 'antd';
import TrashIcon from '../icons/TrashIcon.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StaffWeeklyTimeForm from '../form/StaffWeeklyTimeForm';
import ExtraordinaryOpeningClosingCalendar from '../form/ExtraordinaryOpeningClosingCalendar';
import moment from 'moment';
import { sortById } from '../util/Helper';
import { EditIcon } from '..';
import CancelModal from '../modal/CancelModal';

function StaffDetail({
  staff,
  holidays,
  onDelete,
  onUpdate,
  onStaffHolidayCreate,
  onStaffHolidayUpdate,
  onStaffHolidayDelete,
}) {
  const [form] = Form.useForm();
  const [isWeeklyModalOpen, setIsWeeklyModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [holidayData, setHolidayData] = useState(null);
  const [cancModal, setCancModal] = useState(false);
  const [cancModal2, setCancModal2] = useState(false);

  const { t } = useTranslation();

  const onFinish = (values) =>
    typeof onUpdate === 'function' &&
    onUpdate({ ...values, time_attributes: staff.time_attributes });

  const updateStaffWeeklySchedule = (data) => {
    let apiFormat = { ...staff };
    apiFormat.time_attributes = staff.staff_times.map((x) => {
      delete x.day;
      delete data.day;
      return x.id === data.id ? data : x;
    });
    setLoading(true);
    typeof onUpdate === 'function' &&
      onUpdate(apiFormat, () => {
        setLoading(false);
      });
  };

  const handleStaffHolidaySubmit = (data) => {
    setLoading(true);
    if (holidayData.id) {
      typeof onStaffHolidayUpdate === 'function' &&
        onStaffHolidayUpdate({ ...holidayData, ...data }, () => {
          setLoading(false);
          setHolidayData(null);
        });
    } else {
      typeof onStaffHolidayCreate === 'function' &&
        onStaffHolidayCreate(data, () => {
          setLoading(false);
          setHolidayData(null);
        });
      if (typeof onStaffHolidayCreate !== 'function') setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(staff);
  }, [form, staff]);

  const deleteStaff = () => typeof onDelete === 'function' && onDelete(staff);

  const initialValue = holidayData?.id
    ? {
        startDate: holidayData
          ? moment(holidayData.start_date).format('YYYY-MM-DD')
          : '',
        endDate: holidayData
          ? moment(holidayData.end_date).format('YYYY-MM-DD')
          : '',
        start_hour: holidayData
          ? parseInt(moment(holidayData.start_date).format('HH'))
          : '',
        start_minute: holidayData
          ? parseInt(moment(holidayData.start_date).format('mm'))
          : '',
        end_hour: holidayData
          ? parseInt(moment(holidayData.end_date).format('HH'))
          : '',
        end_minute: holidayData
          ? parseInt(moment(holidayData.end_date).format('mm'))
          : '',
      }
    : null;

  return (
    <div>
      <div className="py-2 my-2" />

      <div className="d-flex justify-content-between pb-4">
        <span></span>
        <Typography.Title
          level={4}
          data-testid="staff_name"
          className="d-flex text-bold flex-column align-items-center justify-content-center"
        >{`${staff.name} ${staff.surname}`}</Typography.Title>
        <span>
          <CancelModal
            title={t('calendar.card.title_confirm')}
            open={cancModal}
            closeModal={() => setCancModal(false)}
            content={t('staffpage.delete_confirmation_title')}
            handleOK={() => {
              setCancModal(false);
              deleteStaff();
            }}
          />
          <Button
            data-testid="deletebutton"
            className="secondary-button-outline"
            onClick={() => {
              setCancModal(true);
            }}
          >
            <img
              src={TrashIcon}
              alt="Delete"
              className="pr-2"
              style={{ height: '16px', marginTop: '-2px' }}
            />
            <span className="font-12">{t('common:delete')}</span>
          </Button>
        </span>
      </div>

      <Card>
        <Typography.Paragraph className="my-0 theme-black-text text-bold py-0">
          {t('common:personal_data')}
        </Typography.Paragraph>
        <div className="w-100 mt-2 py-2"></div>
        <Form form={form} onFinish={onFinish}>
          <Space>
            <Form.Item name="name" rules={[{ required: true }]}>
              <Input placeholder={'Nome'} autoComplete={'off'} />
            </Form.Item>
            <Form.Item name="surname" rules={[{ required: true }]}>
              <Input placeholder={'Cognome'} autoComplete={'off'} />
            </Form.Item>
            <Form.Item name="phone" rules={[{ required: true }]}>
              <Input placeholder={'Telefono'} autoComplete={'off'} />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input type="email" placeholder={'Email'} autoComplete={'off'} />
            </Form.Item>
          </Space>
          <Form.Item span={24}>
            <Button
              htmlType={'submit'}
              data-testid="updatebutton"
              className="secondary-button-outline"
            >
              {t('staffpage.button.update')}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <div className="py-2 my-2" />

      <Card>
        <Row>
          <Col span={12}>
            <Typography.Text
              data-testid="staff_workinghour"
              className="text-bold text-uppercase"
            >
              {t('common:timetable')}
            </Typography.Text>
            <div className="py-2 my-1"></div>
            <div className="d-flex flex-column">
              {staff.staff_times.length &&
                staff.staff_times.sort(sortById).map((day) => (
                  <div className="d-flex py-2" key={day.id}>
                    <div style={{ minWidth: '40%' }}>
                      <Typography.Paragraph className="py-0 text-start my-0">
                        {day.day}
                      </Typography.Paragraph>
                    </div>
                    <div className="px-2"></div>
                    <Typography.Text>
                      {day.closed ? '-' : `${day.from}-${day.to}`}
                    </Typography.Text>
                  </div>
                ))}
            </div>
            {/* TODO: Implement this after staff time has been done */}
            <Button
              className="secondary-button-outline"
              onClick={() => setIsWeeklyModalOpen(true)}
              style={{ marginTop: '20px' }}
            >
              {t('staffpage.timetable.button')}
            </Button>
          </Col>

          <Col span={12} className="border-left">
            <div className="d-flex flex-column">
              <Typography.Text
                className="text-bold"
                data-testid="staff_holidays"
              >
                {t('common:holidays_and_permits')}
              </Typography.Text>

              <div className="py-2 my-1"></div>

              <div className="d-flex flex-column px-4">
                {holidays &&
                  !!holidays.length &&
                  holidays.map((day) => (
                    <div
                      className="d-flex py-2"
                      key={`${staff.id}-holiday-${day.id}`}
                    >
                      <div className="flex-column-center" style={{ flex: 3 }}>
                        <Typography.Paragraph className="py-0 text-start my-0">
                          Da {moment(day.start_date).format('ddd')}{' '}
                          {moment(day.start_date).format('DD/MM/YY')} a{' '}
                          {moment(day.end_date).format('ddd')}{' '}
                          {moment(day.end_date).format('DD/MM/YY')} dalle{' '}
                          {moment(day.start_date).format('HH:mm')} alle{' '}
                          {moment(day.end_date).format('HH:mm')}
                        </Typography.Paragraph>
                      </div>

                      <div
                        style={{
                          width: '100px !important',
                        }}
                      >
                        <Button
                          className="secondary-button-outline"
                          style={{ margin: '4px' }}
                          onClick={() => setHolidayData(day)}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{ width: 16, transform: 'scale(1.5)' }}
                          />
                        </Button>

                        {cancModal2 && (
                          <CancelModal
                            title={t('calendar.card.title_confirm')}
                            open={cancModal2}
                            closeModal={() => setCancModal2(false)}
                            content={t('servicepage.delete_confirmation_title')}
                            handleOK={() => {
                              setCancModal2(false);
                              onStaffHolidayDelete(day);
                            }}
                          />
                        )}
                        <Button
                          className="secondary-button-outline"
                          onClick={() => {
                            setCancModal2(true);
                          }}
                        >
                          <img src={TrashIcon} alt="" />
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <Button
                  className="secondary-button-outline"
                  onClick={() => setHolidayData({})}
                >
                  {t('staffpage.holiday.button')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      {holidayData !== null && (
        <Modal
          className="bg-light-yellow pb-0"
          open={holidayData !== null}
          onCancel={() => setHolidayData(null)}
          closable={true}
          footer={null}
          maskClosable={true}
          width={'800px'}
          centered
        >
          <Spin spinning={loading} delay={200}>
            <ExtraordinaryOpeningClosingCalendar
              initialValue={initialValue}
              onSubmit={handleStaffHolidaySubmit}
            />
          </Spin>
        </Modal>
      )}

      <Modal
        className="bg-light-yellow pb-0"
        open={isWeeklyModalOpen}
        onCancel={() => setIsWeeklyModalOpen(false)}
        closable={true}
        footer={null}
        centered
        maskClosable={true}
        width={'800px'}
      >
        <Spin spinning={loading} delay={300}>
          <StaffWeeklyTimeForm
            onSubmit={updateStaffWeeklySchedule}
            weekData={staff.staff_times}
          />
        </Spin>
      </Modal>
    </div>
  );
}

StaffDetail.propTypes = {
  staff: PropTypes.object.isRequired,
  holidays: PropTypes.array,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  handleStaffHolidayCreate: PropTypes.func,
  handleStaffHolidayUpdate: PropTypes.func,
};

export default StaffDetail;
