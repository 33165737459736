export default function TemplateRender({
  template = null,
  item,
  type = null,
  children,
}) {
  if (typeof template === 'function') {
    return template(item, type);
  }

  return children;
}
