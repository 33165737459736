import React from "react";
import TemplateRender from "../../util/TemplateRender";
import "./header.less"
import { useCalendarTheme } from "../../theme";
import PropTypes from 'prop-types';
import moment from "moment";


function WeeklyCalendarHeader({ columns, columnRef, allDays = [] }) {
    const {computedStyles, theme: CalendarTheme, template} = useCalendarTheme();

    return (
        <div className="calendar-header flex-row" style={{ height: CalendarTheme.header.height * 2 }} data-testid="calendar-header">
          <div className="calendar-hours-header" style={computedStyles.calendarHourHeaderStyle}></div>
          <div className="overflow-x-scroll flex-row w-100" id='calendarscrollLeft' ref={columnRef} style={computedStyles.calendarHeaderColumnContainerStyle}>
            {allDays.map(day => (
                <div key={`weekly-column-${day}`} className="calendar-day d-flex flex-column">
                    <p className="column-day text-uppercase text-center">{moment(day).format('ddd')}</p>
                    <div className="calendar-columns flex-row border-top border-light flex-1">
                    {!!columns.length && columns.map(column => (
                        <div className="calendar-column calendar-column-header" key={`header-column-${column.id}-${day}`} style={computedStyles.calendarColumnHeaderStyle}>
                            <TemplateRender template={template.headerColumnTemplate || null} type={"weekly"} item={{...column, ...{currentDate: day}}}>
                                <div className="flex-column-center h-100">
                                <p className="m-0 text-center">{column.title}</p>
                                </div>
                            </TemplateRender>
                        </div>
                    ))}
                    </div>
                </div>
            ))}
          </div>
        </div>
    );
}


WeeklyCalendarHeader.propTypes = {
    template: PropTypes.object,
    columnRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.object })
    ]),
    columns: PropTypes.array.isRequired
}


export default React.memo(WeeklyCalendarHeader);