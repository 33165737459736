import { notification } from 'antd';
import { t } from 'i18next';
import React from 'react';

const openNotificationWithIcon = (status) => {
    notification[status ? 'success' : "error"]({
      message: status ? t("notification.app_online_title") : t("notification.app_offline_title"),
      description: status ? t("notification.app_online_desc") : t("notification.app_offline_desc"),
    });
};

export const withAlertMessagesContainer = WrappedComponent => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.online.bind(this);
            this.offline.bind(this);
        }

      componentDidMount() {
        window.addEventListener("online", this.online);
        window.addEventListener("offline", this.offline);
      }

      online() {
        openNotificationWithIcon(true);
      }

      offline() {
        openNotificationWithIcon(false);
      }

      componentWillUnmount() {
        window.removeEventListener("online", this.online);
        window.removeEventListener("offline", this.offline);
      }


      render() {
        return (
          <React.Fragment>
            <WrappedComponent />
          </React.Fragment>
        )
      }
    }
};