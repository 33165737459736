import PropTypes from 'prop-types';
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useCalendarTheme } from "../../theme";
import "./body.less";
import CalendarBodyLayer from "./layer";

function WeeklyCalendarBody({
    hours = [],
    allDays = [],
    hourRef,
    columns,

    onCellClick,
}) {
    const {computedStyles, theme, dateOption } = useCalendarTheme();

    const supportsTouch = (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0))

    const touchOptions = {
      delayTouchStart: 20,
      delay: 20
    }

    // const handleDragEnd = useCallback((result) => { 
    //     if(!dragAndDrop) return;
    //     if (result.reason === "DROP") {
    //       if(result.destination === null) return;
    //       typeof scheduleDrop === 'function' && scheduleDrop(result, result.draggableId);
    //     }
    //   }, [dragAndDrop, scheduleDrop]);

    return (
        <div className="calendar-body position-relative flex-row" style={computedStyles.calendarBodyHeightStyle}  data-testid="calendar-body">
          <div className="calendar-hours h-100" id='calendarscrollTop' ref={hourRef} style={computedStyles.calendarScrollerStyle} data-testid="calendar-hour-sidebar">
              {hours.map(hour => (
                <div className="hour sidebar-hour-text" key={`hour-${dateOption.date.format('YYYY-MM-DD')}-${hour}`} style={{ height: theme.sidebar.hourHeight }}>
                  <p className="m-0 sidebar-hour-text" style={computedStyles.calendarSidebarHourHeight}>
                    {hour}:00
                  </p>

                  <p className="m-0 sidebar-hour-text-light">
                    {hour}:30 
                  </p>
                </div>
              ))}
          </div>


          <div className="calendar-container position-relative flex-1 w-100" data-testid="calendar-agenda-columns">
          <DndProvider backend={supportsTouch ? TouchBackend : HTML5Backend} options={ supportsTouch ? touchOptions : {}  }>
            <div className="overflow-scroll h-100 flex-row" id='calendarscroller'>
                {allDays.map(day => (
                    <div key={`weekly-agenda-${day}`} className="calendar-cell-background h-100 flex-row">
                    {!!columns.length && columns.map(column => (
                        <CalendarBodyLayer
                            key={`wcalendar-column-${day}-${column.id}`}

                            hours={hours}
                            column={column} 
                            onCellClick={onCellClick}
                            currentDate={day}
                        />
                    ))}
                    </div>
                ))}

            </div>
            </DndProvider>
          </div>
        </div>
    );
}


WeeklyCalendarBody.propTypes = {
    columns: PropTypes.array.isRequired,
    hourRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.object })
    ]),
    hours: PropTypes.array.isRequired,
    allDays: PropTypes.array.isRequired,
    onCellClick: PropTypes.func,
}


export default WeeklyCalendarBody;