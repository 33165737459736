import { Modal, notification } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NOTIFICATION_CREATED } from '../../app/constants';
import { parseApiResponseAndSendNotification } from '../../handlers/ApiErrorNotifier';
import { logHandler } from '../../handlers/LogHandler';
import { authRequest } from '../../services/api';
import { getEventFromStorage } from '../../services/token';
import ForzaOrarioModal from './Forza/ForzaOrarioModal';
import GeneralLeave from './LeaveModal/GeneralLeave';

function LeaveModal({ open = false, modalType, closeModal }) {
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const applyStaffLeave = async (values) => {
    const { column, modalType: type } = modalType;
    const eventId = getEventFromStorage();
    let staffId = column.id;
    const leaveType = {
      permit: 'leave',
      lunch_break: 'lunch_time',
      meeting: 'meeting',
      commercial_appointment: 'commercial_appointment',
      formation: 'training',
      recovery: 'time_recovery',
      illness: 'sickness',
      other_salon: 'other_salon',
      overbooking: 'overbooking',
    };

    const DATE_FORMAT = 'DD-MM-YYYY HH:mm';
    let date = column.currentDate ? column.currentDate : moment();
    const requestData = {
      agenda_type: 'staff_leave',
      leave_type: leaveType[type],
      staff_id: staffId,
      start_date: moment(date)
        .set({ hour: values.startHour, minute: values.startMinute })
        .format(DATE_FORMAT),
      end_date: moment(date)
        .set({ hour: values.endHour, minute: values.endMinute })
        .format(DATE_FORMAT),
    };
    setLoading(true);
    try {
      await authRequest().post(
        `/api/business/v1/${eventId}/agenda_appointments`,
        requestData,
      );
      notification['success']({
        message: 'Dati aggiornati con successo',
        description: 'Richiesta di permesso creata',
      });
      closeModal({ shouldRerender: true });
    } catch (error) {
      parseApiResponseAndSendNotification(error.response, NOTIFICATION_CREATED);
      logHandler.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const column = modalType ? modalType.column : {};
    if (modalType === null || modalType.modalType === null) return;
    const leaveType = {
      permit: 'PERMESSO PER',
      holiday: 'FERIE PER',
      lunch_break: 'PAUSA PRANZO PER',
      meeting: 'RIUNIONE PER',
      illness: 'MALATTIA PER',
      commercial_appointment: 'COMMERCIAL PER',
      formation: 'FORMAZIONE PER',
      recovery: 'RECUPERO PER',
      other_salon: 'Altro-Salone PER',
      overbooking: 'OVERBOOKING PER',
    };
    setTitle(`${leaveType[modalType.modalType]} ${column.title}`);
  }, [modalType?.modalType, modalType?.column, modalType]);

  const applyHourlyLeave = async (values) => {
    // TODO: Implement forza orario leave
    const { column } = modalType;
    let date = column.currentDate ? column.currentDate : moment();

    const eventId = getEventFromStorage();
    let staffId = column.id;
    setLoading(true);
    const DATE_FORMAT = 'YYYY-MM-DD';
    const TIME_FORMAT = 'HH:mm';
    try {
      const requestData =
        values.type === 'reset'
          ? {
              reset: true,
              date: moment(date).format(DATE_FORMAT),
            }
          : {
              from: moment(date)
                .set({ hour: values.startHour, minute: values.startMinute })
                .format(TIME_FORMAT),
              to: moment(date)
                .set({ hour: values.endHour, minute: values.endMinute })
                .format(TIME_FORMAT),
              break_start: 0,
              break_end: 0,
              date: moment(date)
                .set({ hour: values.endHour, minute: values.endMinute })
                .format(DATE_FORMAT),
            };

      await authRequest().put(
        `/api/business/v1/events/${eventId}/staffs/${staffId}/staff_time`,
        requestData,
      );
      closeModal({ shouldRerender: true });
    } catch (error) {
      parseApiResponseAndSendNotification(error.response, NOTIFICATION_CREATED);
      logHandler.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    let timeout = setTimeout(() => setLoading(false), 600);
    return () => clearTimeout(timeout);
  }, [modalType?.modalType, title]);

  const resetStaffLeave = async () => {
    setLoading(true);
    try {
      const allLeaveTypes = {
        permit: 'leave',
        lunch_break: 'lunch_time',
        meeting: 'meeting',
        commercial_appointment: 'commercial_appointment',
        formation: 'training',
        recovery: 'time_recovery',
        illness: 'sickness',
        other_salon: 'other_salon',
        overbooking: 'overbooking',
      };
      const { column } = modalType;
      let date = column.currentDate ? column.currentDate : moment();
      const DATE_FORMAT = 'YYYY-MM-DD';
      const eventId = getEventFromStorage();
      let staffId = column.id;

      const response = await authRequest().put(
        `/api/business/v1/events/${eventId}/staffs/${staffId}/reset_leave`,
        {
          date: moment(date).format(DATE_FORMAT),
          leave_type: allLeaveTypes[modalType.modalType] || modalType.modalType,
        },
      );

      if (response?.data?.message) {
        notification['success']({
          message: response?.data?.message,
        });
      }
      closeModal({ shouldRerender: true });
    } catch (error) {
      parseApiResponseAndSendNotification(error, NOTIFICATION_CREATED);
      logHandler.error(error);
    }
    setLoading(false);
  };

  const isForzaModal = modalType.modalType === 'time_strength_of_day';
  return (
    <Modal
      open={open}
      onCancel={closeModal}
      closable={true}
      footer={null}
      maskClosable={true}
      width={'800px'}
    >
      {isForzaModal && (
        <ForzaOrarioModal
          loading={loading}
          closeModal={closeModal}
          staff={modalType.column}
          onSubmit={applyHourlyLeave}
        />
      )}

      {!isForzaModal && (
        <GeneralLeave
          loading={loading}
          initialValue={{
            startHour: 1,
            startMinute: 15,
            endHour: 10,
            endMinute: 15,
          }}
          title={title}
          onSubmit={applyStaffLeave}
          onhandleReset={resetStaffLeave}
          type={modalType.modalType}
        />
      )}
    </Modal>
  );
}

LeaveModal.propTypes = {
  open: PropTypes.bool,
  modalType: PropTypes.object,
  closeModal: PropTypes.func,
};

export default LeaveModal;
