export const DefaultTheme = {
  header: {
    height: 40,
    columnWidth: 240,
    columnBgColor: '#d8cacc',
  },
  sidebar: {
    hourWidth: 60,
    hourHeight: 120,
    hourBgColor: '#4A4E69',
    hourTextColor: '#fff',
  },
  body: {
    cellHeight: 40,
    cellBorder: 30,
    cellHoverColor: '#e3e3e3',
  },
  subCellNumber: 4,
};

export const WeeklyDefaultTheme = {
  header: {
    height: 40,
    columnWidth: 40,
    columnBgColor: '#d8cacc',
  },
  sidebar: {
    hourWidth: 60,
    hourHeight: 120,
    hourBgColor: '#4A4E69',
    hourTextColor: '#fff',
  },
  body: {
    cellHeight: 40,
    cellBorder: 30,
    cellHoverColor: '#e3e3e3',
  },
  subCellNumber: 4,
};

export const DefaultTemplates = {
  headerColumnTemplate: null,
  renderSchedule: null,
};

export const DefaultOpeningHours = {
  start: 0,
  end: 23,
  date: new Date().toJSON().substr(0, 10),
};
