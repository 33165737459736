import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationCount, selectUnreadCount } from "./notificationSlice";

function NotificationIcon() {
    const unreadCount = useSelector(selectUnreadCount);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchNotificationCount());
        let notificationCount = setInterval(() => {
            dispatch(fetchNotificationCount());
        }, 12000)
        return () => clearInterval(notificationCount);
    }, [dispatch])
    
    return (
        <div style={{background: '#FFE3E3', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 50}}>
            <div className="position-relative" style={{position: 'relative'}}>
                {!!unreadCount && (
                    <span style={{
                        position: 'absolute',
                        top: '-4px',
                        right: '-6px',
                        fontSize: '8px',
                        background: 'red',
                        color: '#fff',
                        borderRadius: '50%',
                        width: '12px',
                        height: '12px',
                        verticalAlign: 'middle',
                        textAlign: 'center'
                    }}>{unreadCount}</span>
                )}
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        style={{fill: unreadCount ? '#FF3D00' : "#000" }} 
                        d="M9.99925 20.625C9.21086 20.625 8.57068 20.0071 8.57068 19.2487H7.1421C7.1421 20.7659 8.42425 22 9.99925 22C11.5743 22 12.8564 20.7659 12.8564 19.2487H11.4278C11.4278 20.0071 10.7876 20.625 9.99925 20.625ZM19.3466 14.3864C18.0984 13.2425 17.1439 12.0454 17.1439 7.98789C17.1439 4.56801 14.314 1.77891 10.7135 1.4416V0.6875C10.7135 0.307656 10.3939 0 9.99925 0C9.60461 0 9.28496 0.307656 9.28496 0.6875V1.44203C5.68451 1.77934 2.8546 4.56801 2.8546 7.98789C2.8546 12.045 1.89969 13.2425 0.65147 14.3864C0.0278085 14.9579 -0.169067 15.822 0.149237 16.5877C0.474238 17.3697 1.25638 17.875 2.1421 17.875H17.8564C18.7421 17.875 19.5243 17.3693 19.8493 16.5872C20.1676 15.8215 19.9702 14.9579 19.3466 14.3864ZM17.8564 16.5H2.1421C1.50683 16.5 1.18942 15.7923 1.63674 15.3824C3.19299 13.9562 4.28317 12.36 4.28317 7.98832C4.28317 5.09309 6.83987 2.75 9.99925 2.75C13.1582 2.75 15.7153 5.09266 15.7153 7.98789C15.7153 12.3428 16.7961 13.9477 18.3618 15.382C18.8109 15.794 18.489 16.5 17.8564 16.5Z" fill="black"/>
                </svg>
            </div>
        </div>
    );
}


export default NotificationIcon;