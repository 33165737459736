/**
 * Transformer for staff
 * 
 * @param {Array} data List of data
 * @returns {Array}
 */
export const staffTransformerCollection = (staffs) => {
  return staffs.map(data => ({
    "id": data.id,
    "name": data?.name,
    "surname": data?.surname,
    "email": data?.email,
    "title": data?.title,
    "avatar": data?.image?.square,
    "image": {
      "square": data?.image?.square,
      "square2x": data?.image?.square2x
    },
    "phone": data?.phone,
  }));
}


/**
 * Staff time collection transform
 * @param {Array} times 
 * @returns Array
 */
const staffTimeCollectionTransformer = (times) => {
  return times
  .filter(i => typeof i.day === 'string')
  .map(item => ({
    "id": item.id,
    "leave": item.leave,
    "day": item.day,
    "from": item.from,
    "to": item.to,
    "break_start": item.break_start,
    "break_end": item.break_end,
  }));
}


export const staffTransformerResource = (data) => {
  return {
    "id": data.id,
    "name": data?.name,
    "surname": data?.surname,
    "email": data?.email,
    "title": data?.title,
    "avatar": data?.image?.square,
    "image": {
      "square": data?.image?.square,
      "square2x": data?.image?.square2x
    },
    "phone": data?.phone,
    "staff_times": staffTimeCollectionTransformer(data.staff_times)
  };
}

