import { Typography } from 'antd';
import React, { Fragment } from 'react';
//import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './ReviewConfirm.less';
import calendarSvg from './calendar_book.svg';
import './ServiceList.less';
import { t } from 'i18next';

export default function ReviewConfirmBook({
  services,
  selectedService,
  selectedStaff,
  selectedTime,
  staffAllOptions,
  getServiceTotal,
}) {
  return (
    <div className="wrapperReview">
      <div style={{ marginBottom: '16px' }} className="titleGroup">
        {t('calendar.date_hour')}:
      </div>
      <div className="card-review">
        <img src={calendarSvg} className="imgCalendar" alt="Icon Calendario" />
        <div className="textDateFormat">
          {moment(selectedTime.date).format('dddd DD MMMM YYYY')}
        </div>
        <div>{selectedTime.from + ' - ' + selectedTime.to}</div>
      </div>

      <div style={{ marginTop: '28px' }} className="titleGroup">
        {t('calendar.requested_service')}:
      </div>
      {services &&
        services.map((item) => {
          if (!selectedService.includes(item.id)) return null;

          return (
            <Fragment key={`service-${item.id}`}>
              <div
                className={'classCard booked-service'}
                style={{ textAlign: 'left' }}
              >
                <div
                  className="classCardImg"
                  style={{
                    backgroundImage: `url(${item.image_url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    width: '140px',
                    float: 'left',
                    marginRight: '10px',
                  }}
                ></div>

                <div className="service-list">
                  <Typography.Title className="noselect title">
                    {item.title}
                  </Typography.Title>
                  <Typography.Text className="noselect duration">
                    {item.readableDuration}
                  </Typography.Text>

                  <div className="operator">
                    <div>
                      <span className="labelOperator">
                        {t('calendar.operator')}:{' '}
                      </span>
                      {staffAllOptions[selectedStaff[item.id]] || '-'}
                    </div>
                  </div>

                  <Typography.Paragraph className="noselect description">
                    {item.description}
                  </Typography.Paragraph>

                  <Typography.Text className="noselect price">
                    {item.price}€
                  </Typography.Text>
                </div>
              </div>
            </Fragment>
          );
        })}
      <div className="totalPrice">
        {t('calendar.total')}: € {getServiceTotal()}
      </div>
    </div>
  );
}
