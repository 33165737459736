import PropTypes from 'prop-types';
import { Card, Input, Table, Typography, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  filterDateFromKeyword,
  sortByKey,
  dateToItaFormat,
} from '../util/Helper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logHandler } from '../../handlers/LogHandler';
import { TrashIcon } from '..';
import CancelModal from '../modal/CancelModal';

const { Column } = Table;

function ExtraordinaryTimeList({ allTime, onDelete, title }) {
  const [keyword, setKeyword] = useState('');
  const [timeLists, setTimeLists] = useState([]);
  const [cancModal, setCancModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (keyword.length) {
      try {
        let lists = filterDateFromKeyword(allTime, keyword, ['date']);
        lists = sortByKey(lists, 'date');
        setTimeLists(lists);
        return;
      } catch (error) {
        logHandler.error(error);
        return;
      }
    }

    setTimeLists(allTime);
  }, [keyword, allTime]);

  const onSearch = (e) => setKeyword(e.target.value);
  const handleDelete = (row) => typeof onDelete === 'function' && onDelete(row);

  return (
    <Card>
      <div className="d-flex justify-content-between pb-4">
        <span></span>
        <Typography.Text className="d-flex flex-column align-items-center justify-content-center text-uppercase">
          {title}
        </Typography.Text>

        <Input
          data-testid="searchbar"
          placeholder={t('shoptimepage.time_search_placeholder')}
          size="large"
          style={{ maxWidth: 200 }}
          suffix={<SearchOutlined style={{ fontSize: '20px' }} />}
          onChange={onSearch}
        />
      </div>
      <div className="w-100 mt-2 py-2"></div>
      <div data-testid="service-lists">
        <Table dataSource={timeLists}>
          <Column
            title={t('shoptimepage.table.date')}
            dataIndex="date"
            key="date"
            render={(text, item) => (
              <Typography.Text className="font-16">
                {dateToItaFormat(item?.date)}
              </Typography.Text>
            )}
          />
          <Column
            title={t('shoptimepage.table.hours')}
            key="hours"
            render={(text, item) => (
              <Typography.Text className="font-16">
                {item?.from} - {item?.to}
              </Typography.Text>
            )}
          />
          <Column
            title={t('shoptimepage.table.break_time')}
            key="age"
            render={(text, item) => (
              <Typography.Text className="font-16">
                {item?.break_start} - {item?.break_end}
              </Typography.Text>
            )}
          />
          <Column
            title={t('shoptimepage.table.action')}
            key="action"
            render={(text, record) => (
              <>
                <CancelModal
                  title={t('calendar.card.title_confirm')}
                  content={t('servicepage.delete_confirmation_title')}
                  open={cancModal}
                  closeModal={() => setCancModal(false)}
                  handleOK={() => {
                    setCancModal(false);
                    handleDelete(record);
                  }}
                />

                <Button
                  className="secondary-button-ghost"
                  onClick={() => {
                    setCancModal(true);
                  }}
                >
                  <img src={TrashIcon} alt="Remove Card" />
                </Button>
              </>
            )}
          />
        </Table>
      </div>
    </Card>
  );
}

ExtraordinaryTimeList.propTypes = {
  allTime: PropTypes.array,
  title: PropTypes.string,
  onDelete: PropTypes.func,
};

export default ExtraordinaryTimeList;
