import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

function LogoutPage() {
  useEffect(() => {
    localStorage.removeItem('fe-eventId');

    return () => {};
  }, []);

  return (
    <div>
      <Redirect to="/redirect" push />
    </div>
  );
}

export default LogoutPage;
