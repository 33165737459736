import { useCalendarTheme } from "../../../theme";

function BackgroundLayer() {
    const {computedStyles} = useCalendarTheme();

    return (
        <div className="calendar-hours">
            <div className="calendar-hour" style={computedStyles.cellStyle}></div>
            <div className="calendar-hour" style={computedStyles.cellStyle}></div>
            <div className="calendar-hour" style={computedStyles.cellStyle}></div>
            <div className="calendar-hour" style={computedStyles.cellStyle}></div>
        </div>
    );
}

export default BackgroundLayer;