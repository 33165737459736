import { Button, Card, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './RejectAppointmentModal.less';

/**
 * Reject appointment modal
 *
 * @param {*} param0
 * @returns {JSX}
 */
function RejectAppointmentModal({
  open,
  closeModal,
  reasons,
  submit,
  closable,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      closable={closable}
      footer={null}
      maskClosable={true}
      width={'500px'}
    >
      <Card className="text-center">
        <Typography.Paragraph className="reject-text text-uppercase reject-title">
          {t('calendar.scheduledeletemodal.title')}
        </Typography.Paragraph>
        <Typography.Paragraph className="reject-text">
          {t('calendar.scheduledeletemodal.subtitle')}
        </Typography.Paragraph>
        {reasons &&
          reasons.map((reason) => (
            <Button
              size="large"
              className="w-100 my-2 reject-text btn-reason"
              type="primary"
              shape="round"
              data-testid="reason"
              key={reason.note}
              onClick={() => submit(reason.note)}
            >
              {reason.text}
            </Button>
          ))}
        <Button
          data-testid="cancel"
          shape="round"
          className="w-100 btn-cancel"
          onClick={() => closeModal()}
        >
          {t('common:button.cancel')}
        </Button>
      </Card>
    </Modal>
  );
}

RejectAppointmentModal.propTypes = {
  open: PropTypes.bool,
  closable: PropTypes.bool,
  closeModal: PropTypes.func,
  submit: PropTypes.func,
};

export default RejectAppointmentModal;
