import React, { createContext, useContext, useEffect, useState } from 'react';
import { DefaultTemplates, DefaultTheme, WeeklyDefaultTheme } from './data';
import { useSelector } from 'react-redux';
import { selectCalendarColumn } from '../../../features/calendar/calendarSlice';

const DefaultComputedStyle = {
  cellStyle: {},
  cellContainerStyle: {},
  calendarSidebarHourHeight: {},
  calendarBodyHeightStyle: {},
  calendarScrollerStyle: {},
  calendarHourHeaderStyle: {},
  calendarHeaderColumnContainerStyle: {},
  calendarColumnHeaderStyle: {},
};

const CalendarThemeContext = createContext({
  theme: DefaultTheme,
  computedStyles: DefaultComputedStyle,
});

/**
 * Use calendar theme
 * @returns any
 */
export const useCalendarTheme = () => {
  return useContext(CalendarThemeContext);
};

/**
 * Theme Context Provider For Managing Calendar Theme And Options
 *
 * @param {Object} theme  Theme of the calendar
 * @param {JSX} children Children
 * @returns {JSX}
 */
export function CalendarThemeProvider({
  dateOption,
  weekly = false,
  templates,
  theme = null,
  height,
  children,
}) {
  const [themeOption, setThemeOption] = useState(
    weekly ? WeeklyDefaultTheme : DefaultTheme,
  );
  const [computedStyles, setComputedStyles] = useState(DefaultComputedStyle);
  const [template, setTemplates] = useState(DefaultTemplates);
  const columns = useSelector(selectCalendarColumn);

  function updateTheme() {
    const newThemeOption =
      theme === null
        ? themeOption
        : {
            header: { ...themeOption.header, ...theme.header },
            sidebar: { ...themeOption.sidebar, ...theme.sidebar },
            body: { ...themeOption.body, ...theme.body },
            subCellNumber: themeOption.subCellNumber || theme.subCellNumber,
          };
    let width = newThemeOption.header.columnWidth;
    if (window && !weekly && columns?.length && window?.innerWidth > 1200) {
      width = Math.ceil((window.innerWidth - 80) / columns.length);
      newThemeOption.header.columnWidth = width;
    }

    if (window && !weekly && columns?.length && window?.innerWidth < 500) {
      newThemeOption.header.columnWidth = 120;
    }

    // Calculation of hour height. eg. 4 sub cells = 1 hourcell
    newThemeOption.sidebar.hourHeight =
      newThemeOption.body.cellHeight * newThemeOption.subCellNumber;
    JSON.stringify(themeOption) !== JSON.stringify(newThemeOption) &&
      setThemeOption(newThemeOption);

    const cellStyle = {
      minWidth: newThemeOption.header.columnWidth,
      width: newThemeOption.header.columnWidth,
      height: newThemeOption.body.cellHeight,
    };
    const cellContainerStyle = {
      minWidth: newThemeOption.header.columnWidth,
      width: newThemeOption.header.columnWidth,
    };
    const calendarSidebarHourHeight = {
      height: themeOption.body.cellHeight * 2 - 8,
    };
    const calendarBodyHeightStyle = {
      height: (height || 600) - themeOption.header.height,
    };
    const calendarScrollerStyle = {
      minWidth: newThemeOption.sidebar.hourWidth,
      width: newThemeOption.sidebar.hourWidth,
      backgroundColor: newThemeOption.sidebar.hourBgColor,
      color: newThemeOption.sidebar.hourTextColor,
      overflow: 'hidden',
      overflowX: 'scroll',
    };
    const calendarHourHeaderStyle = {
      minWidth: newThemeOption.sidebar.hourWidth,
      width: newThemeOption.sidebar.hourWidth,
      backgroundColor: newThemeOption.sidebar.hourBgColor,
    };
    const calendarHeaderColumnContainerStyle = {
      overflowX: 'scroll',
      overflow: 'hidden',
      backgroundColor: newThemeOption.header.columnBgColor,
    };
    const calendarColumnHeaderStyle = {
      minWidth: newThemeOption.header.columnWidth,
      width: newThemeOption.header.columnWidth,
    };

    const computedStyle = {
      cellStyle,
      cellContainerStyle,
      calendarSidebarHourHeight,
      calendarBodyHeightStyle,
      calendarScrollerStyle,
      calendarHourHeaderStyle,
      calendarHeaderColumnContainerStyle,
      calendarColumnHeaderStyle,
    };

    if (JSON.stringify(computedStyle) !== JSON.stringify(computedStyles)) {
      setComputedStyles({
        cellStyle,
        cellContainerStyle,
        calendarSidebarHourHeight,
        calendarBodyHeightStyle,
        calendarScrollerStyle,
        calendarHourHeaderStyle,
        calendarHeaderColumnContainerStyle,
        calendarColumnHeaderStyle,
      });
    }

    setTemplates({
      ...DefaultTemplates,
      ...templates,
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateTheme, [
    theme,
    height,
    templates?.headerColumnTemplate,
    templates?.renderSchedule,
    columns?.length,
  ]);

  return (
    <CalendarThemeContext.Provider
      value={{
        theme: themeOption,
        computedStyles: computedStyles,
        template: template,
        dateOption,
      }}
    >
      {children}
    </CalendarThemeContext.Provider>
  );
}
