import { Col, Row, Typography } from "antd";
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

/**
 * User is being authorized
 * 
 * @returns {JSX}
 */
function AuthorizationLoading({ message }) {
  
    const { t } = useTranslation(); 
    
    return (
        <div className="homepage">
            <Row type="flex" align="middle" className="w-100 vh-100 text-center">
                <Col offset={6} span={12}>
                    <div>
                        <Title data-testid="title" aria-labelledby="Unauthorized" level={2}>{ t('precheck.authTitle') }</Title>
                        <Paragraph data-testid="desc" className="text-center">{ t('precheck.authDesc') }</Paragraph>
                        {message && (
                            <p aria-labelledby="unauthorizedErrorMessage" className="lead text-danger">{message}</p>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}


export default AuthorizationLoading;